<template>
  <tr
    class="odd:bg-white even:bg-gray-100 child:p-2 first-child:pl-2 last-child:pr-2 first-child:border-l child:border-gray-200 last-child:border-r"
  >
    <td class="py-2">
      <div class="flex space-x-2 items-center">
        <button
          type="button"
          @click="$emit('showVariation', variationOrder)"
        >
          <Icon
            name="info"
            class="w-5 h-5 btn-icon-blue"
          />
        </button>

        <button
          type="button"
          @click="$emit('openCorrespondenceModal', variationOrder)"
        >
          <Icon
            name="email"
            class="w-5 h-5 btn-icon-blue"
          />
        </button>

        <button
          type="button"
          data-test="show-comments"
          @click="$emit('openCommentModal', variationOrder)"
        >
          <Icon
            name="comment"
            class="btn-icon h-5 w-5"
          />
        </button>

        <div
          v-if="showWarning"
          :title="`Notice due in ${noticeDueIn} days`"
        >
          <Icon
            name="caution"
            class="w-5 h-5 text-red-600"
          />
        </div>

        <p
          class="text-wrap w-80"
          :title="variationOrder.description"
        >
          {{ variationOrder.code }} {{ variationOrder.description }}
        </p>
      </div>
    </td>

    <td>
      {{ variationOrder.client_ref }}
    </td>

    <td>
      {{ variationOrder.statusTitle }}
    </td>

    <td class="text-right">
      {{ variationOrder.status >= VariationOrderStatus.priced ? australianCurrency(variationOrder.totalBudget) : '' }}

      <div v-if="variationOrder.status === VariationOrderStatus.registered && project.type !== 'client'">
        <AppInput
          v-model.number="sumPrice"
          name="pricing"
          class="form-input w-20 p-0 ml-auto"
          type="number"
          min="1"
          @blur="priceVariationOrder"
          @keyup.enter="priceVariationOrder"
        />
      </div>
    </td>

    <td v-if="expanded">
      <!-- TODO: 26/02/25 escape to unfocus and reset -->
      <textarea
        v-model="nextAction"
        class="resize-none form-input"
        @blur="saveNextAction"
        @keyup.enter="saveNextAction"
      />
    </td>

    <td @click.stop="">
      <div class="flex gap-x-4 gap-y-2 flex-wrap justify-center">
        <template v-if="project.type !== 'client'">
          <button
            v-if="!variationOrder.notice_sent_at"
            type="button"
            class="btn btn--secondary-blue"
            @click="$emit('sendNotice', variationOrder)"
          >
            Send Notice
          </button>

          <template
            v-if="variationOrder.status === VariationOrderStatus.raised"
          >
            <button
              type="button"
              class="btn btn--primary-green"
              @click="$emit('acceptVariationOrder', variationOrder)"
            >
              Accept
            </button>

            <button
              type="button"
              class="btn btn--primary-alert"
              @click="$emit('declineVariationOrder', variationOrder)"
            >
              Decline
            </button>
          </template>

          <template
            v-else-if="variationOrder.status === VariationOrderStatus.registered"
          >
            <button
              type="button"
              class="btn btn--secondary-blue"
              @click="$emit('priceVariationOrder', variationOrder)"
            >
              Add Pricing
            </button>
          </template>

          <template
            v-else-if="variationOrder.status === VariationOrderStatus.priced"
          >
            <button
              type="button"
              class="btn btn--secondary-blue"
              @click="$emit('priceVariationOrder', variationOrder)"
            >
              Add Pricing
            </button>

            <button
              type="button"
              class="btn btn--secondary-blue"
              @click="$emit('submitVariationOrder', variationOrder)"
            >
              Submit
            </button>
          </template>

          <template
            v-else-if="variationOrder.status === VariationOrderStatus.submitted"
          >
            <button
              type="button"
              class="btn btn--secondary-blue"
              @click="$emit('submitVariationOrder', variationOrder)"
            >
              Resubmit
            </button>
          </template>

          <template
            v-else-if="variationOrder.status === VariationOrderStatus.provisioned"
          >
            <button
              type="button"
              class="btn btn--secondary-blue"
              @click="$emit('approveVariationOrder', variationOrder)"
            >
              Approve
            </button>
          </template>
        </template>

        <template v-else>
          <button
            v-if="variationOrder.status === VariationOrderStatus.raised"
            type="button"
            class="btn btn--secondary-blue"
            @click="$emit('registerNotice', variationOrder)"
          >
            Register Notice
          </button>

          <button
            v-if="variationOrder.status === VariationOrderStatus.registered"
            type="button"
            class="btn btn--secondary-blue"
            @click="$emit('registerPricingNotice', variationOrder)"
          >
            Register Pricing Notice
          </button>

          <template
            v-else-if="variationOrder.status === VariationOrderStatus.provisioned"
          >
            <button
              type="button"
              class="btn btn--secondary-blue"
              @click="$emit('approveVariationOrder', variationOrder)"
            >
              Approve
            </button>
          </template>
        </template>
      </div>
    </td>
  </tr>
</template>

<script setup lang="ts">
import { australianCurrency } from '@/helpers';
import VariationOrder, { VariationOrderStatus } from '@/models/VariationOrder';
import { useProjectsStore } from '@/store/projects';
import { computed } from 'vue';
import { ref } from 'vue';

const props = defineProps<{
  variationOrder: VariationOrder;
  expanded: boolean;
}>();

const emits = defineEmits<{
  (e: 'showVariation', variationOrder: VariationOrder);
  (e: 'openCommentModal', variationOrder: VariationOrder);
  (e: 'acceptVariationOrder', variationOrder: VariationOrder);
  (e: 'declineVariationOrder', variationOrder: VariationOrder);
  (e: 'priceVariationOrder', variationOrder: VariationOrder);
  (e: 'submitVariationOrder', variationOrder: VariationOrder);
  (e: 'approveVariationOrder', variationOrder: VariationOrder);
  (e: 'sumSetVariationOrder', data: { variationOrder: VariationOrder; sumPrice: number });
  (e: 'saveNextAction', data: { variationOrder: VariationOrder; nextAction: string });
  (e: 'sendNotice', variationOrder: VariationOrder);
  (e: 'registerNotice', variationOrder: VariationOrder);
  (e: 'registerPricingNotice', variationOrder: VariationOrder);
  (e: 'openCorrespondenceModal', variationOrder: VariationOrder);
}>();

const sumPrice = ref();

const priceVariationOrder = () => {
  if(sumPrice.value) {
    emits('sumSetVariationOrder', {
      variationOrder: props.variationOrder,
      sumPrice: sumPrice.value,
    });
  }
};

const nextAction = ref(props.variationOrder.next_action);

const saveNextAction = () => {
  emits('saveNextAction', {
    variationOrder: props.variationOrder,
    nextAction: nextAction.value,
  });
};

const projectsStore = useProjectsStore();

const project = computed(() => {
  return projectsStore.project;
});

const noticeDueIn = computed(() => {
  if(props.variationOrder.dateOfOccurrence) {
    return project.value.nov_period - Math.ceil(props.variationOrder.dateOfOccurrence.diffNow('days').days);
  }

  return project.value.nov_period;
});

const showWarning = computed(() => {
  return !props.variationOrder.notice_sent_at &&
    noticeDueIn.value < 3 &&
    noticeDueIn.value >= 0;
});
</script>

<style scoped></style>
