<template>
  <div class="!pt-0">
    <div class="flex flex-col space-y-2.5 max-h-screen pt-16 pb-2.5">
      <div class="flex items-center justify-between space-x-4 mt-2.5">
        <div class="flex items-center space-x-4 element-island">
          <h1 class="font-semibold text-3xl">
            Progress Claims
          </h1>

          <div v-if="claim">
            <h2>
              Claim {{ claim.claim_number }}
              -
              {{ ClaimStatusTitles[enumKeyFromValue(ClaimStatus, claim.status)] }}
              <!-- Retention/Stat dec future functionality -->
              <!-- <span v-if="claim.status === ClaimStatus.certified">
                - {{ claim.stat_dec_received ? 'Stat Dec Received' : 'No Stat Dec' }}
              </span> -->
            </h2>

            <p>
              {{ new Date(claim.start).toDateString() }} - {{ new Date(claim.end).toDateString() }}
            </p>

            <div v-if="claim?.status === ClaimStatus.draft">
              Capture Claim

              <input
                v-model="captureClaimAmount"
                type="checkbox"
                name="capture_claim_amount"
                data-test="capture-claim-amount"
              >
            </div>

            <!-- <div v-if="typingRetention">
              <label class="flex items-center">
                Retention

                <AppInput
                  v-model="updateForm.form.retention"
                  class="form-input ml-4 w-1/2"
                  type="text"
                  name="retention"
                  @keyup.esc="typingRetention = false"
                  @blur="updateClaim"
                />
              </label>
            </div>

            <div
              v-else
              class="flex items-center space-x-2 cursor-pointer"
              @click="typingRetention = true"
            >
              <p>
                Retention {{ australianCurrency(claim.retention) }}
              </p>

              <Icon
                name="edit"
                class="btn-icon h-5 w-5 inline-block"
              />
            </div> -->
          </div>

          <button
            v-if="claim?.status === ClaimStatus.draft"
            type="button"
            class="btn btn--secondary-blue"
            @click="certifyClaim()"
          >
            Certify Claim
          </button>
        </div>

        <div
          v-if="claim?.status === ClaimStatus.draft"
          class="element-island flex space-x-4"
        >
          <button
            v-if="claim?.status === ClaimStatus.draft"
            type="button"
            class="btn btn--secondary-blue"
            @click.prevent="showImageModal = true"
          >
            Attach Images
          </button>

          <button
            v-if="claim?.status === ClaimStatus.draft"
            type="button"
            class="btn btn--secondary-blue"
            @click.prevent="deleteClaim"
          >
            Delete Claim
          </button>
        </div>

        <div
          v-else-if="claim?.status === ClaimStatus.certified"
          class="element-island flex space-x-4"
        >
          <button
            type="button"
            class="btn btn--secondary-blue"
            @click.prevent="showImageModal = true"
          >
            View Images
          </button>

          <button
            type="button"
            class="btn btn--secondary-blue"
            :class="{ 'opacity-50': claimExportAction.is(States.LOADING) }"
            :disabled="claimExportAction.is(States.LOADING)"
            @click.prevent="exportClaim"
          >
            Export to PDF
          </button>
        </div>
      </div>

      <div
        class="element-island overflow-y-auto"
      >
        <ClaimTable
          :project-id="projectId"
          :claim-id="claimId"
        />
      </div>
    </div>

    <ClaimAttachmentsModal
      v-if="showImageModal"
      :claim-id="claimId"
      @close="showImageModal = false;"
    />
  </div>
</template>

<script setup lang="ts">
import ClaimAttachmentsModal from '@/components/HeadContract/ProgressClaimsLite/ClaimAttachmentsModal.vue';
import ClaimTable from '@/components/HeadContract/ProgressClaimsLite/ClaimTable.vue';
import { useFormData } from '@/composables/useFormData';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { enumKeyFromValue, timeZone } from '@/helpers';
import { ClaimStatus, ClaimStatusTitles } from '@/models/Claim';
import { useClaimsStore } from '@/store/claims';
import { useTradesStore } from '@/store/trades';
import { useVariationOrdersStore } from '@/store/variationOrders';
import Swal from 'sweetalert2';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps<{
  projectId: string;
  claimId: string;
}>();

const claimsStore = useClaimsStore();
const router = useRouter();
const tradesStore = useTradesStore();
const variationOrdersStore = useVariationOrdersStore();
const fetchTradesAction = useStoreApiAction(tradesStore.fetchTrades);
const fetchVariationOrdersAction = useStoreApiAction(variationOrdersStore.fetchApprovedVariationOrders);

fetchTradesAction.request(props.projectId, { boq_type: 'direct' }).catch((error) => {
  console.log(error);
});

fetchVariationOrdersAction.request(props.projectId).catch((error) => {
  console.log(error);
});

watch(() => props.projectId, (newValue, oldValue) => {
  if(newValue !== oldValue) {
    router.push({ name: 'ProgressClaimsIndex' });
  }
});

watch(() => props.claimId, () => {
  claimsStore.fetchClaim(props.claimId);
}, { immediate: true });

const claim = computed(() => {
  // TODO: 10/11/23 constrain variation orders to only those with cost for the current claim
  if(props.claimId) {
    const claimQuery = claimsStore.models
      .with('trades', (query) => {
        query.with('trade_items')
          .where('boq_type', 'direct');
      })
      .with('trade_item_costs')
      .with('variation_orders')
      .with('variation_order_costs')
      .where(
        'project_id',
        parseInt(props.projectId),
      );

    return claimQuery.find(parseInt(props.claimId));
  }

  return undefined;
});

const certifyClaimAction = useStoreApiAction(claimsStore.certifyLite);

const certifyClaim = () => {
  Swal.fire({
    icon: 'warning',
    title: 'Are you sure?',
    text: 'Are you sure you want to certify this claim? You will not be able to adjust any amounts after certifying.',
    showCancelButton: true,
    showConfirmButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      certifyClaimAction.request(props.claimId, { submitted_at_time_zone: timeZone }).then(() => {
        Swal.fire({
          title: 'Claim Certified',
          icon: 'success',
        });
      }).catch((error) => {
        if(error.status === 422) {
          Swal.fire({
            title: 'Failed to certify claim',
            text: error.data.message,
            icon: 'error',
          });
        } else {
          Swal.fire({
            title: 'Failed to certify claim',
            icon: 'error',
          });
        }
      });
      // claimsStore.sendClaim(claim.value.id, { status: result.value });
    }
  });
};

const updateForm = useFormData({
  retention: 0,
});

watch(claim, (newValue) => {
  if(newValue) {
    updateForm.setData(newValue);
  }
});

const deleteClaim = () => {
  Swal.fire({
    icon: 'warning',
    title: 'Delete Claim?',
    text: 'Are you sure you want to delete this claim? This cannot be undone.',
    showCancelButton: true,
    showConfirmButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      claimsStore.deleteClaim(claim.value.id).then(() => {
        router.push({ name: 'ProgressClaimsIndex' });

        Swal.fire({
          icon: 'success',
          title: 'Claim deleted.',
        });
      });
    }
  });
};

const claimExportAction = useStoreApiAction(claimsStore.export);

const exportClaim = () => {
  claimExportAction.request(claim.value.id).then(() => {
    Swal.fire({
      icon: 'success',
      title: 'Claim Exported.',
      text: 'Claim is being exported to PDF and will be sent to your email when it is ready.',
    });
  }).catch((error) => {
    if(error.status === 429) {
      Swal.fire({
        icon: 'error',
        title: 'PDF is currently being exported',
        text: error.data.message,
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Failed to Export.',
      });
    }
  });
};

const captureClaimAmount = ref(false);

watch(claim, (newValue) => {
  if(newValue) {
    captureClaimAmount.value = newValue.capture_claim_amount;
  }
}, { immediate: true });

const updateClaimAction = useStoreApiAction(claimsStore.updateClaim);

const saveCaptureClaimAmount = () => {
  updateClaimAction.request(claim.value.id, { capture_claim_amount: captureClaimAmount.value }).then(() => {
    console.log('updated claim');
  }).catch((error) => {
    Swal.fire({
      icon: 'error',
      title: 'Something went Wrong.',
      text: 'Failed to Update Claim',
    });
  });
};

watch(captureClaimAmount, (newValue, oldValue) => {
  if(newValue !== oldValue) {
    if(newValue === false) {
      Swal.fire({
        icon: 'warning',
        title: 'Capture Certified Cost Only?',
        text:
          'Are you sure you want to only capture the certified costs? This will remove all currently filled claim amounts.',

        showDenyButton: true,
        showConfirmButton: true,
        confirmButtonColor: 'green',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if(result.isConfirmed) {
          saveCaptureClaimAmount();
        } else {
          captureClaimAmount.value = true;
        }
      });
    } else {
      saveCaptureClaimAmount();
    }
  }
});

const showImageModal = ref(false);
</script>

<style scoped></style>
