<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl">
          Variation Order
        </h1>
      </div>
    </template>

    <template #body>
      <div class="h-full w-full p-4 min-w-3xl">
        <div class="flex flex-col space-y-4 items-center w-full relative">
          <div class="grid grid-cols-[1fr_auto_1fr] w-full">
            <div>
              <button
                v-if="variationOrder.status === VariationOrderStatus.approved"
                type="button"
                class="btn btn--secondary-blue"
                @click="completeVariationOrder"
              >
                Mark as Complete
              </button>
            </div>

            <div
              :style="{ backgroundColor: statusColour, color: variationOrder.statusTextColour }"
              class="py-2 px-4 rounded-lg"
            >
              {{ statusTitle }}
            </div>

            <div class="ml-auto">
              <button
                type="button"
                class="btn btn--secondary-blue"
                @click="showDetails = !showDetails"
              >
                View {{ showDetails ? 'Attachments' : 'Details' }}
              </button>
            </div>
          </div>

          <div
            v-if="showDetails"
            class="flex space-x-4 w-full"
          >
            <div class="flex flex-col w-1/2 space-y-2">
              <div v-if="variationOrder.delay">
                <p class="font-semibold text-lg">
                  Attached to Delay <span class="font-semibold">{{ variationOrder.delay.code }}</span>
                </p>

                <p>
                  {{ variationOrder.delay.description }}
                </p>
              </div>

              <p class="font-semibold text-lg">
                Variation Details
              </p>

              <form class="flex flex-col space-y-4">
                <label>
                  Description <span class="font-semibold">{{ variationOrder.code }}</span>

                  <AppInput
                    v-model="variationOrderForm.form.description"
                    type="text"
                    name="description"
                    class="form-input"
                    :error="variationOrderForm.getErrors('description')"
                  />
                </label>

                <label>
                  Client Reference

                  <AppInput
                    v-model="variationOrderForm.form.client_ref"
                    type="text"
                    name="client_ref"
                    class="form-input"
                    :error="variationOrderForm.getErrors('client_ref')"
                  />
                </label>

                <label v-if="!variationOrder.date_of_occurrence">
                  Date of Occurrence

                  <AppInput
                    v-model="variationOrderForm.form.date_of_occurrence"
                    :error="variationOrderForm.getErrors('date_of_occurrence')"
                    class="form-input"
                    type="date"
                    name="date_of_occurrence"
                  />
                </label>

                <label>
                  Notes

                  <textarea
                    v-model="variationOrderForm.form.notes"
                    class="resize-none form-input"
                  />

                  <p
                    v-if="variationOrderForm.hasErrors('notes')"
                    class="text-sm text-red-600"
                  >
                    {{ variationOrderForm.getErrors('notes') }}
                  </p>
                </label>
              </form>
            </div>

            <div class="w-1/2 flex flex-col">
              <p class="font-semibold text-lg">
                Status Timeline
              </p>

              <div class="mt-2">
                <div class="flex justify-between">
                  <span>
                    Date Occurred
                  </span>

                  <span
                    v-if="variationOrder.dateOfOccurrence"
                    class="font-semibold"
                  >{{ variationOrder.dateOfOccurrence.toLocaleString() }}</span>

                  <span
                    v-else
                    class="font-semibold"
                  >Not submitted</span>
                </div>

                <div class="flex justify-between">
                  <span>
                    Raised by {{ variationOrder.user?.name }}
                  </span>

                  <span class="font-semibold">{{ variationOrder.createdAt.toLocaleString() }}</span>
                </div>

                <div
                  v-if="variationOrder.registeredAt && project.type !== 'client'"
                  class="flex justify-between"
                >
                  <span>
                    Accepted
                  </span>

                  <span class="font-semibold">{{ variationOrder.registeredAt.toLocaleString() }}</span>
                </div>

                <div
                  v-if="noticeDueDate && !variationOrder.notice_sent_at"
                  class="flex justify-between py-1"
                  :class="noticeClass(noticeDueIn)"
                >
                  <span>
                    Date to be Issued by
                  </span>

                  <span class="font-semibold">{{ noticeDueDate.toLocaleString() }}</span>
                </div>

                <div
                  v-if="variationOrder.noticeSentAt"
                  class="flex justify-between"
                >
                  <span>
                    {{ project.type === 'client' ? 'Notice Registered' : 'Notice Sent' }}
                  </span>

                  <span class="font-semibold">{{ variationOrder.noticeSentAt.toLocaleString() }}</span>
                </div>

                <div
                  v-if="variationOrder.submittedAt && project.type !== 'client'"
                  class="flex justify-between"
                >
                  <span>
                    Pricing Submitted
                  </span>

                  <span class="font-semibold">{{ variationOrder.submittedAt.toLocaleString() }}</span>
                </div>

                <div
                  v-if="variationOrder.provisionedAt"
                  class="flex justify-between"
                >
                  <span>
                    {{ project.type === 'client' ? 'Pricing Received' : 'Pricing Approved' }}
                  </span>

                  <span class="font-semibold">{{ variationOrder.provisionedAt.toLocaleString() }}</span>
                </div>

                <div
                  v-if="variationOrder.approvedAt"
                  class="flex justify-between"
                >
                  <span>
                    Fully Approved
                  </span>

                  <span class="font-semibold">{{ variationOrder.approvedAt.toLocaleString() }}</span>
                </div>
              </div>

              <hr class="border-gray-300 my-2">

              <div class="flex justify-between">
                <span class="font-semibold">
                  Budget
                </span>

                <div>
                  {{ variationOrder.status >= VariationOrderStatus.priced ? australianCurrency(variationOrder.totalBudget) : 'Not Set' }}
                </div>
              </div>

              <div v-if="variationOrder.trade_items?.length > 0">
                <span class="font-semibold">
                  Budget Breakdown:
                </span>

                <ul class="max-h-56 overflow-y-auto">
                  <li
                    v-for="tradeItem in variationOrder.trade_items"
                    :key="tradeItem.id"
                    class="py-1 odd:bg-white even:bg-gray-100 px-2"
                  >
                    <div class="flex justify-between">
                      <span>
                        {{ tradeItem.name }}</span>

                      <span>
                        {{ australianCurrency(tradeItem.budget) }}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            v-else
          >
            <div
              v-if="variationOrderMedia.mediaCollection.value.length === 0"
              class="font-semibold mb-4"
            >
              No attachments found.
            </div>

            <FileUpload
              v-model="fileUploadForm"
              @change="fileUploaded"
            />

            <button
              v-if="variationOrderMedia.mediaCollection.value.length > 1"
              class="absolute left-0 inset-y-1/2 border border-black rounded-full w-8 h-8 flex justify-center items-center hover:bg-gray-100 active:bg-gray-200 z-10 bg-white"
              type="button"
              @click="mediaSwiper.slidePrev()"
            >
              <Icon
                name="chevron-left"
                class="w-6 h-6"
              />
            </button>

            <Swiper
              class="max-w-md mb-10"
              :space-between="20"
              :loop="variationOrderMedia.mediaCollection.value?.length > 1"
              centered-slides
              :modules="[Pagination]"
              :pagination="{
                el: '.swiper-pagination',
                type: 'bullets'
              }"
              @swiper="setSwiper"
            >
              <SwiperSlide
                v-for="media in variationOrderMedia.mediaCollection.value"
                :key="media.uuid"
                class="!h-auto"
              >
                <div class="flex items-center h-full w-full justify-center">
                  <MediaDisplay
                    :media="media"
                    @delete="variationOrderMedia.removeMedia(media.uuid)"
                    @refresh="variationOrderMedia.refreshMedia(media.uuid)"
                  />
                </div>
              </SwiperSlide>
            </Swiper>

            <div class="swiper-pagination" />

            <button
              v-if="variationOrderMedia.mediaCollection.value.length > 1"
              class="absolute right-0 inset-y-1/2 border border-black rounded-full w-8 h-8 flex justify-center items-center hover:bg-gray-100 active:bg-gray-200 z-10 bg-white"
              type="button"
              @click="mediaSwiper.slideNext()"
            >
              <Icon
                name="chevron-right"
                class="w-6 h-6"
              />
            </button>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-end p-2 space-x-4">
        <button
          type="button"
          class="btn btn--secondary-blue w-1/4"
          @click="$emit('close')"
        >
          Close
        </button>

        <button
          type="button"
          class="btn btn--primary-blue w-1/4"
          @click="updateVariationOrder"
        >
          Update
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import Modal from '@/components/Modal.vue';
import { useFormData } from '@/composables/useFormData';
import { useMediaCollection } from '@/composables/useMediaCollection';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency, dateForDateInput, enumKeyFromValue } from '@/helpers';
import { VariationOrderStatus, VariationOrderStatusColours, VariationOrderStatusTitles } from '@/models/VariationOrder';
import { useVariationOrdersStore } from '@/store/variationOrders';
import Swal from 'sweetalert2';
import { Pagination } from 'swiper/modules';
import { Swiper as SwiperInterface } from 'swiper/types';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { computed, ref, watch } from 'vue';
import 'swiper/css/pagination';
import FileUpload from '@/components/FileUpload.vue';
import MediaDisplay from '@/components/MediaDisplay.vue';
import { useCorrespondencesStore } from '@/store/correspondences';
import { useProjectsStore } from '@/store/projects';

const props = defineProps<{
  variationOrderId: Id;
}>();

const emits = defineEmits<{
  (e: 'close');
}>();

const variationOrdersStore = useVariationOrdersStore();
const fetchVariationOrderAction = useStoreApiAction(variationOrdersStore.fetchVariationOrder);
const fetchCorrespondencesAction = useStoreApiAction(variationOrdersStore.fetchCorrespondences);

watch(() => props.variationOrderId, (newValue) => {
  if(newValue) {
    fetchVariationOrderAction.request(newValue).catch(console.error);
    fetchCorrespondencesAction.request(newValue).catch(console.error);
  }
}, { immediate: true });

const variationOrder = computed(() => {
  return variationOrdersStore.models.with('trade_items').with('user').with('correspondences').with('delay').where(
    'id',
    parseInt(props.variationOrderId as string),
  )
    .first();
});

const statusTitle = computed(() => {
  return VariationOrderStatusTitles[enumKeyFromValue(VariationOrderStatus, variationOrder.value.status)];
});

const statusColour = computed(() => {
  return VariationOrderStatusColours[enumKeyFromValue(VariationOrderStatus, variationOrder.value.status)];
});

const updateVariationOrderAction = useStoreApiAction(variationOrdersStore.updateVariationOrder);
const completeVariationOrderAction = useStoreApiAction(variationOrdersStore.completeVariationOrder);

const variationOrderForm = useFormData({
  client_ref: '',
  notes: '',
  description: '',
  date_of_occurrence: '',
});

watch(variationOrder, (newValue) => {
  if(newValue) {
    variationOrderForm.setData(newValue, ['date_of_occurrence']);

    variationOrderForm.form.date_of_occurrence = dateForDateInput(newValue.date_of_occurrence ?? new Date());
  }
}, { immediate: true });

const variationOrderMedia = useMediaCollection(variationOrdersStore.media);

watch(() => props.variationOrderId, (newValue) => {
  if(newValue) {
    variationOrderMedia.fetchMedia(newValue);
  }
}, { immediate: true });

const updateVariationOrder = () => {
  updateVariationOrderAction.request(props.variationOrderId, variationOrderForm.form).then(() => {
    Swal.fire({
      icon: 'success',
      text: 'Variation Order Updated',
    });
  }).catch(console.error);
};

const completeVariationOrder = () => {
  Swal.fire({
    title: 'Mark Variation as Complete?',
    icon: 'question',
    text: 'This will prevent future draw downs against this variation.',
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      completeVariationOrderAction.request(variationOrder.value.id).then(() => {
        Swal.fire({
          icon: 'success',
          text: 'Variation Order Completed',
        });
      }).catch((error) => {
        console.log(error);
      });
    }
  });
};

const showDetails = ref(true);
const mediaSwiper = ref<SwiperInterface>();

const setSwiper = (swiper: SwiperInterface) => {
  mediaSwiper.value = swiper;
};

const storeMediaAction = useStoreApiAction(variationOrdersStore.storeMedia);
const fileUploadForm = ref();

const fileUploaded = (event: Event) => {
  const files = Array.from((event.target as HTMLInputElement).files);
  const selectedFile = files[0];

  if(selectedFile) {
    const fileFormData = new FormData();

    fileFormData.append('images[]', selectedFile);

    storeMediaAction.request(variationOrder.value.id, fileFormData).then((medias) => {
      variationOrderMedia.addMedia(medias);
    }).catch((error) => {
      if(error.status === 422) {
        Swal.fire({
          icon: 'error',
          title: 'Failed to upload attachments',
          text: error.data.message,
        });
      }
    });
  }
};

const projectsStore = useProjectsStore();

const project = computed(() => {
  return projectsStore.project;
});

const noticeDueIn = computed(() => {
  if(variationOrder.value.dateOfOccurrence) {
    return project.value.nov_period - Math.ceil(variationOrder.value.dateOfOccurrence.diffNow('days').days);
  }

  return project.value.nov_period;
});

const noticeClass = (noticeDueIn: number) => {
  if(noticeDueIn < 10 && noticeDueIn > 5) {
    return 'bg-orange-300';
  } else if(noticeDueIn < 5) {
    return 'bg-red-300';
  }

  return '';
};

const noticeDueDate = computed(() => {
  return variationOrder.value.dateOfOccurrence?.plus({ days: project.value.nov_period });
});

const correspondencesStore = useCorrespondencesStore();
</script>

<style scoped></style>
