<template>
  <div
    class="group p-2 relative flex flex-col justify-center items-center"
  >
    <button
      v-if="file.mime === 'image/jpeg' || file.mime === 'image/png'"
      type="button"
      @click="showFullImage = true"
    >
      <img
        :src="imageData"
        class="max-h-40 max-w-40"
      >
    </button>

    <div
      v-else
      class="flex flex-col items-center w-full"
    >
      <button
        type="button"
        class="w-6 h-6 hidden group-hover:block"
        @click="deleteMedia"
      >
        <Icon
          name="trash"
          class="btn-icon btn-icon-red w-6 h-6 group-hover:text-red-600 fill-red-300"
        />
      </button>

      <Icon
        name="document-text"
        class="w-6 h-6 group-hover:hidden"
      />

      <p class="font-semibold mt-2 text-nowrap">
        {{ file.fileName }}
      </p>
    </div>

    <span
      v-if="error"
      class="text-red-600 text-sm"
    >{{ error }}</span>

    <button
      v-if="file.mime === 'image/jpeg' || file.mime === 'image/png'"
      type="button"
      class="absolute w-6 h-6 hidden group-hover:block"
      @click="deleteMedia"
    >
      <Icon
        name="trash"
        class="btn-icon btn-icon-red w-6 h-6 group-hover:text-red-600 fill-red-300"
      />
    </button>

    <Teleport
      v-if="showFullImage"
      to="#modals"
    >
      <div
        class="absolute"
        tabindex="0"
      >
        <div class="fixed z-50 flex justify-center items-center m-4 inset-0">
          <img
            :src="imageData"
            class="max-h-screen p-10"
          >
        </div>

        <button
          type="button"
          @click="showFullImage = false"
        >
          <Icon
            name="close"
            class="h-10 w-10 text-white fixed z-50 top-0 right-0 m-5"
          />
        </button>

        <div
          class="opacity-75 fixed inset-0 z-40 bg-black"
        />
      </div>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

const props = defineProps<{
  file: UploadedFile;
  error?: string;
}>();

const emits = defineEmits<{
  (e: 'delete');
}>();

const deleteMedia = () => {
  emits('delete');
};

const showFullImage = ref(false);
const imageData = ref();

if(props.file.mime === 'image/jpeg' || props.file.mime === 'image/png') {
  const reader = new FileReader();

  // Use this to create a display thumbnail for upload
  reader.onload = (ev) => {
    imageData.value = ev.target.result;
  };

  reader.readAsDataURL(props.file.fileBlob);
}
</script>

<style scoped></style>
