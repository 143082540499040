import { route } from '@/ziggy-shim';
import { api, CastValue } from './LaravelClient';

export default {
  async search(data: ApiRequests['api.v1.suppliers.search']) {
    return await api(route('api.v1.suppliers.search'), 'get', {
      data,
      casts: {
        name: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        project_id: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        company_id: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },
};
