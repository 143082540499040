<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl">
          Claim Attachments
        </h1>
      </div>
    </template>

    <template #body>
      <div class="max-h-[40rem] overflow-y-auto w-full py-4 px-6 max-w-3xl min-w-md relative">
        <FileUpload
          v-if="claim?.status === ClaimStatus.draft"
          v-model="claimsMediaForm.form.images"
          multiple
          @change="fileUploaded"
        />

        <template v-if="showNewAttachments">
          <div
            v-if="filesUploaded.length"
            class="flex justify-between items-center mt-4 w-full"
          >
            <span
              class="font-semibold"
            >
              New Attachments
            </span>

            <div
              v-if="filesUploaded.length > 0"
            >
              <button
                type="button"
                class="mx-auto btn btn--secondary-blue"
                @click="showNewAttachments = !showNewAttachments"
              >
                Show {{ showNewAttachments ? 'Existing' : 'New' }}
              </button>
            </div>
          </div>

          <div
            v-if="filesUploaded.length"
            class="overflow-x-auto max-w-sm flex"
          >
            <UploadedFileDisplay
              v-for="(file, i) in filesUploaded"
              :key="i"
              :file="file"
              :error="claimsMediaForm.getErrors(`images.${i}`)"
              @delete="removeFile(i)"
            />
          </div>
        </template>

        <template v-else>
          <div
            v-if="claim?.status === ClaimStatus.draft"
            class="flex justify-between items-center mt-4 w-full"
          >
            <span class=" font-semibold">
              Existing Attachments
            </span>

            <div
              v-if="filesUploaded.length > 0"
            >
              <button
                type="button"
                class="mx-auto btn btn--secondary-blue"
                @click="showNewAttachments = !showNewAttachments"
              >
                Show {{ showNewAttachments ? 'Existing' : 'New' }}
              </button>
            </div>
          </div>

          <div
            v-if="claimMediaCollection.mediaCollection.value.length === 0"
            class="font-semibold"
          >
            No attachments found.
          </div>

          <button
            v-if="claimMediaCollection.mediaCollection.value.length > 1"
            class="absolute left-0 inset-y-1/2 border border-black rounded-full w-8 h-8 flex justify-center items-center hover:bg-gray-100 active:bg-gray-200 ml-4 z-10 bg-white"
            type="button"
            @click="mediaSwiper.slidePrev()"
          >
            <Icon
              name="chevron-left"
              class="w-6 h-6"
            />
          </button>

          <div class="mx-10 relative pb-5">
            <Swiper
              class="max-w-md"
              :space-between="20"
              loop
              centered-slides
              :modules="[Pagination]"
              :pagination="{
                el: '.swiper-pagination',
                type: 'bullets'
              }"
              @swiper="setSwiper"
            >
              <SwiperSlide
                v-for="image in claimMediaCollection.mediaCollection.value"
                :key="image.uuid"
                class="!h-auto"
              >
                <div class="flex items-center h-full w-full justify-center">
                  <MediaDisplay
                    :media="image"
                    @delete="claimMediaCollection.removeMedia(image.uuid)"
                    @refresh="claimMediaCollection.refreshMedia(image.uuid)"
                  />
                </div>
              </SwiperSlide>
            </Swiper>

            <div class="swiper-pagination" />
          </div>

          <button
            v-if="claimMediaCollection.mediaCollection.value.length > 1"
            class="absolute right-0 inset-y-1/2 border border-black rounded-full w-8 h-8 flex justify-center items-center hover:bg-gray-100 active:bg-gray-200 mr-4 z-10 bg-white"
            type="button"
            @click="mediaSwiper.slideNext()"
          >
            <Icon
              name="chevron-right"
              class="w-6 h-6"
            />
          </button>
        </template>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-end p-2 space-x-4">
        <button
          type="button"
          class="btn btn--secondary-blue"
          @click="$emit('close')"
        >
          Close
        </button>

        <button
          v-if="claim?.status === ClaimStatus.draft"
          type="button"
          class="btn btn--primary-blue disabled:opacity-50"
          data-test="apply-filter"
          :disabled="claimsMediaForm.form.images.length === 0"
          @click="uploadMedia"
        >
          Upload
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import FileUpload from '@/components/FileUpload.vue';
import MediaDisplay from '@/components/MediaDisplay.vue';
import Modal from '@/components/Modal.vue';
import UploadedFileDisplay from '@/components/UploadedFileDisplay.vue';
import { useFormData } from '@/composables/useFormData';
import { useMediaCollection } from '@/composables/useMediaCollection';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { getFileMime } from '@/helpers';
import { ClaimStatus } from '@/models/Claim';
import { useClaimsStore } from '@/store/claims';
import Swal from 'sweetalert2';
import { Pagination } from 'swiper/modules';
import { Swiper as SwiperInterface } from 'swiper/types';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { computed, ref, watch } from 'vue';

const props = defineProps<{
  claimId: Id;
}>();

const emits = defineEmits<{
  (e: 'close');
}>();

const claimsStore = useClaimsStore();

const claim = computed(() => {
  return claimsStore.models.find(props.claimId);
});

const claimsMediaForm = useFormData({
  images: [],
});

const filesUploaded = ref<UploadedFile[]>([]);
const showNewAttachments = ref(false);

const fileUploaded = (event: Event) => {
  const files = Array.from((event.target as HTMLInputElement).files);

  filesUploaded.value = [];

  files.forEach(async (file) => {
    const mimeType = await getFileMime(file);

    filesUploaded.value.push({
      fileName: file.name,
      mime: mimeType,
      fileBlob: file,
    });
  });

  if(files.length > 0) {
    showNewAttachments.value = true;
  }
};

const removeFile = (index: number) => {
  filesUploaded.value.splice(index, 1);
  claimsMediaForm.form.images.splice(index, 1);

  if(filesUploaded.value.length === 0) {
    showNewAttachments.value = false;
  }
};

const storeMediaAction = useStoreApiAction(claimsStore.storeMedia);

const uploadMedia = () => {
  storeMediaAction.request(props.claimId, claimsMediaForm.toFormDataObject()).then(() => {
    Swal.fire({
      icon: 'success',
      title: 'Files Uploaded',
    }).then(() => {
      emits('close');
    });
  }).catch((error) => {
    claimsMediaForm.setErrors(error.data);
  });
};

const claimMediaCollection = useMediaCollection(claimsStore.media);

watch(() => props.claimId, (newValue) => {
  if(newValue) {
    claimMediaCollection.fetchMedia(newValue);
  }
}, { immediate: true });

const mediaSwiper = ref<SwiperInterface>();

const setSwiper = (swiper: SwiperInterface) => {
  mediaSwiper.value = swiper;
};
</script>

<style scoped></style>
