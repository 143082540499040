<template>
  <div class="!pt-0">
    <div class="flex flex-col max-h-screen pt-16 pb-2.5 min-h-screen space-y-2.5">
      <div class="flex items-stretch justify-between mt-2.5">
        <div class="flex space-x-2 element-island">
          <h1 class="font-semibold text-3xl">
            Delays Schedule
          </h1>
        </div>

        <div class="element-island flex items-center">
          <button
            type="button"
            class="btn btn--secondary-blue"
            @click="showCreateDelayModal = true"
          >
            + Delay
          </button>
        </div>
      </div>

      <div class="element-island overflow-y-auto flex space-x-8 min-h-max flex-1 p-4">
        <template v-if="delays.length">
          <div
            v-if="fetchDelaysAction.firstLoad"
            class="flex flex-col space-y-4 w-3/5"
          >
            <div class="relative h-full">
              <div class="absolute inset-0 overflow-y-auto">
                <div class="flex flex-col">
                  <div class="sticky top-0 bg-white">
                    <div class="flex justify-between items-center px-2">
                      <div class="flex space-x-4 items-center">
                        <div
                          class=""
                          @click="toggleExpand"
                        >
                          <Icon
                            v-if="variationsExpanded"
                            name="arrows-pointing-in"
                            class="w-6 h-6 hover:stroke-ccm-blue-500 cursor-pointer"
                          />

                          <Icon
                            v-else
                            name="arrows-pointing-out"
                            class="w-6 h-6 hover:stroke-ccm-blue-500 cursor-pointer"
                          />
                        </div>

                        <Icon
                          name="search"
                          class="w-6 h-6"
                        />

                        <AppInput
                          v-model="searchForm.form.search"
                          type="text"
                          name="variation_orders_filter"
                          class="form-input h-10"
                        />
                      </div>

                      <AppSelect
                        v-model="searchForm.form.status"
                        name="status_filter"
                        class="capitalize form-input pr-8"
                        :options="statusOptions"
                        empty-text="Status"
                      />

                      <button
                        type="button"
                        class="btn btn--secondary-blue"
                        @click="resetSearch"
                      >
                        Clear
                      </button>
                    </div>
                  </div>

                  <table
                    v-if="fetchDelaysAction.is(States.COMPLETE) && filteredDelays.length > 0"
                    class="mt-4 border-separate border-spacing-0"
                  >
                    <thead>
                      <tr class="child:p-2 sticky top-0 bg-white border border-gray-200 child:border-y child:border-gray-200 first-child:border-l last-child:border-r">
                        <th class="text-left border-b border-gray-200">
                          Delay
                        </th>

                        <th class="text-left border-b border-gray-200">
                          Client Ref
                        </th>

                        <th class="text-left border-b border-gray-200">
                          Status
                        </th>

                        <th class="text-right border-b border-gray-200">
                          Days
                        </th>

                        <th class="border-b border-gray-200" />
                      </tr>
                    </thead>

                    <tbody>
                      <DelayRow
                        v-for="delay in filteredDelays"
                        :key="delay.id"
                        :delay="delay"
                        @show-delay="showDelay(delay)"
                        @open-comment-modal="openCommentModal(delay)"
                        @accept-delay="acceptDelay(delay)"
                        @decline-delay="declineDelay(delay)"
                        @add-days-to-delay="addDaysToDelay(delay)"
                        @submit-delay="submitDelay(delay.id)"
                        @approve-delay="approveDelay(delay.id)"
                      />

                      <tr
                        v-if="!searchForm.form.search && fetchDelaysAction.is(States.COMPLETE)"
                        class="sticky bottom-0 bg-white border child:px-2 child:border-y first-child:border-l last-child:border-r font-semibold"
                      >
                        <td />

                        <td />

                        <td>
                          Total
                        </td>

                        <td class="text-right">
                          {{ totalDelay }}
                        </td>

                        <td />
                      </tr>
                    </tbody>
                  </table>

                  <div
                    v-else-if="fetchDelaysAction.is(States.COMPLETE)"
                    class="font-semibold text-center mt-4"
                  >
                    No delays found, try adjusting your filter.
                  </div>

                  <AppSpinner
                    v-else
                    class="mx-auto mt-4"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="!variationsExpanded"
              class="text-sm my-auto w-2/3"
            >
              <div class="bg-ccm-blue-100 rounded-t-md text-white p-2 font-semibold text-base">
                Delay Synopsis
              </div>

              <table class="w-full">
                <thead class="text-base child:border-b child:border-x border-gray-600 font-semibold">
                  <tr class="child:px-5 child:py-2 text-left">
                    <th>Status</th>

                    <th>
                      Days
                    </th>
                  </tr>
                </thead>

                <tbody class="child:border-b child:border-x border-gray-600 font-semibold">
                  <tr class="child:px-5 child:py-2">
                    <td>
                      Registered
                    </td>

                    <td>
                      {{ delaysSummary.registered }}
                    </td>
                  </tr>

                  <tr class="child:px-5 child:py-2">
                    <td>
                      Submitted
                    </td>

                    <td>
                      {{ delaysSummary.submitted }}
                    </td>
                  </tr>

                  <tr class="child:px-5 child:py-2">
                    <td>
                      Provisioned
                    </td>

                    <td>
                      {{ delaysSummary.provisioned }}
                    </td>
                  </tr>

                  <tr class="child:px-5 child:py-2">
                    <td>
                      Approved
                    </td>

                    <td>
                      {{ delaysSummary.approved }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <AppSpinner
            v-else
            class="mx-auto mt-5"
          />

          <div class="flex flex-col space-y-4 w-2/5">
            <div class="flex h-full">
              <div class="w-1/2 my-auto">
                <p class="text-center text-lg font-semibold">
                  Delays by status
                </p>

                <div class="w-full mt-6">
                  <canvas
                    ref="statusChart"
                  />
                </div>
              </div>

              <div class="w-1/2 my-auto">
                <p class="text-center text-lg font-semibold">
                  Delay Days by Status
                </p>

                <div class="w-full mt-6">
                  <canvas
                    ref="valueChart"
                  />
                </div>
              </div>
            </div>

            <div class="text-sm my-auto">
              <div class="bg-ccm-blue-100 rounded-t-md text-white p-2 font-semibold text-base ">
                Project Summary
              </div>

              <table class="w-full">
                <tbody class="child:border-b child:border-x border-gray-600 font-semibold">
                  <tr class="first-child:pl-4 child:p-2">
                    <td>
                      Contract End Date
                    </td>

                    <td>{{ project.contractStartDate.toLocaleDateString() }}</td>
                  </tr>

                  <tr class="first-child:pl-4 child:p-2">
                    <td>
                      Approved Delay
                    </td>

                    <td>{{ delaysSummary.approved_delays_total }}</td>
                  </tr>

                  <tr class="first-child:pl-4 child:p-2">
                    <td>
                      Approved Contract End Date
                    </td>

                    <td>{{ project.estimateContractEndDate(delaysSummary.approved_delays_total).toLocaleString() }}</td>
                  </tr>

                  <tr class="first-child:pl-4 child:p-2">
                    <td>
                      Unapproved Delay
                    </td>

                    <td>{{ delaysSummary.unapproved_delays_total }}</td>
                  </tr>

                  <tr class="first-child:pl-4 child:p-2">
                    <td>
                      Anticipated Contract End Date
                    </td>

                    <td>{{ project.estimateContractEndDate(totalDelay).toLocaleString() }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>

        <div
          v-else-if="fetchDelaysAction.isNot(States.LOADING)"
          class="font-semibold mx-auto"
        >
          No delays found for project.
        </div>

        <div
          v-else
          class="flex w-full"
        >
          <AppSpinner class="mx-auto" />
        </div>
      </div>
    </div>

    <DelayShowModal
      v-if="showDelayModal"
      :delay-id="selectedDelay"
      @close="showDelayModal = false"
      @attach-variation="showDelayModal = false; attachVariation($event)"
    />

    <DelaySaveModal
      v-if="showCreateDelayModal"
      :project-id="$props.projectId"
      @close="showCreateDelayModal = false"
    />

    <DelayCommentModal
      v-if="showCommentModal"
      :project-id="props.projectId"
      :delay-id="selectedDelay"
      @close="showCommentModal = false"
    />

    <DelayAttachVariationModal
      v-if="showAttachModal"
      :project-id="props.projectId"
      :delay-id="selectedDelay"
      @close="showAttachModal = false"
    />
  </div>
</template>

<script setup lang="ts">
import DelayRow from '@/components/DelaysSchedule/DelayRow.vue';
import DelayAttachVariationModal from '@/components/HeadContract/DelaysSchedule/DelayAttachVariationModal.vue';
import DelayCommentModal from '@/components/HeadContract/DelaysSchedule/DelayCommentModal.vue';
import DelaySaveModal from '@/components/HeadContract/DelaysSchedule/DelaySaveModal.vue';
import DelayShowModal from '@/components/HeadContract/DelaysSchedule/DelayShowModal.vue';
import { useFormData } from '@/composables/useFormData';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency, cumulativeSum, enumKeyFromValue, roundDecimals } from '@/helpers';
import Delay, { DelayStatus, DelayStatusColours, DelayStatusTitles } from '@/models/Delay';
import { useDelaysStore } from '@/store/delays';
import { useProjectsStore } from '@/store/projects';
import { useDebounceFn } from '@vueuse/core';
import { Chart } from 'chart.js';
import Swal from 'sweetalert2';
import { computed, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps<{
  projectId: string;
}>();

const delaysStore = useDelaysStore();
const projectsStore = useProjectsStore();

const project = computed(() => {
  return projectsStore.project;
});

const delaysSummary = ref<DelaysSummary>({
  approved_delays_total: 0,
  unapproved_delays_total: 0,
  registered: 0,
  submitted: 0,
  provisioned: 0,
  approved: 0,
  total_delays: 0,
});

const fetchDelaysAction = useStoreApiAction(delaysStore.fetchDelays);
const fetchDelaysSummaryAction = useStoreApiAction(delaysStore.fetchDelaysSummary);

watch(() => project.value?.id, (newValue) => {
  if(newValue) {
    fetchDelaysAction.request(newValue).catch((error) => {
      console.log(error);
    });

    fetchDelaysSummaryAction.request(newValue).then((summary) => {
      delaysSummary.value = summary;
    }).catch(console.error);
  }
}, { immediate: true });

const router = useRouter();

watch(project, (newValue) => {
  if(newValue && !newValue.boq_locked) {
    router.push({ name: 'BillOfQuantities', params: { projectId: newValue.id } });
  }
}, { immediate: true });

const delays = computed(() => {
  return delaysStore.models.where('project_id', parseInt(props.projectId)).orderBy(
    'status',
  ).get();
});

const totalDelay = computed(() => {
  return delaysSummary.value.unapproved_delays_total + delaysSummary.value.approved_delays_total;
});

const delaysByStatus = computed(() => {
  return delays.value.reduce((delaysMap, delay) => {
    const key = enumKeyFromValue(DelayStatus, delay.status);

    if(delaysMap.has(key)) {
      const delays = delaysMap.get(key);

      delays.push(delay);
      delaysMap.set(key, delays);
    } else {
      delaysMap.set(key, [delay]);
    }

    return delaysMap;
  }, new Map<string, Delay[]>());
});

const acceptDelayAction = useStoreApiAction(delaysStore.acceptDelay);
const declineDelayAction = useStoreApiAction(delaysStore.declineDelay);
const submitDelayAction = useStoreApiAction(delaysStore.submitDelay);
const approveDelayAction = useStoreApiAction(delaysStore.approveDelay);
const updateDelayAction = useStoreApiAction(delaysStore.updateDelay);

const acceptDelay = (delay: Delay) => {
  Swal.fire({
    title: 'Accept Delay?',
    text: delay.description,
    icon: 'question',
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      acceptDelayAction.request(delay.id).then(() => {
        console.log('accepted');
      }).catch((error) => {
        console.log(error);
      });
    }
  });
};

const declineDelay = (delay: Delay) => {
  Swal.fire({
    title: 'Decline Delay?',
    text: delay.description,
    icon: 'question',
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      declineDelayAction.request(delay.id).then(() => {
        console.log('declined');
      }).catch((error) => {
        console.log(error);
      });
    }
  });
};

const submitDelay = (id: Id) => {
  Swal.fire({
    title: 'Submit Delay?',
    text: 'Submit delay to be approved by client',
    icon: 'question',
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      submitDelayAction.request(id).then((data) => {
        console.log(data);
      }).catch((error) => {
        Swal.fire('Failed to Submit', 'Failed to submit delay', 'error');
      });
    }
  });
};

const approveDelay = (id: Id) => {
  Swal.fire({
    title: 'Approve Delay?',
    icon: 'question',
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      approveDelayAction.request(id).then((data) => {
        console.log(data);
      }).catch((error) => {
        Swal.fire('Failed to Approve', '', 'error');
      });
    }
  });
};

const selectedDelay = ref();
const showModal = ref(false);
const showCreateDelayModal = ref(false);
let statusChartCanvas: Chart<'pie'> | undefined;
const statusChart = ref();

watch(statusChart, (newValue) => {
  if(newValue) {
    const ctx = newValue.getContext('2d');

    if(statusChartCanvas !== undefined) {
      statusChartCanvas.destroy();
    }

    const delays = Array.from(delaysByStatus.value.keys());

    const delaysPerStatus = Array.from(delaysByStatus.value.values()).map((delays) => {
      return delays.length;
    });

    const statuses = delays.map((status) => {
      return DelayStatusTitles[status];
    });

    const colours = delays.map((status) => {
      return DelayStatusColours[status];
    });

    const data = {
      labels: statuses,

      datasets: [{
        data: delaysPerStatus,

        backgroundColor: colours,

        hoverOffset: 4,
      }],
    };

    statusChartCanvas = new Chart(ctx, {
      type: 'pie',
      data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
          },

          tooltip: {
            callbacks: {
              label(this, tooltipItem) {
                return `${tooltipItem.dataset.data[tooltipItem.dataIndex]} (${
                  (tooltipItem.dataset.data[tooltipItem.dataIndex] / delaysSummary.value.total_delays * 100).toFixed(2)
                }%)`;
              },
            },
          },
        },

        maintainAspectRatio: false,
      },
    });
  }
});

watch(delaysByStatus, (newValue) => {
  if(statusChartCanvas) {
    const delays = Array.from(newValue.keys());

    const delaysPerStatus = Array.from(newValue.values()).map((delays) => {
      return delays.length;
    });

    const statusLabels = delays.map((status) => {
      return DelayStatusTitles[status];
    });

    const colours = delays.map((status) => {
      return DelayStatusColours[status];
    });

    statusChartCanvas.data.datasets[0].data = delaysPerStatus;
    statusChartCanvas.data.labels = statusLabels;
    statusChartCanvas.data.datasets[0].backgroundColor = colours;

    statusChartCanvas.update();
  }
});

let valueChartCanvas: Chart<'pie'> | undefined;
const valueChart = ref();

watch(valueChart, (newValue) => {
  if(newValue) {
    const ctx = newValue.getContext('2d');

    if(valueChartCanvas !== undefined) {
      valueChartCanvas.destroy();
    }

    const delays = Array.from(delaysByStatus.value.keys());

    const daysPerStatus = Array.from(delaysByStatus.value.values()).map((delays) => {
      return delays.reduce((totalDaysForStatus, delay) => {
        return totalDaysForStatus + (delay.days ?? 0);
      }, 0);
    });

    const statuses = delays.map((status) => {
      return DelayStatusTitles[status];
    });

    const colours = delays.map((status) => {
      return DelayStatusColours[status];
    });

    const data = {
      labels: statuses,

      datasets: [{
        data: daysPerStatus,

        backgroundColor: colours,

        hoverOffset: 4,
      }],
    };

    valueChartCanvas = new Chart(ctx, {
      type: 'pie',
      data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
          },

          tooltip: {
            callbacks: {
              label(this, tooltipItem) {
                return `${tooltipItem.dataset.data[tooltipItem.dataIndex]} (${
                  (tooltipItem.dataset.data[tooltipItem.dataIndex] / totalDelay.value * 100).toFixed(2)
                }%)`;
              },
            },
          },
        },

        maintainAspectRatio: false,
      },
    });
  }
});

watch(delaysByStatus, (newValue) => {
  if(valueChartCanvas?.data) {
    const delays = Array.from(newValue.keys());

    const daysPerStatus = Array.from(newValue.values()).map((delays) => {
      return delays.reduce((totalDaysForStatus, delay) => {
        return totalDaysForStatus + (delay.days ?? 0);
      }, 0);
    });

    const statuses = delays.map((status) => {
      return DelayStatusTitles[status];
    });

    const colours = delays.map((status) => {
      return DelayStatusColours[status];
    });

    valueChartCanvas.data.datasets[0].data = daysPerStatus;
    valueChartCanvas.data.labels = statuses;
    valueChartCanvas.data.datasets[0].backgroundColor = colours;

    valueChartCanvas.update();
  }
});

const searchForm = useFormData({
  search: '',
  status: undefined,
});

const filteredDelayIds = ref<number[] | undefined>([]);

const statusOptions = computed(() => {
  const options = [];

  for(const key in DelayStatus) {
    options.push({ value: DelayStatus[key], label: key });
  }

  return options;
});

const searchDelays = () => {
  fetchDelaysAction.request(project.value?.id, searchForm.form).then((delays) => {
    filteredDelayIds.value = delays.map((variation) => variation.id);
  }).catch((error) => {
    console.log(error);
  });
};

const filteredDelays = computed(() => {
  if(!searchForm.isDirty.value) {
    return delays.value;
  }

  return delays.value.filter((variationOrder) => {
    return filteredDelayIds.value.includes(variationOrder.id);
  });
});

const debounceSearch = useDebounceFn(searchDelays, 300);
const filterActive = ref(false);

watch(searchForm.form, (newValue) => {
  if(!newValue.search && !newValue.status) {
    filterActive.value = false;
  } else {
    filterActive.value = true;
  }
});

watch(searchForm.form, () => {
  if(filterActive.value) {
    debounceSearch();
  }
});

const resetSearch = () => {
  filterActive.value = false;

  searchForm.resetData();

  searchDelays();
};

const showDelayModal = ref(false);

const showDelay = (delay: Delay) => {
  selectedDelay.value = delay.id;

  showDelayModal.value = true;
};

// const totalORders = ref(0);
// const ordersIndex = ref(0);
// const { wrapperProps } = useVirtualList(totalORders, ordersIndex, 40);
// const orderListRef = ref();
// const page = ref(1);
// const paginator = ref<ResourcePaginator<VariationOrder>>();

// const recordQueue = useQueue<number>(10);

// const initialFetch = () => {
//   variationOrdersStore.models.query().where('plant_id', props.plant.id).delete();

//   fetchVariationsAction.request(props.plant.id, plantsStore.filter.form).then((newPaginator) => {
//     recordQueue.reset();
//     totalRecords.value = 0;
//     totalRecords.value += newPaginator.data.length;

//     recordIndex.value = newPaginator.meta.current_page * newPaginator.meta.per_page;

//     recordQueue.enqueueFront(newPaginator.data.map((record: PlantDailyRecord) => {
//       return record.id;
//     }));

//     paginator.value = newPaginator;
//   });
// };

// const scrolled = () => {
//   const recordList = recordListRef.value;

//   if(recordList) {
//     const scrollable = recordList.scrollHeight > recordList.clientHeight;

//     if(
//       page.value > 1 &&
//       scrollable &&
//       recordList.scrollTop === 0
//     ) {
//       fetchRecordsAction.request(props.plant.id, { page: page.value - 1, ...plantsStore.filter.form })
//         .then((newPaginator) => {
//           paginator.value = newPaginator;

//           totalRecords.value -= newPaginator.data.length;
//           recordIndex.value = newPaginator.meta.current_page * newPaginator.meta.per_page;

//           const recordsToDelete = recordQueue.enqueueBack(newPaginator.data.map((record: PlantDailyRecord) => {
//             return record.id;
//           }));

//           plantDailyRecordsStore.models.query().whereId(recordsToDelete).delete();

//           recordList.scrollTop = Math.round(recordList.scrollHeight * 0.1);
//           page.value = newPaginator.meta.current_page;
//         });
//     } else if(
//       paginator.value.meta.last_page !== page.value &&
//       scrollable &&
//       recordList.scrollHeight - recordList.scrollTop === recordList.clientHeight
//     ) {
//       fetchRecordsAction.request(props.plant.id, { page: page.value + 1, ...plantsStore.filter.form })
//         .then((newPaginator) => {
//           paginator.value = newPaginator;

//           totalRecords.value += newPaginator.data.length;
//           recordIndex.value = newPaginator.meta.current_page * newPaginator.meta.per_page;

//           const recordsToDelete = recordQueue.enqueueFront(newPaginator.data.map((record: PlantDailyRecord) => {
//             return record.id;
//           }));

//           plantDailyRecordsStore.models.query().whereId(recordsToDelete).delete();

//           recordList.scrollTop = Math.round(recordList.scrollHeight * 0.2);
//           page.value = newPaginator.meta.current_page;
//         });
//     }
//   }
// };

const addDaysToDelay = (delay: Delay) => {
  Swal.fire({
    icon: 'info',
    title: 'Add Days to Delay',
    text: 'Delay Duration (Days)',
    input: 'number',
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed && result.value) {
      updateDelayAction.request(delay.id, { days: result.value }).then((delay) => {
        Swal.fire({
          icon: 'success',
          title: 'Days added to Delay',
        });
      }).catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Failed to add days',
        });
      });
    }
  });
};

const showCommentModal = ref(false);

const openCommentModal = (delay: Delay) => {
  selectedDelay.value = delay.id;

  showCommentModal.value = true;
};

const variationsExpanded = ref(false);

const toggleExpand = () => {
  if(variationsExpanded.value) {
    variationsExpanded.value = false;
  } else {
    variationsExpanded.value = true;
  }
};

const showAttachModal = ref(false);

const attachVariation = (id: Id) => {
  selectedDelay.value = id;
  showAttachModal.value = true;
};
</script>

<style scoped></style>
