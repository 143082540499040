<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl">
          Create Claim
        </h1>
      </div>
    </template>

    <template #body>
      <div class="flex h-full w-full p-4 overflow-y-auto">
        <div class="flex flex-col w-full my-2 px-3">
          <form
            action=""
            class="w-full flex flex-col space-y-4"
            @submit.prevent="createClaim"
          >
            <p v-if="claimForm.form.start && claimForm.form.end">
              Overriding next claim period to be <b>{{ DateTime.fromFormat(claimForm.form.start, 'yyyy-MM-dd').toLocaleString() }}</b>

              to <b>{{ DateTime.fromFormat(claimForm.form.end, 'yyyy-MM-dd').toLocaleString() }}</b>
            </p>

            <p v-else-if="latestClaim">
              Next claim period will be <b>{{ latestClaim.startDate?.plus({ months: 1 }).startOf('month').toLocaleString() }}</b>

              to <b>{{ latestClaim.endDate?.plus({ months: 1 }).endOf('month').toLocaleString() }}</b>
            </p>

            <p v-else>
              First claim period will be <b>{{ DateTime.fromISO(project.claim_start).startOf('month').toLocaleString() }}</b>

              to <b>{{ DateTime.fromISO(project.claim_start).startOf('month').endOf('month').toLocaleString() }}</b>
            </p>

            <label>
              Start Override
              <AppInput
                v-model="claimForm.form.start"
                name="start"
                type="date"
                class="form-input"
                :error="claimForm.getErrors('start')"
              />
            </label>

            <label>
              End Override
              <AppInput
                v-model="claimForm.form.end"
                name="end"
                type="date"
                class="form-input"
                :error="claimForm.getErrors('end')"
              />
            </label>
          </form>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-center p-4">
        <div>
          <button
            type="button"
            class="btn btn--secondary-blue"
            @click.prevent="$emit('close')"
          >
            Cancel
          </button>

          <button
            type="button"
            class="btn btn--primary-blue ml-2"
            @click="createClaim"
          >
            Create
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import Claim from '@/models/Claim';
import { useClaimsStore } from '@/store/claims';
import { useProjectsStore } from '@/store/projects';
import { DateTime } from 'luxon';
import Swal from 'sweetalert2';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import Modal from '../Modal.vue';

const props = withDefaults(
  defineProps<{
    projectId: Id;
    latestClaim?: Claim;
    boqType: string;
  }>(),
  {
    latestClaim: undefined,
  },
);

const emits = defineEmits<{
  (e: 'close');
}>();

const router = useRouter();
const claimsStore = useClaimsStore();

const claimForm = useFormData({
  start: '',
  end: '',
  boq_type: props.boqType,
});

const createClaimAction = useStoreApiAction(claimsStore.createClaim);

const createClaim = () => {
  claimForm.resetErrors();

  createClaimAction.request(props.projectId, claimForm.form).then((claim) => {
    Swal.fire({
      icon: 'success',
      title: 'Claim Created',
    }).then(() => {
      props.boqType === 'direct' ?
        router.push({
          name: 'ProgressClaims',
          params: {
            claimId: claim.id,
          },
        }) :
        router.push({
          name: 'IndirectProgressClaimsShow',
          params: {
            claimId: claim.id,
          },
        });

      emits('close');
    });
  }).catch((error) => {
    claimForm.setErrors(error.data);
  });
};

const projectsStore = useProjectsStore();

const project = computed(() => {
  return projectsStore.project;
});
</script>

<style scoped></style>
