<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl">
          Delay Details
        </h1>
      </div>
    </template>

    <template #body>
      <div class="h-full w-full p-4 min-w-3xl">
        <div class="flex flex-col space-y-4 items-center w-full">
          <div

            v-if="delay"
            :style="{ backgroundColor: statusColour }"
            class="py-2 px-4 rounded-lg"
          >
            {{ delay.statusTitle }}
          </div>

          <div class="flex space-x-4 w-full">
            <div class="flex flex-col w-1/2 space-y-2">
              <div v-if="delay.variation_order">
                <p class="font-semibold text-lg">
                  Attached to Variation <span class="font-semibold">{{ delay.variation_order.code }}</span>
                </p>

                <p>
                  {{ delay.variation_order.description }}
                </p>
              </div>

              <p class="font-semibold text-lg">
                Delay Details
              </p>

              <form class="flex flex-col space-y-4">
                <label>
                  Description <span class="font-semibold">{{ delay.code }}</span>

                  <AppInput
                    v-model="delayForm.form.description"
                    type="text"
                    name="description"
                    class="form-input"
                    :error="delayForm.getErrors('description')"
                  />
                </label>

                <div>
                  Client Reference

                  <AppInput
                    v-model="delayForm.form.client_ref"
                    type="text"
                    name="client_ref"
                    class="form-input"
                    :error="delayForm.getErrors('client_ref')"
                  />
                </div>

                <label>
                  Notes

                  <textarea
                    v-model="delayForm.form.notes"
                    class="resize-none form-input"
                  />

                  <p
                    v-if="delayForm.hasErrors('notes')"
                    class="text-sm text-red-600"
                  >
                    {{ delayForm.getErrors('notes') }}
                  </p>
                </label>
              </form>
            </div>

            <div class="w-1/2 flex flex-col">
              <p class="font-semibold text-lg">
                Status Timeline
              </p>

              <div class="mt-2">
                <div class="flex justify-between">
                  <span>
                    Raised by {{ delay.user?.name }}
                  </span>

                  <span class="font-semibold">{{ delay.createdAt.toLocaleString() }}</span>
                </div>

                <div
                  v-if="delay.registeredAt"
                  class="flex justify-between"
                >
                  <span>
                    Accepted
                  </span>

                  <span class="font-semibold">{{ delay.registeredAt.toLocaleString() }}</span>
                </div>

                <div
                  v-if="delay.submittedAt"
                  class="flex justify-between"
                >
                  <span>
                    Pricing Submitted
                  </span>

                  <span class="font-semibold">{{ delay.submittedAt.toLocaleString() }}</span>
                </div>

                <div
                  v-if="delay.provisionedAt"
                  class="flex justify-between"
                >
                  <span>
                    Pricing Approved
                  </span>

                  <span class="font-semibold">{{ delay.provisionedAt.toLocaleString() }}</span>
                </div>

                <div
                  v-if="delay.approvedAt"
                  class="flex justify-between"
                >
                  <span>
                    Fully Approved
                  </span>

                  <span class="font-semibold">{{ delay.approvedAt.toLocaleString() }}</span>
                </div>
              </div>

              <hr class="border-gray-300 my-2">

              <div class="flex justify-between">
                <span class="font-semibold">
                  Delay Duration (Days)
                </span>

                <div>
                  {{ delay.days }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-end p-2 space-x-4">
        <button
          v-if="!delay.variation_order"
          type="button"
          class="btn btn--tertiary-blue mr-auto"
          @click="$emit('attachVariation', delayId)"
        >
          Attach Variation
        </button>

        <button
          type="button"
          class="btn btn--secondary-blue w-1/4"
          @click="$emit('close')"
        >
          Close
        </button>

        <button
          type="button"
          class="btn btn--primary-blue w-1/4"
          @click="updateDelay"
        >
          Update
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import Modal from '@/components/Modal.vue';
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency, enumKeyFromValue } from '@/helpers';
import { DelayStatus, DelayStatusColours } from '@/models/Delay';
import { VariationOrderStatus, VariationOrderStatusColours, VariationOrderStatusTitles } from '@/models/VariationOrder';
import { useDelaysStore } from '@/store/delays';
import { useVariationOrdersStore } from '@/store/variationOrders';
import Swal from 'sweetalert2';
import { computed, watch } from 'vue';

const props = defineProps<{
  delayId: Id;
}>();

const emits = defineEmits<{
  (e: 'close');
  (e: 'attachVariation', id: Id);
}>();

const delaysStore = useDelaysStore();
const fetchDelayAction = useStoreApiAction(delaysStore.fetchDelay);

watch(() => props.delayId, (newValue) => {
  if(newValue) {
    fetchDelayAction.request(newValue).catch(console.error);
  }
}, { immediate: true });

const delay = computed(() => {
  return delaysStore.models.with('user').with('variation_order').where(
    'id',
    parseInt(props.delayId as string),
  )
    .first();
});

const statusColour = computed(() => {
  return DelayStatusColours[enumKeyFromValue(DelayStatus, delay.value.status)];
});

const updateDelayOrderAction = useStoreApiAction(delaysStore.updateDelay);

const delayForm = useFormData({
  client_ref: '',
  notes: '',
  description: '',
});

watch(delay, (newValue) => {
  if(newValue) {
    delayForm.setData(newValue);
  }
});

const updateDelay = () => {
  updateDelayOrderAction.request(props.delayId, delayForm.form).then(() => {
    Swal.fire({
      icon: 'success',
      text: 'Delay Updated',
    });
  }).catch(console.error);
};
</script>

<style scoped></style>
