<template>
  <div class="p-5">
    <div class="flex space-x-10">
      <div class="flex flex-col w-full justify-between items-start space-y-10">
        <button
          type="button"
          class="btn btn--primary-blue ml-auto"
          data-test="save-head-contract"
          @click="updateProject"
        >
          Save Head Contract Settings
        </button>

        <div
          v-if="userStore.isRole(['admin', 'project_admin', 'company_admin', 'contract_admin'])"
          class="card mx-auto w-full"
        >
          <div class="card__header">
            <div class="flex justify-between items-center">
              <p>
                Contract
              </p>
            </div>
          </div>

          <div class="w-1/2 p-4">
            <form
              method="post"
              class="flex flex-col space-y-4"
              @submit.prevent="updateProject"
            >
              <template v-if="!project.feature_flag_progress_claims_lite">
                <label>
                  Contractor Email

                  <AppInput
                    v-model="projectForm.form.contractor_email"
                    :error="projectForm.getErrors('contractor_email')"
                    class="form-input"
                    type="text"
                    name="contractor_email"
                  />
                </label>

                <label>
                  PQS Firm Email

                  <AppInput
                    v-model="projectForm.form.pqs_email"
                    :error="projectForm.getErrors('pqs_email')"
                    class="form-input"
                    type="text"
                    name="pqs_email"
                  />
                </label>
              </template>

              <label v-if="project.isContractor">
                Superintendent Email

                <AppInput
                  v-model="projectForm.form.superintendent_email"
                  :error="projectForm.getErrors('superintendent_email')"
                  class="form-input"
                  type="text"
                  name="superintendent_email"
                />
              </label>

              <label v-if="project.isContractor">
                Client Email

                <AppInput
                  v-model="projectForm.form.client_email"
                  :error="projectForm.getErrors('client_email')"
                  class="form-input"
                  type="text"
                  name="client_email"
                />
              </label>

              <label>
                Contract Start Date

                <AppInput
                  v-model="projectForm.form.contract_start_date"
                  :error="projectForm.getErrors('contract_start_date')"
                  class="form-input"
                  type="date"
                  name="con.contract_start_date"
                />
              </label>

              <label>
                Contract End Date

                <AppInput
                  v-model="projectForm.form.contract_end_date"
                  :error="projectForm.getErrors('contract_end_date')"
                  class="form-input"
                  type="date"
                  name="contract_end_date"
                />
              </label>

              <label>
                Claim Start Date

                <AppInput
                  v-model="projectForm.form.claim_start"
                  :error="projectForm.getErrors('claim_start')"
                  class="form-input"
                  type="date"
                  name="claim_start"
                />
              </label>

              <div
                v-if="contractLength"
                class="font-semibold"
              >
                Project Length {{ contractLength }} months
              </div>

              <label>
                Commentary Lock <span class="text-sm text-gray-400">(days before end of month)</span>

                <AppInput
                  v-model="projectForm.form.commentary_lock"
                  :error="projectForm.getErrors('commentary_lock')"
                  class="form-input"
                  type="number"
                  name="commentary_lock"
                />
              </label>
            </form>
          </div>
        </div>

        <div
          v-if="userStore.isRole(['admin', 'project_admin', 'company_admin', 'contract_admin'])"
          class="card mx-auto w-full"
        >
          <div class="card__header">
            <div class="flex justify-between items-center">
              <p>
                Notices
              </p>
            </div>
          </div>

          <div class="w-1/2 p-4">
            <form
              method="post"
              class="flex flex-col space-y-4"
              @submit.prevent="updateProject"
            >
              <label>
                Variation Notice Period <span class="text-sm text-gray-400">(days)</span>

                <AppInput
                  v-model="projectForm.form.nov_period"
                  class="form-input"
                  type="number"
                  name="nov_period"
                  :error="projectForm.getErrors('nov_period')"
                />
              </label>

              <label v-if="project.isContractor">
                Variation Notice Clause

                <textarea
                  v-model="projectForm.form.nov_clause"
                  class="form-input resize-none"
                  type="text"
                  name="nov_clause"
                />

                <span
                  v-if="projectForm.hasErrors('nov_clause')"
                  class="text-red-600 text-sm"
                >{{ projectForm.getErrors('nov_clause') }}</span>
              </label>

              <label v-if="project.isContractor">
                Delay Notice Clause

                <textarea
                  v-model="projectForm.form.nod_clause"
                  class="form-input resize-none"
                  type="text"
                  name="nod_clause"
                />

                <span
                  v-if="projectForm.hasErrors('nod_clause')"
                  class="text-red-600 text-sm"
                >{{ projectForm.getErrors('nod_clause') }}</span>
              </label>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { dateForDateInput } from '@/helpers';
import { useProjectsStore } from '@/store/projects';
import { useUserStore } from '@/store/user';
import { DateTime } from 'luxon';
import Swal from 'sweetalert2';
import { computed, nextTick, ref, watch } from 'vue';

const props = defineProps<{
  projectId: string;
}>();

const emits = defineEmits<{
  (e: 'dirty');
  (e: 'saved');
}>();

const projectsStore = useProjectsStore();

const projectForm = useFormData({
  contractor_email: '',
  pqs_email: '',
  superintendent_email: '',
  client_email: '',
  contract_start_date: '',
  contract_end_date: '',
  claim_start: '',
  nov_clause: '',
  nov_period: 14,
  nod_clause: '',
  commentary_lock: 0,
});

const project = computed(() => {
  return projectsStore.project;
});

watch(project, (newValue) => {
  if(newValue) {
    projectForm.setData(newValue, ['contract_end_date', 'contract_start_date', 'claim_start']);

    projectForm.form.claim_start = dateForDateInput(newValue.claim_start);
    projectForm.form.contract_end_date = dateForDateInput(newValue.contract_end_date);
    projectForm.form.contract_start_date = dateForDateInput(newValue.contract_start_date);

    nextTick(() => {
      projectForm.resetDirtyStatus();
    });
  }
}, { immediate: true });

watch(projectForm.isDirty, (newValue) => {
  if(newValue) {
    emits('dirty');
  } else {
    emits('saved');
  }
});

const updateProjectAction = useStoreApiAction(projectsStore.updateHeadContractSettings);

const updateProject = () => {
  projectForm.resetErrors();

  updateProjectAction.request(props.projectId, projectForm.form).then(() => {
    Swal.fire({
      icon: 'success',
      title: 'Head Contract Settings Updated.',
    });
  }).catch((error) => {
    projectForm.setErrors(error.data);
  });
};

const userStore = useUserStore();

const contractLength = computed(() => {
  if(projectForm.form.contract_end_date && projectForm.form.contract_start_date) {
    const contractEndDate = DateTime.fromFormat(projectForm.form.contract_end_date, 'yyyy-MM-dd').endOf('month');
    const contractStartDate = DateTime.fromFormat(projectForm.form.contract_start_date, 'yyyy-MM-dd').startOf('month');

    if(contractEndDate.isValid && contractStartDate.isValid) {
      return Math.round(Math.abs(contractStartDate.diff(contractEndDate, ['months']).months));
    }
  }

  return undefined;
});
</script>

<style scoped></style>
