<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl">
          Pricing for {{ variationOrder.code }}
        </h1>
      </div>
    </template>

    <template #body>
      <div class="min-w-md flex flex-col p-4 max-h-[30rem] overflow-y-auto w-full">
        <div class="flex justify-between space-x-4 items-center">
          <p class="w-60 text-wrap">
            {{ variationOrder.description }}
          </p>
        </div>

        <div
          v-if="variationOrder.notes"
          class="mt-2"
        >
          {{ variationOrder.notes }}
        </div>

        <form
          action=""
          class="flex flex-col space-y-4 mt-4"
          @submit.prevent=""
        >
          <label>
            Date Received

            <AppInput
              v-model="pricingForm.form.date_received"
              name="date_received"
              type="date"
              class="form-input"
              :error="pricingForm.getErrors('date_received')"
            />
          </label>

          <label>
            To

            <AppInput
              v-model="pricingForm.form.to"
              name="to"
              type="text"
              class="form-input"
              :error="pricingForm.getErrors('to')"
            />
          </label>

          <label>
            From

            <AppInput
              v-model="pricingForm.form.from"
              name="from"
              class="form-input"
              type="text"
              :error="pricingForm.getErrors('from')"
            />
          </label>

          <label>
            Description

            <textarea
              v-model="pricingForm.form.description"
              name="description"
              class="form-input resize-none"
              type="text"
            />

            <span
              v-if="pricingForm.getErrors('description')"
              class="text-red-600 text-sm"
            >{{ pricingForm.getErrors('description') }}</span>
          </label>

          <label class="flex justify-between">
            Response Required

            <input
              v-model="pricingForm.form.response_required"
              name="response_required"
              type="checkbox"
              class="form-checkbox"
              true-value="1"
              false-value="0"
            >
          </label>

          <label class="mt-4">
            Pricing <span class="text-sm text-gray-400">($)</span>

            <AppInput
              v-model="pricingForm.form.budget"
              type="number"
              name="budget"
              class="form-input mt-2"
              :error="pricingForm.getErrors('budget')"
            />
          </label>

          <FileUpload
            v-model="pricingForm.form.media"
            multiple
            @change="addFile"
          />

          <div
            v-if="filesUploaded.length"
            class="overflow-x-auto max-w-sm flex"
          >
            <UploadedFileDisplay
              v-for="(file, i) in filesUploaded"
              :key="i"
              :file="file"
              :error="pricingForm.getErrors(`media.${i}`)"
              @delete="removeFile(i)"
            />
          </div>
        </form>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-end p-2 space-x-4">
        <button
          type="button"
          class="btn btn--secondary-blue"
          @click="$emit('close')"
        >
          Cancel
        </button>

        <button
          type="button"
          class="btn btn--primary-blue"
          data-test="apply-filter"
          @click="savePricingNotice"
        >
          Save
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import FileUpload from '@/components/FileUpload.vue';
import Modal from '@/components/Modal.vue';
import UploadedFileDisplay from '@/components/UploadedFileDisplay.vue';
import { useFileUploadForm } from '@/composables/useFileUploadForm';
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency, dateForDateInput, falsey } from '@/helpers';
import { useVariationOrdersStore } from '@/store/variationOrders';
import Swal from 'sweetalert2';
import { computed, ref, watch } from 'vue';

const emit = defineEmits<{
  (e: 'close');
  (e: 'priced');
}>();

const props = defineProps<{
  variationOrderId: Id;
}>();

const variationsOrdersStore = useVariationOrdersStore();

const variationOrder = computed(() => {
  return variationsOrdersStore.models.with('trade_items').find(props.variationOrderId);
});

const pricingForm = useFormData({
  budget: 0,
  date_received: dateForDateInput(new Date()),
  to: '',
  from: '',
  description: '',
  response_required: 0,
  media: [],
});

const variationOrderPricingAction = useStoreApiAction(variationsOrdersStore.storePricingNotice);

const savePricingNotice = () => {
  variationOrderPricingAction.request(props.variationOrderId, pricingForm.toFormDataObject()).then((data) => {
    Swal.fire('Variation Priced', '', 'success').then(() => {
      emit('priced');
      emit('close');
    });
  }).catch((error) => {
    pricingForm.setErrors(error.data);
    console.log(error);
  });
};

const { filesUploaded, addFile, removeFile } = useFileUploadForm(undefined, (file, index) => {
  pricingForm.form.media.splice(index, 1);
});
</script>

<style scoped></style>
