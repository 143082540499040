<template>
  <div class="bg-white">
    Audit Log
    <div
      v-for="audit in audits"
      :key="audit.id"
      class="py-6"
      :class="`audit-log-badge--${audit.log_name}`"
    >
      {{ audit }}
    </div>
  </div>
</template>

<script setup lang="ts">
import ActivityLogService from '@/services/AuditLogService';
import { ref } from 'vue';

const props = defineProps<{
  projectId: string;
}>();

const audits = ref([]);

ActivityLogService.index(props.projectId).then((data) => {
  audits.value = data[0];
});
</script>

<style scoped></style>
