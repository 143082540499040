import { route } from '@/ziggy-shim';
import { api, apiConfig, CastValue } from './LaravelClient';

export default {
  async index(projectId: Id, data: ApiRequests['api.v1.delays.index']) {
    return await api(route('api.v1.delays.index', projectId), 'get', {
      data,
      casts: {
        search: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        status: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },

  async show(id: Id) {
    return await api(route('api.v1.delays.show', id), 'get');
  },

  async accept(id: Id) {
    return await api(route('api.v1.delays.accept', id), 'post');
  },

  async decline(id: Id) {
    return await api(route('api.v1.delays.decline', id), 'post');
  },

  async submit(id: Id) {
    return await api(route('api.v1.delays.submit', id), 'post');
  },

  async provision(id: Id, signedParams: unknown) {
    return await apiConfig(route('api.v1.delays.provision', id), 'post', {
      params: signedParams,
      paramsSerializer: {
        serialize: (params) => {
          return new URLSearchParams(params).toString();
        },
      },
    });
  },

  async approve(id: Id) {
    return await api(route('api.v1.delays.approve', id), 'post');
  },

  async create(projectId: Id, data: ApiRequests['api.v1.delays.store']) {
    return await api(route('api.v1.delays.store', projectId), 'post', {
      data,
      casts: {
        notes: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },

  async update(id: Id, data: ApiRequests['api.v1.delays.update']) {
    return await api(route('api.v1.delays.update', id), 'patch', {
      data,
      casts: {
        notes: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        client_ref: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },
  // async delete(id: Id) {
  //   return await api(route('api.v1.delays.destroy', id), 'delete');
  // },

  async summary(projectid: Id) {
    return await api(route('api.v1.delays.summary', projectid), 'get');
  },

  async comment(id: Id, data: ApiRequests['api.v1.delays.comments']) {
    return await api(route('api.v1.delays.comments', id), 'post', {
      data,
    });
  },

  async attachVariationOrder(id: Id, data: ApiRequests['api.v1.delays.attach-variation-order']) {
    return await api(route('api.v1.delays.attach-variation-order', id), 'post', {
      data,
      casts: {
        notes: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },
};
