<template>
  <div class="w-full">
    <canvas
      ref="valueChart"
    />
  </div>
</template>

<script setup lang="ts">
import { australianCurrency } from '@/helpers';
import VariationOrder, { VariationOrderStatusColours, VariationOrderStatusTitles } from '@/models/VariationOrder';
import { Chart } from 'chart.js';
import { useTemplateRef, watch } from 'vue';
import { ref } from 'vue';

const props = defineProps<{
  variationOrdersByStatus: Map<string, VariationOrder[]>;
  variationsSummary: VariationOrdersSummary;
  totalVariationsBudget: number;
}>();

let valueChartCanvas: Chart<'pie'> | undefined;
const valueChart = useTemplateRef('valueChart');

watch(valueChart, (newValue) => {
  if(newValue) {
    const ctx = newValue.getContext('2d');

    if(valueChartCanvas !== undefined) {
      valueChartCanvas.destroy();
    }

    const variations = Array.from(props.variationOrdersByStatus.keys());

    const budgetPerStatus = Array.from(props.variationOrdersByStatus.values()).map((variations) => {
      return variations.reduce((totalBudgetForStatus, variation) => {
        return totalBudgetForStatus + (variation.totalBudget ?? 0);
      }, 0);
    });

    const statusLabels = variations.map((status) => {
      return VariationOrderStatusTitles[status];
    });

    const colours = variations.map((status) => {
      return VariationOrderStatusColours[status];
    });

    const data = {
      labels: statusLabels,

      datasets: [{
        data: budgetPerStatus,

        backgroundColor: colours,

        hoverOffset: 4,
      }],
    };

    valueChartCanvas = new Chart(ctx, {
      type: 'pie',
      data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
          },

          tooltip: {
            callbacks: {
              label(this, tooltipItem) {
                return `${australianCurrency(tooltipItem.dataset.data[tooltipItem.dataIndex])} (${
                  (tooltipItem.dataset.data[tooltipItem.dataIndex] / props.totalVariationsBudget * 100).toFixed(2)
                }%)`;
              },
            },
          },
        },

        maintainAspectRatio: false,
      },
    });
  }
});

watch(() => props.variationOrdersByStatus, (newValue) => {
  if(valueChartCanvas?.data) {
    const variations = Array.from(newValue.keys());

    const budgetPerStatus = Array.from(newValue.values()).map((variations) => {
      return variations.reduce((totalBudgetForStatus, variation) => {
        return totalBudgetForStatus + (variation.totalBudget ?? 0);
      }, 0);
    });

    const statuses = variations.map((status) => {
      return VariationOrderStatusTitles[status];
    });

    const colours = variations.map((status) => {
      return VariationOrderStatusColours[status];
    });

    valueChartCanvas.data.datasets[0].data = budgetPerStatus;
    valueChartCanvas.data.labels = statuses;
    valueChartCanvas.data.datasets[0].backgroundColor = colours;

    valueChartCanvas.update();
  }
});
</script>

<style scoped></style>
