<template>
  <div
    class="cursor-pointer border-2 border-gray-400 border-dashed flex flex-col justify-center items-center w-full h-full rounded p-5"
    @click="fileInput.click()"
  >
    <button
      type="button"
      class="flex flex-col space-y-2 items-center text-ccm-blue-500"
    >
      <div class="flex space-x-2">
        <Icon
          name="upload"
          class="w-6 h-6 "
        />

        <span class="text-sm md:text-base font-medium">Upload a file </span>
      </div>

      <span
        v-if="error"
        class="text-red-600 text-sm block"
      >{{ error }}</span>
    </button>

    <input
      ref="fileInput"
      hidden
      name="images"
      type="file"
      v-bind="$attrs"
      @change="fileUploaded"
    >
  </div>
</template>

<script setup lang="ts">
import { ref, useTemplateRef } from 'vue';

withDefaults(
  defineProps<{
    // name: string;
    // modelValue: string | number | null | undefined;
    error?: string;
  }>(),
  {
    error: undefined,
  },
);

defineOptions({
  inheritAttrs: false,
});

// TODO: 14/03/25 enable 2 way sync when removing file
const filesUploaded = defineModel();

const fileUploaded = (event: Event) => {
  const files = (event.target as HTMLInputElement).files;

  filesUploaded.value = Array.from(files);
};

const fileInput = useTemplateRef('fileInput');

const resetInput = () => {
  /* eslint-disable no-null/no-null */
  fileInput.value.value = null;
  filesUploaded.value = [];
};

defineExpose({
  resetInput,
});
</script>

<style scoped></style>
