<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl">
          New Variation
        </h1>
      </div>
    </template>

    <template #body>
      <div class="h-full w-full p-4 min-w-md">
        <form
          class="flex flex-col space-y-4"
          @submit.prevent="saveVariationOrder"
        >
          <div class="font-semibold">
            Today's Date {{ new Date().toLocaleDateString() }}
          </div>

          <label>
            Date of Occurrence

            <AppInput
              v-model="variationOrderForm.form.date_of_occurrence"
              :error="variationOrderForm.getErrors('date_of_occurrence')"
              class="form-input"
              type="date"
              name="date_of_occurrence"
            />
          </label>

          <label>
            Description
            <textarea
              v-model="variationOrderForm.form.description"
              name="description"
              class="form-input resize-none"
            />
          </label>

          <label>
            Notes
            <textarea
              v-model="variationOrderForm.form.notes"
              name="notes"
              class="form-input resize-none"
            />
          </label>

          <FileUpload
            ref="fileInputComponent"
            v-model="variationOrderForm.form.images"
            multiple
            @change="fileUploaded"
          />

          <span
            v-if="filesUploaded.length"
            class="font-semibold"
          >
            Attachments
          </span>

          <div
            v-if="filesUploaded.length"
            class="overflow-x-auto max-w-sm flex"
          >
            <UploadedFileDisplay
              v-for="(file, i) in filesUploaded"
              :key="i"
              :file="file"
              :error="variationOrderForm.getErrors(`images.${i}`)"
              @delete="removeFile(i)"
            />
          </div>
        </form>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-end p-2 space-x-4">
        <button
          type="button"
          class="btn btn--secondary-blue"
          @click="$emit('close')"
        >
          Cancel
        </button>

        <button
          type="button"
          class="btn btn--primary-blue"
          data-test="apply-filter"
          :disabled="createVariationOrderAction.is(States.LOADING)"
          @click="saveVariationOrder"
        >
          Create
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import FileUpload from '@/components/FileUpload.vue';
import Modal from '@/components/Modal.vue';
import UploadedFileDisplay from '@/components/UploadedFileDisplay.vue';
import { useFormData } from '@/composables/useFormData';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { dateForDateInput, getFileMime } from '@/helpers';
import { useVariationOrdersStore } from '@/store/variationOrders';
import { ref } from 'vue';

const props = defineProps<{
  projectId: string;
  variationOrderId?: string;
}>();

const emits = defineEmits<{
  (e: 'close');
}>();

const variationOrdersStore = useVariationOrdersStore();
const createVariationOrderAction = useStoreApiAction(variationOrdersStore.createVariationOrder);

const variationOrderForm = useFormData({
  description: '',
  type: 0,
  name: '',
  notes: '',
  images: [],
  date_of_occurrence: dateForDateInput(new Date()),
});

const saveVariationOrder = () => {
  createVariationOrderAction.request(props.projectId, variationOrderForm.toFormDataObject()).then((variationOrder) => {
    emits('close');
  }).catch((error) => {
    variationOrderForm.setErrors(error.data);
  });
};

const filesUploaded = ref<UploadedFile[]>([]);

const fileUploaded = async (event: Event) => {
  const files = (event.target as HTMLInputElement).files;

  filesUploaded.value = [];

  Array.from(files).forEach(async (file) => {
    const mimeType = await getFileMime(file);

    filesUploaded.value.push({
      fileName: file.name,
      mime: mimeType,
      fileBlob: file,
    });
  });
};

const removeFile = (index: number) => {
  filesUploaded.value.splice(index, 1);
  variationOrderForm.form.images.splice(index, 1);
};
</script>

<style scoped></style>
