<template>
  <div class="!pt-0">
    <div class="flex flex-col space-y-2.5 max-h-screen pt-16 pb-2.5">
      <div class="flex items-center justify-between space-x-4 mt-2.5">
        <div class="flex space-x-2 element-island">
          <h1 class="font-semibold text-3xl">
            Bill of Quantities - Development Costs
          </h1>
        </div>
      </div>

      <div
        v-if="trades.length === 0 && fetchTradesAction.state.value === States.COMPLETE"
        class="py-5 px-3 element-island text-center font-semibold w-1/2 mx-auto flex flex-col items-center space-y-4"
      >
        <span>
          No Indirect Costs found.
        </span>

        <button
          type="button"
          class="btn btn--secondary-blue"
          @click="createTrade"
        >
          Add First Indirect Cost
        </button>
      </div>

      <div
        v-else-if="fetchTradesAction.state.value === States.COMPLETE"
        class="element-island w-1/2 mx-auto overflow-y-auto"
      >
        <div
          v-if="trades.length > 0"
          class="card w-full"
        >
          <div class="card__header sticky -top-2 flex justify-between items-center z-10">
            <button
              type="button"
              class="btn btn--secondary-blue"
              @click="createTrade"
            >
              Add Indirect Cost
            </button>

            <div>
              Total amount: {{ australianCurrency(contractAmount) }}
            </div>
          </div>

          <div class="w-full p-4 ">
            <draggable
              :list="trades"
              item-key="id"
              class="flex flex-col space-y-2"
              :class="{ 'opacity-50 pointer-events-none': updateSortAction.is(States.LOADING) }"
              :disabled="disableDraggable"
              @end="changeSortOrder"
            >
              <template
                v-if="updateSortAction.is(States.LOADING)"
                #header
              >
                <div class="absolute left-1/2 -translate-x-1/2 translate-y-1/2 bottom-1/2">
                  <AppSpinner />
                </div>
              </template>

              <template #item="{element}">
                <div>
                  <TradeRow
                    :trade="element"
                    @edit="selectTrade(element)"
                    @delete="deleteTrade(element)"
                    @modal-close="disableDraggable = false"
                    @modal-open="disableDraggable = true"
                  />

                  <div class="group">
                    <button
                      type="button"
                      class="w-full flex justify-center mt-2 cursor-pointer invisible group-hover:visible p-1"
                      @click="showTradeAndSelectPosition(element.sort_order + 1)"
                    >
                      <Icon
                        class="w-4 rounded-full border border-black"
                        name="plus"
                      />
                    </button>
                  </div>
                </div>
              </template>
            </draggable>
          </div>
        </div>
      </div>

      <div v-else>
        <AppSpinner class="h-[68px]" />
      </div>
    </div>

    <TradeSaveModal
      v-if="showTradeModal"
      :trade="selectedTrade"
      :project-id="$props.projectId"
      :selected-position="selectedPosition"
      boq-type="indirect"
      @update-sort="changeSortOrderForTrade($event.trade, $event.index)"
      @close="showTradeModal = false; selectedPosition = undefined"
    >
      <template #title>
        Indirect Cost
      </template>
    </TradeSaveModal>
  </div>
</template>

<script setup lang="ts">
import TradeRow from '@/components/HeadContract/BillOfQuantities/TradeRow.vue';
import TradeSaveModal from '@/components/HeadContract/BillOfQuantities/TradeSaveModal.vue';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency } from '@/helpers';
import Trade from '@/models/Trade';
import { useTradesStore } from '@/store/trades';
import Icon from '@/svgs/Icon.vue';
import Swal from 'sweetalert2';
import { computed, ref, watch } from 'vue';
import draggable from 'vuedraggable';

const props = defineProps<{
  projectId: string;
}>();

const tradesStore = useTradesStore();
const fetchTradesAction = useStoreApiAction(tradesStore.fetchTrades);

watch(() => props.projectId, () => {
  fetchTradesAction.request(props.projectId, { boq_type: 'indirect' }).catch((error) => {
    console.log(error);
  });
}, { immediate: true });

const trades = computed(() => {
  return tradesStore.models
    .with('trade_items')
    .where('boq_type', 'indirect')
    .where('project_id', parseInt(props.projectId))
    .orderBy('sort_order')
    .get();
});

const contractAmount = computed(() => {
  return trades.value.reduce((total, trade) => {
    return total + trade.totalBudget;
  }, 0);
});

const selectedTrade = ref<Trade | undefined>();
const selectedPosition = ref<number | undefined>();
const showTradeModal = ref(false);

const showTradeAndSelectPosition = (position: number) => {
  selectedPosition.value = position;
  showTradeModal.value = true;
};

const selectTrade = (trade: Trade) => {
  selectedTrade.value = trade;
  showTradeModal.value = true;
};

const createTrade = () => {
  selectedTrade.value = undefined;
  showTradeModal.value = true;
};

const deleteTradeAction = useStoreApiAction(tradesStore.deleteTrade);

const deleteTrade = (trade: Trade) => {
  Swal.fire({
    icon: 'warning',
    title: 'Delete Indirect Cost?',
    text: `You are about to delete ${trade.name}. This action cannot be undone.`,
    showCancelButton: true,
    showConfirmButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      deleteTradeAction.request(trade.id).then(() => {
        Swal.fire({
          icon: 'warning',
          title: 'Indirect Cost Deleted.',
        });
      }).catch((error) => {
        if(error?.status === 422 && error?.data.errors.trade_items) {
          Swal.fire({
            icon: 'error',
            title: `Failed to delete indirect cost ${trade.name}`,
            text: error?.data.errors.trade_items[0],
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: `Failed to delete indirect cost ${trade.name}`,
          });
        }
      });
    }
  });
};

const updateSortAction = useStoreApiAction(tradesStore.updateSort);

const changeSortOrderForTrade = (trade: Trade, index: number) => {
  updateSortAction.request(trade.id, {
    sort_order: index,
  }).then((data) => {
    console.log(data);
  }).catch((error) => {
    Swal.fire({
      title: 'Something went wrong.',
      icon: 'error',
    });
  });
};

const changeSortOrder = (e) => {
  changeSortOrderForTrade(e.item.__draggable_context.element, e.newIndex + 1);
};

const disableDraggable = ref(false);
</script>

<style scoped></style>
