<template>
  <div class="!pt-0">
    <div class="flex flex-col space-y-2.5 max-h-screen pt-16 pb-2.5">
      <div class="flex items-center justify-between space-x-4 mt-2.5">
        <div class="flex items-center space-x-4 element-island">
          <h1 class="font-semibold text-3xl">
            Indirect Cost Claims
          </h1>
        </div>

        <div
          v-if="!latestClaim || latestClaim?.status === ClaimStatus.certified"
          class="element-island flex space-x-4"
        >
          <button
            type="button"
            class="btn btn--secondary-blue"
            @click.prevent="show = true"
          >
            New Claim
          </button>
        </div>
      </div>

      <div class="element-island flex mx-auto">
        <table
          v-if="claims.length"
          class="w-full border-separate border-spacing-0 border border-black rounded-lg"
        >
          <thead>
            <tr class="first-child:pl-5 last-child:pr-5 child:px-5 child:py-3 text-left rounded-tl-lg bg-gray-300 first-child:rounded-tl-lg last-child:rounded-tr-lg">
              <th>
                Claim
              </th>

              <th>
                Period
              </th>

              <th>
                Created
              </th>

              <th>
                Certified
              </th>

              <th class="text-right">
                Certified Amount
              </th>

              <th class="text-right">
                Cumulative Certified
              </th>
            </tr>

            <tr class="first-child:pl-5 last-child:pr-5 child:border-y child:border-black child:px-5 child:py-2 text-left odd:bg-gray-100 even:bg-white sticky -top-2 bg-white z-10">
              <td
                colspan="4"
              />

              <td class="text-right">
                {{ australianCurrency(totalCertified) }}
              </td>

              <td class="text-right">
                {{ australianCurrency(totalCertified) }}
              </td>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(projectClaim, i) in claims"
              :key="projectClaim.id"
              class="group first-child:pl-5 last-child:pr-5 child:px-5 child:py-3 odd:bg-gray-100 even:bg-white hover:bg-blue-100 cursor-pointer"
              @click="router.push({ name: 'IndirectProgressClaimsShow', params: { claimId: projectClaim.id }})"
            >
              <td class="group-last:rounded-bl-lg">
                <div class="flex justify-between">
                  <div class="flex flex-col">
                    <p>
                      Claim No. {{ projectClaim.claim_number }}

                      <span
                        v-if="overallStatus(projectClaim.status)"
                        :class="[overallStatus(projectClaim.status).colour]"
                        class="p-1 rounded-lg text-white m-1 font-semibold text-sm"
                      >
                        {{ overallStatus(projectClaim.status).label }}
                      </span>
                    </p>
                  </div>
                </div>
              </td>

              <td>
                <p>
                  {{ new Date(projectClaim.start).toDateString() }} - {{ new Date(projectClaim.end).toDateString() }}
                </p>
              </td>

              <td>
                <p>
                  {{ projectClaim.createdAt.toLocaleString() }}
                </p>
              </td>

              <td class="group-last:rounded-br-lg">
                <p v-if="projectClaim.submittedAt">
                  {{ projectClaim.submittedAt.toLocaleString() }}
                </p>
              </td>

              <td class="text-right">
                <p v-if="projectClaim.status === ClaimStatus.certified">
                  {{ australianCurrency(projectClaim.totalCertified) }}
                </p>
              </td>

              <td class="group-last:rounded-br-lg text-right">
                <p v-if="projectClaim.status === ClaimStatus.certified">
                  {{ australianCurrency(cumulativeCertified[i - numberOfDraftClaims]) }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <div
          v-else
          class="m-auto p-5"
        >
          No Claims for Project.
        </div>
      </div>

      <CreateClaimModal
        v-if="show"
        :project-id="$props.projectId"
        :latest-claim="latestClaim"
        boq-type="indirect"
        @close="show = false"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import CreateClaimModal from '@/components/ProgressClaims/CreateClaimModal.vue';
import { australianCurrency, cumulativeSum, enumKeyFromValue } from '@/helpers';
import { ClaimStatus, ClaimStatusTitles } from '@/models/Claim';
import { useClaimsStore } from '@/store/claims';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps<{
  projectId: string;
}>();

const claimsStore = useClaimsStore();
const router = useRouter();

watch(() => [props.projectId], () => {
  claimsStore.fetchClaims(props.projectId, { boq_type: 'indirect' });
}, { immediate: true });

const show = ref(false);

const claims = computed(() => {
  return claimsStore.models.where('boq_type', 'indirect').where('project_id', parseInt(props.projectId)).orderBy(
    'claim_number',
    'desc',
  ).orderBy(
    'claim_number',
    'desc',
  ).get();
});

const overallStatus = (status: ObjectValues<typeof ClaimStatus>) => {
  switch (true) {
    case status === ClaimStatus.certified:
      return { colour: 'bg-green-600', label: 'Certified' };

    default:
      return { colour: 'bg-red-600', label: 'Draft' };
  }
};

const latestClaim = computed(() => {
  if(claims.value.length) {
    return claims.value[0];
  }

  return undefined;
});

const totalCertified = computed(() => {
  return claims.value.filter((claim) => claim.status === ClaimStatus.certified).reduce((total, claim) => {
    return total += claim.totalCertified ?? 0;
  }, 0);
});

const cumulativeCertified = computed(() => {
  return cumulativeSum(
    claims.value.filter((claim) => claim.status === ClaimStatus.certified).map((claim) => claim.totalCertified)
      .reverse(),
  ).reverse();
});

const numberOfDraftClaims = computed(() => {
  return claims.value.filter((claim) => claim.status === ClaimStatus.draft).length;
});
</script>

<style scoped></style>
