import MediaService from '@/services/MediaService';
import { defineStore } from 'pinia';

export const useMediaStore = defineStore('media', {
  state: () => ({}),

  getters: {},

  actions: {
    async refreshMedia(uuid: Id) {
      const [data, error] = await MediaService.show(uuid);

      if(data) {
        return data;
      }

      throw error;
    },

    async deleteMedia(uuid: Id) {
      const [data, error] = await MediaService.delete(uuid);

      if(data) {
        return data;
      }

      throw error;
    },

    async downloadMedia(uuid: Id) {
      const [data, error] = await MediaService.download(uuid);

      if(data) {
        return data;
      }

      throw error;
    },
  },
});
