<template>
  <div
    class="group p-2 relative flex justify-center items-center"
  >
    <button
      v-if="media.mime_type.startsWith('image/')"
      type="button"
      @click="showFullImage = true"
    >
      <img
        :src="media.custom_properties.access_url"
        :alt="media.name"
        @error="$emit('refresh', media)"
      >
    </button>

    <div
      v-else
      class="flex flex-col items-center w-full"
    >
      <div class=" space-x-2 items-center hidden group-hover:flex">
        <button
          type="button"
          @click="deleteMedia"
        >
          <Icon
            name="trash"
            class="btn-icon btn-icon-red w-6 h-6 group-hover:text-red-600 fill-red-300"
          />
        </button>

        <a
          class="bg-ccm-blue-100 rounded-lg p-1"
          :href="downloadMediaRoute"
        >
          <Icon
            name="download"
            class="btn-icon btn-icon-blue w-5 h-5 text-white"
          />
        </a>
      </div>

      <Icon
        name="document-text"
        class="w-6 h-6 group-hover:hidden"
      />

      <p class="font-semibold mt-2 text-nowrap">
        {{ media.file_name }}
      </p>
    </div>

    <div
      v-if="media.mime_type.startsWith('image/')"
      class="absolute hidden group-hover:flex space-x-2 items-center"
    >
      <button
        type="button"
        @click="deleteMedia"
      >
        <Icon
          name="trash"
          class="btn-icon btn-icon-red w-8 h-8 text-red-600 fill-red-300"
        />
      </button>

      <a
        class="bg-ccm-blue-100 rounded-lg p-1"
        :href="downloadMediaRoute"
      >
        <Icon
          name="download"
          class="btn-icon btn-icon-blue w-5 h-5 text-white"
        />
      </a>
    </div>

    <Teleport
      v-if="showFullImage"
      to="#modals"
    >
      <div
        class="absolute"
        tabindex="0"
      >
        <div class="fixed z-50 flex justify-center items-center m-4 inset-0">
          <img
            :src="media.custom_properties.access_url"
            :alt="media.name"
            class="max-h-screen p-10"
            @error="$emit('refresh', media)"
          >
        </div>

        <button
          type="button"
          @click="showFullImage = false"
        >
          <Icon
            name="close"
            class="h-10 w-10 text-white fixed z-50 top-0 right-0 m-5"
          />
        </button>

        <div
          class="opacity-75 fixed inset-0 z-40 bg-black"
        />
      </div>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { Media } from '@/composables/useMediaCollection';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { useMediaStore } from '@/store/media';
import { route } from '@/ziggy-shim';
import Swal from 'sweetalert2';
import { computed, ref } from 'vue';

const props = defineProps<{
  media: Media;
}>();

const emits = defineEmits<{
  (e: 'delete', media: Media);
  (e: 'refresh', media: Media);
}>();

const mediaStore = useMediaStore();
const deleteMediaAction = useStoreApiAction(mediaStore.deleteMedia);

const deleteMedia = () => {
  Swal.fire({
    icon: 'question',
    title: 'Delete Media?',
    text: 'Are you sure you want to delete this attachment?',
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      deleteMediaAction.request(props.media.uuid).then(() => {
        emits('delete', props.media);
      }).catch(console.error);
    }
  });
};

const downloadMediaRoute = computed(() => {
  return route('api.v1.media.download', props.media.uuid);
});

const showFullImage = ref(false);
</script>

<style scoped></style>
