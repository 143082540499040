<template>
  <div class="!pt-0">
    <div class="flex flex-col space-y-2.5 max-h-screen pt-16 pb-2.5">
      <div class="flex items-center justify-between space-x-4 mt-2.5">
        <div class="flex items-center space-x-2 element-island">
          <h1 class="font-semibold text-3xl">
            Certified Costs
          </h1>

          <div>
            <h2 v-if="claim">
              Claim {{ claim.claim_number }} {{ ClaimStatusTitles[enumKeyFromValue(ClaimStatus, claim.status)] }}
            </h2>

            <p>
              {{ new Date(claim.start).toDateString() }} - {{ new Date(claim.end).toDateString() }}
            </p>
          </div>

          <button
            v-if="claim.status === ClaimStatus.draft_pqs"
            type="button"
            class="btn btn--secondary-blue"
            @click="sendContractor"
          >
            Send Draft Claim
          </button>

          <button
            v-if="claim.status === ClaimStatus.contractor_claim && canEditClaim"
            type="button"
            class="btn btn--secondary-blue"
            @click="sendPqs"
          >
            Submit Claim
          </button>

          <button
            v-if="claim.status === ClaimStatus.pqs_review && canEditCertified"
            type="button"
            class="btn btn--secondary-blue"
            :disabled="submitAction.is(States.LOADING)"
            @click="submit"
          >
            <AppSpinner
              v-if="submitAction.is(States.LOADING)"
              class="mx-10"
            />

            <p v-else>
              Issue Certificate
            </p>
          </button>
        </div>
      </div>

      <div class="element-island overflow-y-auto">
        <table class="w-full">
          <thead class="">
            <tr class="sticky -top-2 bg-gray-300">
              <th class="py-2 text-left pl-3">
                Trade
              </th>

              <th class="text-right pr-3">
                Budget
              </th>

              <th class="text-right bg-green-200/50">
                Current Claim
              </th>

              <th class="text-right bg-green-200/50">
                Certified
              </th>

              <th class="text-right bg-green-200/50">
                Previous
              </th>

              <th class="text-right bg-green-200/50 pr-3">
                Cumulative
              </th>

              <th class="text-right bg-blue-300/50">
                Claim %
              </th>

              <th class="text-right bg-blue-300/50">
                Certified %
              </th>

              <th class="text-right bg-blue-300/50">
                Previous %
              </th>

              <th class="text-right bg-blue-300/50 pr-3">
                Cumulative %
              </th>

              <th class="text-right">
                Variance
              </th>

              <th class="text-right pr-3 border-r">
                Cost To Complete
              </th>
            </tr>
          </thead>

          <tbody
            v-if="claim"
            class="border-t border-black"
          >
            <TradeRow
              v-for="trade in trades"
              :key="trade.id"
              :trade="trade"
              :claim="claim"
            />

            <tr class="border-black border">
              <td class="pl-3">
                Total:
              </td>

              <td class="text-right">
                {{ australianCurrency(totalCosts.trades.budget) }}
              </td>

              <td class="text-right">
                {{ australianCurrency(totalCosts.trades.current_claim) }}
              </td>

              <td class="text-right">
                {{ australianCurrency(totalCosts.trades.current_certified) }}
              </td>

              <td class="text-right">
                {{ australianCurrency(totalCosts.trades.previous) }}
              </td>

              <td class="text-right">
                {{ australianCurrency(totalCosts.trades.cumulativeCertified) }}
              </td>

              <td class="text-right">
                {{ australianNumber((totalCosts.trades.current_claim / totalCosts.trades.budget) * 100) }}%
              </td>

              <td class="text-right">
                {{ australianNumber((totalCosts.trades.current_certified / totalCosts.trades.budget) * 100) }}%
              </td>

              <td class="text-right">
                {{ australianNumber((totalCosts.trades.previous / totalCosts.trades.budget) * 100) }}%
              </td>

              <td class="text-right">
                {{ australianNumber((totalCosts.trades.cumulativeCertified / totalCosts.trades.budget) * 100) }}%
              </td>

              <td class="text-right">
                {{ australianCurrency(totalCosts.trades.current_claim - totalCosts.trades.current_certified) }}
              </td>

              <td class="text-right pr-3">
                {{ australianCurrency(Math.abs(totalCosts.trades.budget - totalCosts.trades.current_certified)) }}
              </td>
            </tr>

            <template
              v-for="(costs, key) in variationOrderCosts"
              :key="key"
            >
              <VariationOrderRow
                :claim="claim"
                :costs="costs"
                :title="key"
                :variation-order-type="VariationOrderType[key]"
              />
            </template>

            <tr class="border-black border">
              <td class="pl-3">
                Total:
              </td>

              <td class="text-right">
                {{ australianCurrency(totalCosts.variationOrders.budget) }}
              </td>

              <td class="text-right">
                {{ australianCurrency(totalCosts.variationOrders.current_claim) }}
              </td>

              <td class="text-right">
                {{ australianCurrency(totalCosts.variationOrders.current_certified) }}
              </td>

              <td class="text-right">
                {{ australianCurrency(totalCosts.variationOrders.previous) }}
              </td>

              <td class="text-right">
                {{ australianCurrency(totalCosts.variationOrders.cumulativeCertified) }}
              </td>

              <td class="text-right">
                {{ totalCosts.variationOrders.budget > 0 ? australianNumber((totalCosts.variationOrders.current_claim / totalCosts.variationOrders.budget) * 100) : 0 }}%
              </td>

              <td class="text-right">
                {{ totalCosts.variationOrders.budget > 0 ? australianNumber((totalCosts.variationOrders.current_certified / totalCosts.variationOrders.budget) * 100) : 0 }}%
              </td>

              <td class="text-right">
                {{ totalCosts.variationOrders.budget > 0 ? australianNumber((totalCosts.variationOrders.previous / totalCosts.variationOrders.budget) * 100) : 0 }}%
              </td>

              <td class="text-right">
                {{ totalCosts.variationOrders.budget > 0 ? australianNumber((totalCosts.variationOrders.cumulativeCertified / totalCosts.variationOrders.budget) * 100) : 0 }}%
              </td>

              <td class="text-right">
                {{ australianCurrency(totalCosts.variationOrders.current_claim - totalCosts.variationOrders.current_certified) }}
              </td>

              <td class="text-right pr-3">
                {{ australianCurrency(Math.abs(totalCosts.variationOrders.budget - totalCosts.variationOrders.current_certified)) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TradeRow from '@/components/ClaimCertifiedCosts/TradeRow.vue';
import VariationOrderRow from '@/components/ClaimCertifiedCosts/VariationOrderRow.vue';
import { useSignedData } from '@/composables/useSignedData';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency, australianNumber, enumKeyFromValue, parseUrlParams, timeZone } from '@/helpers';
import { ClaimStatus, ClaimStatusTitles } from '@/models/Claim';
import TradeItem from '@/models/TradeItem';
import { VariationOrderType } from '@/models/VariationOrder';
import VariationOrderCost from '@/models/VariationOrderCost';
import { useClaimsStore } from '@/store/claims';
import { useTradeItemCostsStore } from '@/store/tradeItemCosts';
import { useTradeItemsStore } from '@/store/tradeItems';
import { useTradesStore } from '@/store/trades';
import { useVariationOrderCostsStore } from '@/store/variationOrderCosts';
import { useVariationOrdersStore } from '@/store/variationOrders';
import { Collection } from 'pinia-orm';
import Swal from 'sweetalert2';
import { computed, onMounted } from 'vue';

const props = defineProps<{
  claimId: string;
}>();

const signedParams = useSignedData();
const tradeItemCostsStore = useTradeItemCostsStore();
const tradeItemsStore = useTradeItemsStore();
const claimsStore = useClaimsStore();
const tradesStore = useTradesStore();
const variationOrdersStore = useVariationOrdersStore();
const variationOrderCostsStore = useVariationOrderCostsStore();

tradeItemCostsStore.models.save(signedParams.models.tradeItemCosts);
tradeItemsStore.models.save(signedParams.models.tradeItems);
claimsStore.models.save(signedParams.models.claim);
tradesStore.models.save(signedParams.models.trades);
variationOrdersStore.models.save(signedParams.models.variationOrders);

const claim = computed(() => {
  return claimsStore.models
    .with('trades', (query) => {
      query.with('trade_items');
    })
    .with('trade_item_costs')
    .with('variation_orders', (query) => {
      query.whereHas('costs', (query) => {
        query.where('claim_id', parseInt(props.claimId));
      });
    })
    .with('variation_order_costs')
    .find(parseInt(props.claimId));
});

const tradeItemIds = computed(() => {
  return (signedParams.models.tradeItems as TradeItem[]).map((tradeItem) => {
    return tradeItem.id;
  });
});

const trades = computed(() => {
  return tradesStore.models.with('trade_items').whereHas('trade_items', (query) => {
    query.whereIn('id', tradeItemIds.value);
  }).with('trade_item_costs', (query) => {
    query.where('claim_id', parseInt(props.claimId));
  }).get();
});

const tradeItems = computed(() => {
  return tradeItemsStore.models.query().whereIn('id', tradeItemIds.value).with('trade_item_costs', (query) => {
    query.where('claim_id', parseInt(props.claimId));
  }).get();
});

const canEditClaim = computed(() => {
  return signedParams.meta.edit_claim &&
    (claim.value.status === ClaimStatus.contractor_claim || claim.value.status === ClaimStatus.draft_contractor);
});

const canEditCertified = computed(() => {
  return signedParams.meta.edit_certified && claim.value.status === ClaimStatus.pqs_review;
});

onMounted(() => {
  const costs = tradeItems.value.map((trade_item) => {
    const cost = trade_item.trade_item_costs[0];

    if(canEditClaim.value) {
      return {
        current_claim: cost ? cost.current_claim : 0,
        id: cost?.id,
        claim_id: parseInt(props.claimId),
        trade_item_id: trade_item.id,
      };
    }

    return {
      current_certified: cost ? cost.current_certified : 0,
      id: cost?.id,
      claim_id: parseInt(props.claimId),
      trade_item_id: trade_item.id,
    };
  });

  tradeItemCostsStore.costsForm.setData({
    costs,
  });
});

const totalCosts = computed(() => {
  const totals = {
    trades: {
      budget: 0,
      current_claim: 0,
      current_certified: 0,
      cumulativeCertified: 0,
      previous: 0,
    },

    variationOrders: {
      budget: 0,
      current_claim: 0,
      current_certified: 0,
      cumulativeCertified: 0,
      previous: 0,
    },
  };

  if(claim.value) {
    trades.value.forEach((trade) => {
      trade.trade_items.forEach((tradeItem) => {
        totals.trades.budget += tradeItem.budget;
        totals.trades.previous += tradeItem.previous(claim.value);
      });
    });

    claim.value.trade_item_costs.forEach((cost) => {
      totals.trades.current_claim += cost.current_claim;
      totals.trades.current_certified += cost.current_certified;
    });

    claim.value.variation_orders?.forEach((variationOrder) => {
      totals.variationOrders.budget += variationOrder.budget;
    });

    claim.value.variation_order_costs?.forEach((variationOrderCost) => {
      totals.variationOrders.current_claim += variationOrderCost.current_claim;
      totals.variationOrders.current_certified += variationOrderCost.current_certified;
    });
  }

  totals.trades.cumulativeCertified = totals.trades.current_certified + totals.trades.previous;

  totals.variationOrders.cumulativeCertified = totals.variationOrders.current_certified +
    totals.variationOrders.previous;

  return totals;
});

const sendContractorAction = useStoreApiAction(claimsStore.sendContractor);

const sendContractorParams = computed(() => {
  return parseUrlParams(signedParams.meta.send_contractor_url as string);
});

const sendContractor = () => {
  Swal.fire({
    icon: 'warning',
    title: 'Send Draft Claim to Contractor',
    text: 'Are you sure you want to send the draft claim?',
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      sendContractorAction.request(claim.value.id, sendContractorParams.value, {
        trade_item_ids: Array.from(tradeItemsStore.selectedTradeItems.values()),
        draft_issued_at_time_zone: timeZone,
      }).then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Claim sent to Contractor',
        });
      });
    }
  });
};

const sendPqsAction = useStoreApiAction(claimsStore.sendPqs);

const sendPqsParams = computed(() => {
  return parseUrlParams(signedParams.meta.send_pqs_url as string);
});

const sendPqs = () => {
  Swal.fire({
    icon: 'warning',
    title: 'Send Claim to PQS Firm',
    text: 'Are you sure you want to send the claim?',
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      sendPqsAction.request(claim.value.id, sendPqsParams.value, {
        claim_issued_at_time_zone: timeZone,
      }).then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Claim sent to PQS',
        });
      }).catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Failed to Send to PQS',
          text: error?.data?.message,
        });
      });
    }
  });
};

const submitAction = useStoreApiAction(claimsStore.submit);

const submitParams = computed(() => {
  return parseUrlParams(signedParams.meta.certify_url as string);
});

const submit = () => {
  Swal.fire({
    icon: 'warning',
    title: 'Certify Claim',
    text: `Are you sure you want to submit the certificate on ${new Date().toLocaleString()}?`,
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      Swal.fire({
        icon: 'warning',
        text: 'Has the stat dec been received for this claim?',
        showConfirmButton: true,
        confirmButtonText: 'Yes',
        showDenyButton: true,
      }).then((result) => {
        if(result.isConfirmed || result.isDenied) {
          submitAction.request(claim.value.id, submitParams.value, {
            stat_dec_received: result.value,
            submitted_at_time_zone: timeZone,
          }).then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Claim certified',
            });
          }).catch(() => {
            Swal.fire({
              icon: 'error',
              title: 'Failed to Certify',
              text: 'Certification failed, please ensure all amounts have been certified.',
            });
          });
        }
      });
    }
  });
};

const variationOrderCosts = computed(() => {
  const costs: Partial<{ [k in keyof typeof VariationOrderType]: Collection<VariationOrderCost> }> = {};

  for(const key in VariationOrderType) {
    costs[key] = variationOrderCostsStore.models.with('variation_order').where('claim_id', claim.value?.id).whereHas(
      'variation_order',
      (query) => {
        query.where('type', VariationOrderType[key]);
      },
    ).get();
  }

  return costs;
});
</script>

<style scoped></style>
