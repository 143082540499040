<template>
  <tr
    class="even:bg-gray-100 odd:bg-white text-right border-black border-x hover:bg-blue-100"
    @click="open = !open"
  >
    <td class="flex pl-3 py-5 items-center space-x-1">
      <button
        :class="[{'rotate-90': open}]"
        data-test="open-claim"
        type="button"
      >
        <Icon
          name="chevron-right"
          class="btn-icon h-5 w-5 group-hover:text-gray-700"
        />
      </button>

      <span>
        {{ title.replace('_', ' ') }}
      </span>
    </td>

    <td class="pr-3">
      {{ australianCurrency(totalCosts.budget) }}
    </td>

    <td
      v-if="claim.capture_claim_amount"
      class="bg-green-200/50"
    >
      {{ australianCurrency(totalCosts.currentClaim) }}
    </td>

    <td class="bg-green-200/50">
      {{ australianCurrency(totalCosts.currentCertified) }}
    </td>

    <td
      v-if="claim.capture_claim_amount"
      class="pr-3 bg-green-200/50"
      :class="{ 'text-red-600': totalCosts.variance !== 0 }"
    >
      {{ australianCurrency(totalCosts.variance) }}
    </td>

    <td class="bg-green-200/50">
      {{ australianCurrency(totalCosts.previous) }}
    </td>

    <td class="bg-green-200/50 pr-3">
      {{ australianCurrency(totalCosts.cumulative) }}
    </td>

    <td
      v-if="claim.capture_claim_amount"
      class="bg-blue-300/50"
    >
      {{ totalCosts.budget > 0 ? australianNumber((totalCosts.currentClaim / totalCosts.budget) * 100) : 0 }}%
    </td>

    <td class="bg-blue-300/50">
      {{ totalCosts.budget > 0 ? australianNumber((totalCosts.currentCertified / totalCosts.budget) * 100) : 0 }}%
    </td>

    <td class="bg-blue-300/50">
      {{ totalCosts.budget > 0 ? australianNumber((totalCosts.previous / totalCosts.budget) * 100) : 0 }}%
    </td>

    <td class="bg-blue-300/50 pr-3">
      {{ totalCosts.budget > 0 ? australianNumber((totalCosts.cumulative / totalCosts.budget) * 100) : 0 }}%
    </td>

    <td class="pr-3">
      {{ australianCurrency(totalCosts.budget - totalCosts.cumulative) }}
    </td>
  </tr>

  <template v-if="open">
    <VariationOrderCostRow
      v-for="variationOrder in variationOrders"
      :key="variationOrder.id"
      :claim="$props.claim"
      :variation-order="variationOrder"
    />

    <tr
      v-if="variationOrders?.length === 0"
      class="border-x border-black"
    >
      <td
        colspan="100%"
        class="text-center p-4"
      >
        No {{ title.replace('_', ' ') }} found for this claim
      </td>
    </tr>
  </template>
</template>

<script setup lang="ts">
import { australianCurrency, australianNumber } from '@/helpers';
import Claim from '@/models/Claim';
import VariationOrder, { VariationOrderType } from '@/models/VariationOrder';
import { computed, ref } from 'vue';
import VariationOrderCostRow from './VariationOrderCostRow.vue';

const props = defineProps<{
  variationOrders?: VariationOrder[];
  claim: Claim;
  title: string;
  variationOrderType: ObjectValues<typeof VariationOrderType>;
}>();

const totalCosts = computed(() => {
  const totals = {
    budget: 0,
    currentClaim: 0,
    currentCertified: 0,
    previous: 0,
    cumulative: 0,
    variance: 0,
  };

  props.variationOrders?.forEach((variationOrder) => {
    totals.budget += variationOrder.totalBudget;
    totals.previous += variationOrder.previous(props.claim);

    const variationOrderCost = variationOrder.costs.find((cost) => {
      return cost.claim_id === props.claim.id;
    });

    if(variationOrderCost) {
      totals.currentClaim += variationOrderCost.current_claim;
      totals.currentCertified += variationOrderCost.current_certified;
      totals.variance += variationOrderCost.current_claim - variationOrderCost.current_certified;
    }
  });

  totals.cumulative = totals.previous + totals.currentCertified;

  return totals;
});

const open = ref(false);

defineOptions({
  inheritAttrs: false,
});
</script>

<style scoped></style>
