<template>
  <div class="!pt-0">
    <div class="flex flex-col max-h-screen pt-16 pb-2.5 min-h-screen space-y-2.5">
      <div class="element-island m-auto mt-2.5 p-4">
        <h1 class="font-semibold text-3xl">
          Variation Pricing
        </h1>

        <div
          v-if="variationOrder"
          class="flex flex-col space-y-4 mt-4 3xl:text-xl"
        >
          <div
            v-if="variationOrder.status === VariationOrderStatus.provisioned"
            class="p-4 rounded-lg mx-auto"
            :style="{ backgroundColor: VariationOrderStatusColours.approved }"
          >
            Approved
          </div>


          <div>
            <span class="font-semibold">
              Variation Details
            </span>

            <p>
              {{ variationOrder.description }}
            </p>
          </div>

          <div class="flex justify-between font-semibold">
            <span>
              Total Cost
            </span>

            <span>
              {{ australianCurrency(variationOrder.totalBudget) }}
            </span>
          </div>

          <div>
            <span class="font-semibold">
              Cost Breakdown
            </span>

            <div
              v-for="tradeItem in variationOrder.trade_items"
              :key="tradeItem.id"
              class="flex justify-between"
            >
              <span>
                {{ tradeItem.name }}
              </span>

              <span>
                {{ australianCurrency(tradeItem.budget) }}
              </span>
            </div>
          </div>


          <button
            type="button"
            class="btn btn--primary-blue w-full disabled:cursor-not-allowed"
            :disabled="variationOrder.status === VariationOrderStatus.provisioned"
            @click="provisionVariationOrder"
          >
            Approve Variation Pricing
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSignedData } from '@/composables/useSignedData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency, parseUrlParams } from '@/helpers';
import { VariationOrderStatus, VariationOrderStatusColours } from '@/models/VariationOrder';
import { useVariationOrdersStore } from '@/store/variationOrders';
import Swal from 'sweetalert2';
import { computed } from 'vue';

const props = defineProps<{
  variationId: string;
}>();

const signedParams = useSignedData();
const variationOrdersStore = useVariationOrdersStore();

variationOrdersStore.models.save(signedParams.models.variation_order);

const variationOrder = computed(() => {
  return variationOrdersStore.models.with('trade_items').find(parseInt(props.variationId));
});

const provisionVariationOrderAction = useStoreApiAction(variationOrdersStore.provisionVariationOrder);

const provisionVariationOrderParams = computed(() => {
  return parseUrlParams(signedParams.meta.provision_variation_url as string);
});

const provisionVariationOrder = () => {
  Swal.fire({
    icon: 'question',
    title: 'Approve Pricing?',
    text: 'Are you sure you want to approve the pricing for this variation?',
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      provisionVariationOrderAction.request(props.variationId, provisionVariationOrderParams.value).then(
        () => {
          Swal.fire({
            icon: 'success',
            title: 'Variation Pricing Approved',
            text: 'You may close this window',
          });
        },
      ).catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Failed to Approve Pricing.',
          text: error?.data?.message,
        });
      });
    }
  });
};
</script>

<style scoped></style>
