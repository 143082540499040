import { route } from '@/ziggy-shim';
import { api } from './LaravelClient';

export default {
  async index(projectId: Id, data: ApiRequests['api.v1.trades.index']) {
    return await api(route('api.v1.trades.index', projectId), 'get', {
      data,
    });
  },

  async create(projectId: Id, data: ApiRequests['api.v1.trades.store']) {
    return await api(route('api.v1.trades.store', projectId), 'post', {
      data,
    });
  },

  async update(id: Id, data: ApiRequests['api.v1.trades.update']) {
    return await api(route('api.v1.trades.update', id), 'patch', {
      data,
    });
  },

  async updateBaselineForecast(id: Id, data: ApiRequests['api.v1.trades.baseline-forecast']) {
    return await api(route('api.v1.trades.baseline-forecast', id), 'patch', {
      data,
    });
  },

  async updateForecast(id: Id, data: ApiRequests['api.v1.trades.forecast']) {
    return await api(route('api.v1.trades.forecast', id), 'patch', {
      data,
    });
  },

  async updateSort(id: Id, data: ApiRequests['api.v1.trades.sort']) {
    return await api(route('api.v1.trades.sort', id), 'patch', {
      data,
    });
  },

  async delete(id: Id) {
    return await api(route('api.v1.trades.destroy', id), 'delete');
  },

  async seed(projectId: Id) {
    return await api(route('api.v1.trades.seed', projectId), 'post');
  },
};
