<template>
  <div class="lg:!mx-0 flex">
    <div class="flex flex-col max-h-content px-6 pb-2.5 min-h-content space-y-2.5 w-screen">
      <div class="flex items-stretch justify-between mt-2.5">
        <div class="flex space-x-2 element-island">
          <h1 class="font-semibold text-3xl">
            Cost Aggregation
          </h1>
        </div>
      </div>

      <div class="flex flex-col border border-gray-300 p-5 element-island w-full overflow-y-auto flex-1">
        <div
          class="w-full flex space-x-4 relative"
        >
          <table class="w-1/2 h-fit">
            <thead>
              <tr class="text-left first-child:pl-0 child:px-3 child:py-2">
                <th>
                  Section
                </th>

                <th class="text-right">
                  Tender
                </th>

                <th class="text-right">
                  To Date
                </th>

                <th class="text-right">
                  Final
                </th>
              </tr>
            </thead>

            <tbody>
              <tr class="first-child:pl-0 child:px-3 child:py-2 child:border-gray-200 child:border-b">
                <td class="font-semibold">
                  Trade Costs
                </td>

                <td class="text-right">
                  {{ australianCurrency(costAggregationSummary.total_budget) }}
                </td>

                <td class="text-right">
                  {{ australianCurrency(costAggregationSummary.total_certified) }}
                </td>

                <td class="text-right">
                  {{ australianCurrency(costAggregationSummary.total_budget + costAggregationSummary.variations.approved_variations_total) }}
                </td>
              </tr>

              <tr
                class="first-child:pl-0 child:px-3 child:py-2 child:border-gray-200 child:border-b cursor-pointer"
                @click="openCosts = !openCosts"
              >
                <td class="font-semibold">
                  <div class="flex items-center">
                    <button
                      :class="[{'rotate-90': openCosts}]"
                      type="button"
                    >
                      <Icon
                        name="chevron-right"
                        class="btn-icon h-5 w-5 group-hover:text-gray-700"
                      />
                    </button>

                    <p>
                      Indirect Costs
                    </p>
                  </div>
                </td>

                <td class="text-right">
                  {{ australianCurrency(costAggregationSummary.total_indirect_cost) }}
                </td>

                <td class="text-right">
                  {{ australianCurrency(costAggregationSummary.total_indirect_certified) }}
                </td>

                <td />
              </tr>

              <template v-if="openCosts">
                <tr
                  v-for="trade in indirectTrades"
                  :key="trade.id"
                  class="child:px-3 child:py-2 child:border-gray-200 child:border-b"
                >
                  <td class="font-semibold">
                    {{ trade.name }}
                  </td>

                  <td class="text-right">
                    {{ australianCurrency(trade.totalBudget) }}
                  </td>

                  <td class="text-right">
                    {{ australianCurrency(trade.totalCertified) }}
                  </td>

                  <td />
                </tr>
              </template>

              <tr class="first-child:pl-0 child:px-3 child:py-2 child:border-gray-200 child:border-b">
                <td class="font-semibold">
                  Total
                </td>

                <td class="text-right">
                  {{ australianCurrency(costAggregationSummary.total_indirect_cost + costAggregationSummary.total_budget) }}
                </td>

                <td class="text-right">
                  {{ australianCurrency(costAggregationSummary.total_indirect_certified + costAggregationSummary.total_certified) }}
                </td>

                <td />
              </tr>
            </tbody>
          </table>

          <div class="w-1/2">
            <div class="bg-ccm-blue-100 rounded-t-md text-white p-2 font-semibold text-base">
              Variations Synopsis
            </div>

            <table class="w-full">
              <thead class="text-base child:border-b child:border-x border-gray-600 font-semibold">
                <tr class="child:px-5 child:py-2 text-left">
                  <th>Status</th>

                  <th class="text-right">
                    Cost
                  </th>
                </tr>
              </thead>

              <tbody class="child:border-b child:border-x border-gray-600 font-semibold">
                <tr class="child:px-5 child:py-2">
                  <td>
                    Priced
                  </td>

                  <td class="text-right">
                    {{ australianCurrency(costAggregationSummary.variations.priced) }}
                  </td>
                </tr>

                <tr class="child:px-5 child:py-2">
                  <td>
                    Submitted
                  </td>

                  <td class="text-right">
                    {{ australianCurrency(costAggregationSummary.variations.submitted) }}
                  </td>
                </tr>

                <tr class="child:px-5 child:py-2">
                  <td>
                    Provisioned
                  </td>

                  <td class="text-right">
                    {{ australianCurrency(costAggregationSummary.variations.provisioned) }}
                  </td>
                </tr>

                <tr class="child:px-5 child:py-2">
                  <td>
                    Approved
                  </td>

                  <td class="text-right">
                    {{ australianCurrency(costAggregationSummary.variations.approved) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="flex text-sm 3xl:text-base space-x-4 mt-auto">
          <div class="w-1/2 flex flex-col items-center justify-start">
            <h3 class="text-center font-bold text-2xl">
              Project Works
            </h3>

            <div class="w-full my-auto relative h-52 md-height:h-80">
              <canvas
                ref="pieChart"
              />
            </div>
          </div>

          <div class="w-1/2">
            <div class="bg-ccm-blue-100 rounded-t-md text-white p-2 font-semibold text-base">
              Contract Synopsis
            </div>

            <table
              v-if="project"
              class="w-full border border-gray-300 shadow-sm"
            >
              <tbody class="child:border-b border-gray-600 font-semibold last-child:border-none">
                <tr class="first-child:pl-4 child:p-2">
                  <td>
                    Contract Value
                  </td>

                  <td class="text-right">
                    {{ australianCurrency(totalBudget) }}
                  </td>
                </tr>

                <tr class="first-child:pl-4 child:p-2">
                  <td>
                    Approved Variations Value
                  </td>

                  <td class="text-right">
                    {{ australianCurrency(variationOrdersTotalValue) }}
                  </td>
                </tr>

                <tr class="first-child:pl-4 child:p-2">
                  <td>
                    Approved Contract Value
                  </td>

                  <td class="text-right">
                    {{ australianCurrency(totalBudget + variationOrdersTotalValue) }}
                  </td>
                </tr>

                <tr class="first-child:pl-4 child:p-2">
                  <td>
                    Unapproved Variations Value
                  </td>

                  <td class="text-right">
                    {{ australianCurrency(costAggregationSummary.variations.unapproved_variations_total) }}
                  </td>
                </tr>

                <tr class="first-child:pl-4 child:p-2">
                  <td>
                    Estimated Final Contract Value
                  </td>

                  <td class="text-right">
                    {{ australianCurrency(totalBudget + costAggregationSummary.variations.unapproved_variations_total + costAggregationSummary.variations.approved_variations_total) }}
                  </td>
                </tr>

                <tr class="first-child:pl-4 child:p-2">
                  <td>
                    Payments Certified
                  </td>

                  <td class="text-right">
                    {{ australianCurrency(claimCertifiedTotal) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency } from '@/helpers';
import { useProjectsStore } from '@/store/projects';
import { useTradesStore } from '@/store/trades';
import { Chart } from 'chart.js';
import { computed, onMounted, ref, useTemplateRef, watch } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps<{
  projectId: string;
}>();

const projectsStore = useProjectsStore();
const tradesStore = useTradesStore();

const project = computed(() => {
  return projectsStore.project;
});

const indirectTrades = computed(() => {
  return tradesStore.models
    .with('trade_items')
    .where('boq_type', 'indirect')
    .where('project_id', parseInt(props.projectId))
    .orderBy('sort_order')
    .get();
});

const costAggregationSummary = ref<CostAggregationSummary>({
  total_budget: 0,
  total_certified: 0,
  total_indirect_certified: 0,
  total_indirect_cost: 0,
  variations: {
    approved_variations_total: 0,
    unapproved_variations_total: 0,
    priced: 0,
    submitted: 0,
    provisioned: 0,
    approved: 0,
    total_variations: 0,
    total_budget: 0,
  },
});

const variationOrdersTotalValue = computed(() => {
  return costAggregationSummary.value.variations.approved_variations_total;
});

const claimCertifiedTotal = computed(() => {
  return costAggregationSummary.value.total_certified;
});

const costAggregationSummaryAction = useStoreApiAction(projectsStore.costAggregationSummary);
const fetchTradesAction = useStoreApiAction(tradesStore.fetchTrades);
const pieChart = useTemplateRef('pieChart');
let workDoneChart: Chart<'bar'> | undefined;

const totalBudget = computed(() => {
  return costAggregationSummary.value.total_budget;
});

const router = useRouter();

watch(project, (newValue) => {
  if(newValue && !newValue.boq_locked) {
    router.push({ name: 'BillOfQuantities', params: { projectId: newValue.id } });
  }
}, { immediate: true });

watch(() => props.projectId, () => {
  costAggregationSummaryAction.request(props.projectId).then((costAggregation) => {
    costAggregationSummary.value = costAggregation;
  }).catch((error) => {
    console.log(error);
  });

  fetchTradesAction.request(props.projectId, { boq_type: 'indirect' });
}, { immediate: true });

watch(costAggregationSummary, (newValue) => {
  workDoneChart.data.datasets[0] = {
    data: [
      newValue.total_budget,
      newValue.total_certified,
      newValue.total_indirect_cost,
      newValue.variations.approved_variations_total,
    ],

    backgroundColor: [
      '#A6ACEC',
      '#4E95D9',
      '#215F9A',
      '#163E64',
      '#0E2841',
    ],
  };

  workDoneChart.data.labels = [
    `Total Work ${australianCurrency(newValue.total_budget)}`,
    `Work Done ${australianCurrency(newValue.total_certified)}`,
    `Indirect Cost ${australianCurrency(newValue.total_indirect_cost)}`,
    `Approved Variations ${australianCurrency(newValue.variations.approved_variations_total)}`,
  ];

  workDoneChart.update();
});

onMounted(() => {
  const pieChartCtx = pieChart.value.getContext('2d');

  const data = {
    labels: [
      `Total Work ${australianCurrency(costAggregationSummary.value.total_budget)}`,
      `Work Done ${australianCurrency(claimCertifiedTotal.value)}`,
      `Indirect Cost ${australianCurrency(costAggregationSummary.value.total_indirect_cost)}`,
      `Approved Variations ${australianCurrency(costAggregationSummary.value.variations.approved_variations_total)}`,
    ],

    datasets: [{
      label: 'Contract Works',

      data: [
        costAggregationSummary.value.total_budget,
        claimCertifiedTotal.value,
        costAggregationSummary.value.total_indirect_cost,
        costAggregationSummary.value.variations.approved_variations_total,
      ],

      backgroundColor: [
        '#A6ACEC',
        '#4E95D9',
        '#215F9A',
        '#163E64',
        '#0E2841',
      ],
    }],
  };

  workDoneChart = new Chart(pieChartCtx, {
    type: 'bar',
    data,
    options: {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
      },

      // title: {
      //   display: false,
      // },

      //   tooltip: {
      //     callbacks: {
      //       // label(this, tooltipItem) {
      //       //   return (tooltipItem.dataset.data[tooltipItem.dataIndex] / totalBudget.value * 100).toFixed(2) + '%';
      //       // },
      //     },
      //   },
      // },

      maintainAspectRatio: false,
    },
  });
});

const openCosts = ref(false);
</script>

<style scoped></style>
