<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl">
          {{ $props.projectSupplier ? 'Update' : 'Add' }} Supplier
        </h1>
      </div>
    </template>

    <template #body>
      <div class="max-h-[min(45rem,70vh)] md-height:max-h-[min(45rem,90vh)] overflow-y-auto h-full w-full p-4">
        <form
          action=""
          class="space-y-4"
          @submit.prevent="saveOrder"
        >
          <label class="flex items-center justify-between space-x-2">
            <p>
              Supplier
            </p>

            <AppTypeAhead
              v-model="supplierForm.form.supplier_name"
              name="supplier_name"
              :suggestion-callback="getSuggestions"
              :initial-suggestions="getSuggestions"
              data-test="supplier-name"
              :error="supplierForm.getErrors('supplier_id') || supplierForm.getErrors('supplier_name')"
              :disabled="$props.projectSupplier"
              class="disabled:bg-gray-300"
              @value-selected="selectSupplier"
            />
          </label>

          <label class="flex items-center justify-between space-x-2">
            <p>
              Type
            </p>

            <AppSelect
              v-model="supplierForm.form.type"
              name="type"
              class="capitalize"
              :error="supplierForm.getErrors('type')"
              :options="supplierTypes"
            />
          </label>

          <label class="flex items-center justify-between space-x-2">
            <p>
              Contact Email
            </p>

            <AppInput
              v-model="supplierForm.form.contact_email"
              name="contact_email"
              type="text"
              :error="supplierForm.getErrors('contact_email')"
              data-test="contact_email"
            />
          </label>

          <label class="flex items-center justify-between space-x-2">
            <p>
              Contact Name
            </p>

            <AppInput
              v-model="supplierForm.form.contact_name"
              name="contact_name"
              type="text"
              :error="supplierForm.getErrors('contact_name')"
              data-test="contact_name"
            />
          </label>

          <label class="flex items-center justify-between space-x-2">
            <p>
              Address
            </p>

            <AppInput
              v-model="supplierForm.form.address"
              name="address"
              type="text"
              :error="supplierForm.getErrors('address')"
              data-test="address"
            />
          </label>

          <label class="flex items-center justify-between space-x-2">
            <p>
              Contact Phone Number
            </p>

            <AppInput
              v-model="supplierForm.form.contact_number"
              name="contact_number"
              type="text"
              :error="supplierForm.getErrors('contact_number')"
              data-test="contact_number"
            />
          </label>

          <label class="flex items-center justify-between space-x-2">
            <p>
              Saturday Multiplier
            </p>

            <AppInput
              v-model.number="supplierForm.form.saturday_multiplier"
              name="saturday_multiplier"
              type="text"
              :error="supplierForm.getErrors('saturday_multiplier')"
              data-test="saturday_multiplier"
            />
          </label>

          <label class="flex items-center justify-between space-x-2">
            <p>
              Sunday Multiplier
            </p>

            <AppInput
              v-model.number="supplierForm.form.sunday_multiplier"
              name="sunday_multiplier"
              type="text"
              :error="supplierForm.getErrors('sunday_multiplier')"
              data-test="sunday_multiplier"
            />
          </label>

          <label class="flex items-center justify-between space-x-2">
            <p>
              Public Holiday Multiplier
            </p>

            <AppInput
              v-model.number="supplierForm.form.public_holiday_multiplier"
              name="public_holiday_multiplier"
              type="text"
              :error="supplierForm.getErrors('public_holiday_multiplier')"
              data-test="public_holiday_multiplier"
            />
          </label>

          <div class="flex flex-col items-start space-y-4">
            <div>
              <legend>
                Overtime Thresholds
              </legend>

              <div class="flex items-end space-x-4">
                <label>
                  Hour Threshold

                  <AppInput
                    v-model.number="supplierForm.form.threshold"
                    name="activity"
                    type="text"
                    :error="supplierForm.getErrors('threshold')"
                    @keydown.enter.prevent="addThreshold"
                  />
                </label>

                <label>
                  Multiplier

                  <AppInput
                    v-model.number="supplierForm.form.multiplier"
                    name="activity"
                    type="text"
                    @keydown.enter.prevent="addThreshold"
                  />
                </label>


                <button
                  type="button"
                  class="btn btn--secondary-blue h-min"
                  @click.prevent="addThreshold"
                >
                  Add Threshold
                </button>
              </div>
            </div>

            <ul class="flex flex-col space-y-2">
              <li
                v-for="(overtime, i) in supplierForm.form.overtime"
                :key="i"
                class="w-full flex card__list-item"
              >
                <p>
                  Above {{ overtime.threshold }} hours, rate is multiplied by {{ overtime.multiplier }}
                </p>

                <button type="button">
                  <Icon
                    name="trash"
                    class=" w-6 h-6"
                    @click="removeThreshold(i)"
                  />
                </button>
              </li>
            </ul>
          </div>
        </form>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-between p-2 space-x-4">
        <button
          v-if="$props.projectSupplier"
          type="button"
          class="btn btn--tertiary-blue"
          @click="deleteSupplier"
        >
          Delete
        </button>

        <div class="space-x-2 ml-auto">
          <button
            type="button"
            class="btn btn--secondary-blue"
            @click="$emit('close'); supplierForm.resetData()"
          >
            Cancel
          </button>

          <button
            type="button"
            class="btn btn--primary-blue"
            data-test="save-order"
            @click="saveOrder"
          >
            {{ $props.projectSupplier ? 'Update' : 'Create' }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import Modal from '@/components/Modal.vue';
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import ProjectSupplier, { SupplierType } from '@/models/ProjectSupplier';
import { useProjectSupplierStore } from '@/store/projectSupplier';
import { useSuppliersStore } from '@/store/supplier';
import Swal from 'sweetalert2';
import { computed, nextTick, ref, watch } from 'vue';

const props = defineProps<{
  projectSupplier?: ProjectSupplier;
  projectId: Id;
}>();

const emits = defineEmits<{
  (e: 'close');
}>();

const projectSuppliersStore = useProjectSupplierStore();

const supplierForm = useFormData({
  supplier_name: '',
  threshold: 0,
  multiplier: 0,
  overtime: [] as ({ threshold: number; multiplier: number })[],
  saturday_multiplier: 1,
  sunday_multiplier: 1,
  public_holiday_multiplier: 1,
  supplier_id: undefined,
  type: 0,
  contact_email: '',
  address: '',
  contact_number: '',
  contact_name: '',
});

watch(() => props.projectSupplier, (newValue) => {
  if(newValue) {
    supplierForm.form.supplier_name = newValue.supplier.name;
    // supplierForm.form.overtime = props.projectSupplier.overtime || [];
    // supplierForm.form.saturday_multiplier = props.projectSupplier.saturday_multiplier;
    // supplierForm.form.sunday_multiplier = props.projectSupplier.sunday_multiplier;
    // supplierForm.form.public_holiday_multiplier = props.projectSupplier.public_holiday_multiplier;
    supplierForm.setData(newValue, ['supplier_name', 'supplier_id', 'multiplier', 'threshold']);
  }
}, { immediate: true });

const updateSupplierAction = useStoreApiAction(projectSuppliersStore.updateProjectSupplier);
const createSupplierAction = useStoreApiAction(projectSuppliersStore.createProjectSupplier);

const saveOrder = () => {
  supplierForm.resetErrors();

  if(props.projectSupplier) {
    updateSupplierAction.request(props.projectSupplier.id, supplierForm.form).then(() => {
      Swal.fire({
        icon: 'success',
        text: 'Supplier updated',
      }).then(() => {
        emits('close');
        supplierForm.resetData();
      });
    }).catch((error) => {
      supplierForm.setErrors(error.data);
    });
  } else {
    createSupplierAction.request(props.projectId, supplierForm.form).then(() => {
      Swal.fire({
        icon: 'success',
        text: 'Supplier created',
      }).then(() => {
        emits('close');
        supplierForm.resetData();
      });
    }).catch((error) => {
      supplierForm.setErrors(error.data);
    });
  }
};

const deleteSupplier = () => {
  Swal.fire({
    icon: 'warning',
    title: 'Remove Supplier?',
    text: 'This will remove the association with this supplier',
    showConfirmButton: true,
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      projectSuppliersStore.deleteProjectSupplier(props.projectSupplier.id).then(() => {
        emits('close');
      }).catch(() => {
        Swal.fire({
          icon: 'error',
          title: 'Failed to delete',
        });
      });
    }
  });
};

const addThreshold = () => {
  if(supplierForm.form.multiplier && supplierForm.form.threshold) {
    supplierForm.form.overtime.push({
      multiplier: supplierForm.form.multiplier,
      threshold: supplierForm.form.threshold,
    });

    supplierForm.form.multiplier = undefined;
    supplierForm.form.threshold = undefined;
  }
};

const removeThreshold = (index: number) => {
  supplierForm.form.overtime.splice(index, 1);
};

const suppliersStore = useSuppliersStore();
const suggestedSuppliers = ref<{ id: number; name: string }[]>([]);

const getSuggestions = async () => {
  suggestedSuppliers.value = await suppliersStore
    .search({ name: supplierForm.form.supplier_name, project_id: parseInt(`${props.projectId}`) });

  return suggestedSuppliers.value.map((value) => value.name);
};

const selectSupplier = (index: number) => {
  nextTick(() => {
    supplierForm.form.supplier_id = suggestedSuppliers.value[index].id;
  });
};

watch(() => supplierForm.form.supplier_name, (newValue, oldValue) => {
  if(
    newValue !== oldValue &&
    supplierForm.form.supplier_id !== undefined
  ) {
    supplierForm.form.supplier_id = undefined;
  }
});

const supplierTypes = computed(() => {
  const types = [];

  for(const type in SupplierType) {
    const value = SupplierType[type];

    types.push({ value, label: type.replace('_', ' ') });
  }

  return types;
});
</script>

<style scoped></style>
