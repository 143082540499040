<template>
  <div class="w-[90%] mx-auto flex items-center pb-16">
    <div class="bg-white p-2 mt-8 rounded-lg shadow-lg border border-gray-300 w-full">
      <div class="my-5 flex justify-center">
        <h1 class="text-3xl font-semibold justify-center">
          Variation Claims
        </h1>

        <RouterLink :to="{ name: 'MobileVariationsCreate' }">
          new claim
        </RouterLink>
      </div>

      <div class="items-center flex flex-col px-3">
        <div>
          Claim No.
        </div>

        <div
          v-for="variationOrder in variationOrders"
          :key="variationOrder.id"
        >
          <div>
            {{ variationOrder.id }}

            {{ variationOrder.code }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useVariationOrdersStore } from '@/store/variationOrders';
import { computed } from 'vue';

const props = defineProps<{
  projectId: string;
}>();

const variationOrdersStore = useVariationOrdersStore();

variationOrdersStore.fetchVariationOrders(props.projectId);

const variationOrders = computed(() => {
  return variationOrdersStore.models.where('project_id', parseInt(props.projectId)).get();
});
</script>

<style scoped></style>
