<template>
  <div class="!pt-0">
    <div class="flex flex-col max-h-screen pt-16 pb-2.5 min-h-screen space-y-2.5">
      <div class="element-island m-auto mt-2.5 p-4">
        <h1 class="font-semibold text-3xl">
          Delay Days
        </h1>

        <div
          v-if="delay"
          class="flex flex-col space-y-4 mt-4 3xl:text-xl"
        >
          <div
            v-if="delay.status === DelayStatus.provisioned"
            class="p-4 rounded-lg mx-auto"
            :style="{ backgroundColor: DelayStatusColours.approved }"
          >
            Approved
          </div>

          <div>
            <span class="font-semibold">
              Delay Details
            </span>

            <p>
              {{ delay.description }}
            </p>
          </div>

          <div class="flex justify-between font-semibold">
            <span>
              Delay Duration
            </span>

            <span>
              {{ delay.days }}
            </span>
          </div>

          <button
            type="button"
            class="btn btn--primary-blue w-full disabled:cursor-not-allowed"
            :disabled="delay.status === DelayStatus.provisioned"
            @click="provisionDelay"
          >
            Approve Delay
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSignedData } from '@/composables/useSignedData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { parseUrlParams } from '@/helpers';
import { DelayStatus, DelayStatusColours } from '@/models/Delay';
import { useDelaysStore } from '@/store/delays';
import Swal from 'sweetalert2';
import { computed } from 'vue';

const props = defineProps<{
  delayId: string;
}>();

const signedParams = useSignedData();
const delaysStore = useDelaysStore();

delaysStore.models.save(signedParams.models.delay);

const delay = computed(() => {
  return delaysStore.models.find(parseInt(props.delayId));
});

const provisionDelayAction = useStoreApiAction(delaysStore.provisionDelay);

const provisionDelayParams = computed(() => {
  return parseUrlParams(signedParams.meta.provision_delay_url as string);
});

const provisionDelay = () => {
  Swal.fire({
    icon: 'question',
    title: 'Approve Delay?',
    text: 'Are you sure you want to approve this delay?',
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      provisionDelayAction.request(props.delayId, provisionDelayParams.value).then(
        () => {
          Swal.fire({
            icon: 'success',
            title: 'Delay Approved',
            text: 'You may close this window',
          });
        },
      ).catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Failed to Approve Delay.',
          text: error?.data?.message,
        });
      });
    }
  });
};
</script>

<style scoped></style>
