<template>
  <div
    class="w-1/3 bg-white min-h-content max-h-content mt-16 2xl:mt-0 fixed 2xl:sticky inset-y-0 p-5 flex-shrink-0 lg:w-1/4 xl:w-1/5 2xl:max-w-[15%] z-30"
    v-bind="$attrs"
  >
    <div class="h-full flex flex-col justify-between">
      <div class="relative">
        <p class="text-center font-semibold text-xl">
          Projects
        </p>

        <div
          v-if="open"
          class="absolute right-0 top-0 hover:bg-gray-300 cursor-pointer p-1 rounded 2xl:hidden bg-gray-100"
          @click="$emit('close')"
        >
          <Icon
            name="chevron-left"
            class="w-5"
          />
        </div>

        <div
          v-else
          class="absolute -right-10 top-0 hover:bg-gray-300 cursor-pointer p-1 rounded 2xl:hidden bg-gray-100"
          @click="$emit('open')"
        >
          <Icon
            name="chevron-right"
            class="w-5"
          />
        </div>

        <hr class="border border-black mt-3">
      </div>

      <div class="overflow-y-auto space-y-3 py-3">
        <RouterLink
          v-for="project in projects"
          :key="project.id"
          :to="{ name: 'ProjectSelector', params: { projectId: project.id } }"
          class="block"
        >
          <button
            type="button"
            class="btn w-full"
            :class="[projectsStore.project?.id === project.id ? 'btn--primary-blue' : 'btn--secondary-blue']"
          >
            {{ project.name }}
          </button>
        </RouterLink>
      </div>


      <div class="flex-shrink-0">
        <hr class="border border-black mb-3">

        <button
          v-if="userStore.isRole(['company_admin']) && projectsStore.project?.company_id && companyProjects.length < selectedCompany?.max_projects"
          class="btn btn--secondary-blue w-full"
          type="button"
          @click="show = true"
        >
          Add Project
        </button>
      </div>
    </div>
  </div>

  <Modal
    v-if="show"
    @close="show = false"
  >
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl">
          Create Company Project
        </h1>
      </div>
    </template>

    <template #body>
      <div class="h-full w-full p-2 min-w-[20rem]">
        <form @submit.prevent="createProject">
          <label>
            Name
            <AppInput
              v-model="projectForm.form.name"
              type="text"
              name="name"
              :error="projectForm.getErrors('name')"
            />
          </label>

          <label v-if="companyProjects.length > 1">
            Copy user roles from project
            <AppSelect
              v-model="projectForm.form.clone_project_id"
              name="clone_project_id"
              :options="companyProjects.map((project) => {
                return {
                  label: project.name,
                  value: project.id
                }
              })"
            />
          </label>

          <label v-if="availableCompanies.length > 1">
            Company
            <AppSelect
              v-model="projectForm.form.company_id"
              name="company_id"
              :options="availableCompanies.map((permission) => {
                return {
                  label: permission.company.name,
                  value: permission.company.id
                }
              })"
            />
          </label>
        </form>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-center p-2 space-x-4">
        <button
          type="button"
          class="btn btn--secondary-blue"
          @click="show = false"
        >
          Cancel
        </button>

        <button
          type="button"
          class="btn btn--primary-blue"
          @click="createProject"
        >
          Create
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import Permissions from '@/models/Permissions';
import User from '@/models/User';
import { useCompaniesStore } from '@/store/companies';
import { useProjectsStore } from '@/store/projects';
import { useUserStore } from '@/store/user';
import { useRepo } from 'pinia-orm';
import Swal from 'sweetalert2';
import { computed, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import Modal from '../Modal.vue';

defineProps<{
  open: boolean;
}>();

const projectsStore = useProjectsStore();
const companiesStore = useCompaniesStore();

const projects = computed(() => {
  return projectsStore.models.all();
});

const emits = defineEmits<{
  (e: 'close');
  (e: 'open');
}>();

const show = ref(false);

const projectForm = useFormData({
  name: '',
  company_id: undefined,
  clone_project_id: undefined,
});

const createProjectAction = useStoreApiAction(projectsStore.createCompanyProject);
const router = useRouter();

const createProject = () => {
  projectForm.resetErrors();

  createProjectAction.request(projectForm.form.company_id, projectForm.form).then((project) => {
    router.push({ name: 'ProjectSelector', params: { projectId: project.id } });
    show.value = false;
    projectForm.resetData();
  }).catch((error) => {
    if(error?.status === 403) {
      Swal.fire({
        icon: 'error',
        text: 'You have reached the maximum number of projects and cannot create any more',
      });
    } else {
      projectForm.setErrors(error.data);
    }
  });
};

const userStore = useUserStore();

const availableCompanies = computed(() => {
  return useRepo(Permissions)
    .query()
    .where('user_id', (userStore.user as User)?.id)
    .where('company_id', (company_id: Id) => !!company_id)
    .with('company')
    .get();
});

watch([availableCompanies, show], (newValue) => {
  if(newValue[0]?.length === 1) {
    projectForm.form.company_id = newValue[0][0].company_id;
  }
}, { immediate: true });

const companyProjects = computed(() => {
  return projects.value.filter((project) => {
    return project.company_id === projectForm.form.company_id;
  });
});

const selectedCompany = computed(() => {
  return companiesStore.models.find(projectForm.form.company_id);
});

watch(() => projectForm.form.company_id, (newValue, oldValue) => {
  if(newValue !== oldValue) {
    projectForm.resetData(['clone_project_id']);
  }
});
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<style scoped></style>
