<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl">
          New Delay
        </h1>
      </div>
    </template>

    <template #body>
      <div class="h-full w-full p-4">
        <form
          class="flex flex-col space-y-4"
          @submit.prevent="saveDelay"
        >
          <div>
            Date {{ new Date().toLocaleDateString() }}
          </div>

          <div>
            Description
            <textarea
              v-model="delayForm.form.description"
              name="description"
              class="form-input resize-none"
            />
          </div>

          <div>
            Notes
            <textarea
              v-model="delayForm.form.notes"
              name="notes"
              class="form-input resize-none"
            />
          </div>
        </form>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-end p-2 space-x-4">
        <button
          type="button"
          class="btn btn--secondary-blue"
          @click="$emit('close')"
        >
          Cancel
        </button>

        <button
          type="button"
          class="btn btn--primary-blue"
          data-test="apply-filter"
          @click="saveDelay"
        >
          Create
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import Modal from '@/components/Modal.vue';
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { useDelaysStore } from '@/store/delays';

const props = defineProps<{
  projectId: string;
  delayId?: string;
}>();

const emits = defineEmits<{
  (e: 'close');
}>();

const delaysStore = useDelaysStore();

// variationOrdersStore.fetchVariationOrders(props.projectId);

const createDelayAction = useStoreApiAction(delaysStore.createDelay);

const delayForm = useFormData({
  description: '',
  notes: '',
});

const saveDelay = () => {
  createDelayAction.request(props.projectId, delayForm.form).then((delay) => {
    // router.push({ name: 'MobileVariationsIndex' });
    console.log('close');

    emits('close');
  }).catch((error) => {
    delayForm.setErrors(error.data);
  });
};
</script>

<style scoped></style>
