<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl">
          Pricing for {{ variationOrder.code }}
        </h1>
      </div>
    </template>

    <template #body>
      <div class="min-w-md flex flex-col p-4 max-h-[30rem] overflow-y-auto w-full max-w-5xl">
        <div class="flex justify-between space-x-4 items-center">
          <p class="w-60 text-wrap">
            {{ variationOrder.description }}
          </p>

          <button
            v-if="project.type !== 'client'"
            class="btn btn--secondary-blue"
            type="button"
            @click="togglePricing"
          >
            Price by {{ totalSumSelected ? 'Bill of Quantities' : 'Sum' }}
          </button>
        </div>

        <div
          v-if="variationOrder.notes"
          class="mt-2"
        >
          {{ variationOrder.notes }}
        </div>

        <form
          action=""
          class="flex flex-col space-y-4 mt-4"
          @submit.prevent=""
        >
          <div
            v-if="!falsey(pricingForm.form.trade_items)"
            class="flex flex-col overflow-auto"
          >
            <div class="flex space-x-4 mt-4">
              <div class="flex flex-col space-y-2 w-1/2">
                <div class="text-lg font-semibold">
                  {{ !falsey(selectedTradeItem) ? `Edit ${pricingForm.form.trade_items[selectedTradeItem].name}` : 'Trade Item Details' }}
                </div>

                <label>
                  Name
                  <AppInput
                    v-model="tradeItemForm.form.name"
                    name="name"
                    type="text"
                    class="form-input"
                    :error="tradeItemForm.getErrors('name')"
                    data-test="name-input"
                  />
                </label>

                <div class="flex space-x-4">
                  <label>
                    Unit
                    <AppInput
                      v-model="tradeItemForm.form.unit"
                      class="form-input"
                      name="unit"
                      type="text"
                      :error="tradeItemForm.getErrors('unit')"
                      data-test="unit-input"
                    />
                  </label>

                  <label>
                    Quantity
                    <AppInput
                      v-model="tradeItemForm.form.quantity"
                      class="form-input"
                      name="quantity"
                      type="number"
                      :error="tradeItemForm.getErrors('quantity')"
                      data-test="quantity-input"
                    />
                  </label>
                </div>

                <label>
                  Rate <span class="text-sm text-gray-400">($/unit)</span>

                  <AppInput
                    v-model="tradeItemForm.form.rate"
                    class="form-input"
                    name="rate"
                    type="number"
                    :error="tradeItemForm.getErrors('rate')"
                    data-test="rate-input"
                  />
                </label>

                <div class="flex space-x-4 items-end">
                  <label class="w-1/2">
                    Budget <span class="text-sm text-gray-400">($)</span>

                    <AppInput
                      v-model="tradeItemForm.form.budget"
                      class="form-input disabled:bg-gray-300"
                      name="budget"
                      type="number"
                      :error="tradeItemForm.getErrors('budget')"
                      data-test="budget-input"
                      disabled
                    />
                  </label>

                  <button
                    type="button"
                    class="btn btn--secondary-blue w-1/2 disabled:cursor-not-allowed"
                    :disabled="!canAddTradeItem"
                    @click="!falsey(selectedTradeItem) ? saveEditTradeItem() : pushTradeItem()"
                  >
                    {{ !falsey(selectedTradeItem) ? `Save ${pricingForm.form.trade_items[selectedTradeItem].name}` : 'Add item' }}
                  </button>
                </div>
              </div>

              <div class="w-1/2">
                <div class="text-lg font-semibold px-2 flex justify-between">
                  <span>
                    Variation Bill of Quantities
                  </span>

                  <span
                    v-if="tradeItemTotal > 0"
                    class="font-normal"
                  >
                    {{ australianCurrency(tradeItemTotal) }}
                  </span>
                </div>

                <table class="w-full">
                  <thead class="first-child:pl-2 last-child:pr-2 child:p-2 text-left border-b border-gray-300">
                    <th>
                      Item
                    </th>

                    <th>
                      Unit
                    </th>

                    <th class="text-right">
                      Quantity
                    </th>

                    <th class="text-right">
                      Rate
                    </th>

                    <th class="text-right">
                      Amount
                    </th>
                  </thead>

                  <tbody>
                    <tr
                      v-for="(item, index) in pricingForm.form.trade_items"
                      :key="index"
                      class="child:p-2 odd:bg-white even:bg-gray-100"
                    >
                      <td :title="item.name">
                        <div class="flex space-x-2">
                          <button
                            type="button"
                            class="text-red-600"
                            @click="removeTradeItem(index)"
                          >
                            <Icon
                              name="close"
                              class="w-6 h-6"
                            />
                          </button>

                          <button
                            type="button"
                            class="text-ccm-blue-500"
                            @click="editTradeItem(index)"
                          >
                            <Icon
                              name="edit"
                              class="w-6 h-6"
                            />
                          </button>

                          <p class="text-wrap">
                            {{ item.name }}
                          </p>
                        </div>
                      </td>

                      <td>
                        {{ item.unit }}
                      </td>

                      <td class="text-right">
                        {{ item.quantity }}
                      </td>

                      <td class="text-right">
                        {{ item.rate }}
                      </td>

                      <td class="text-right">
                        {{ australianCurrency(item.budget) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div
            v-else
            class="flex flex-col"
          >
            <label class="mt-4">
              <span class="font-semibold text-lg">
                Price Variation by Sum <span class="text-sm text-gray-400">($)</span>
              </span>

              <AppInput
                v-model="pricingForm.form.budget"
                type="number"
                name="budget"
                class="form-input mt-2"
                :error="pricingForm.getErrors('budget')"
              />
            </label>
          </div>
        </form>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-end p-2 space-x-4">
        <button
          type="button"
          class="btn btn--secondary-blue"
          @click="$emit('close')"
        >
          Cancel
        </button>

        <button
          type="button"
          class="btn btn--primary-blue"
          data-test="apply-filter"
          @click="savePricing"
        >
          Save
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import Modal from '@/components/Modal.vue';
import { useFileUploadForm } from '@/composables/useFileUploadForm';
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency, dateForDateInput, falsey } from '@/helpers';
import { useProjectsStore } from '@/store/projects';
import { useTradeItemsStore } from '@/store/tradeItems';
import { useVariationOrdersStore } from '@/store/variationOrders';
import Swal from 'sweetalert2';
import { computed, ref, watch } from 'vue';

const emit = defineEmits<{
  (e: 'close');
  (e: 'priced');
}>();

const props = defineProps<{
  variationOrderId: Id;
}>();

const variationsOrdersStore = useVariationOrdersStore();

const variationOrder = computed(() => {
  return variationsOrdersStore.models.with('trade_items').find(props.variationOrderId);
});

const pricingForm = useFormData({
  budget: 0,
  trade_items: undefined,
});

const totalSumSelected = ref(true);

watch(variationOrder, (newValue) => {
  if(newValue) {
    if(newValue.trade_items.length) {
      totalSumSelected.value = false;
      pricingForm.form.budget = undefined;

      const tradeItems = newValue.trade_items.map((tradeItem) => {
        return {
          name: tradeItem.name,
          budget: tradeItem.budget,
          rate: tradeItem.rate,
          quantity: tradeItem.quantity,
          unit: tradeItem.unit,
        };
      });

      pricingForm.form.trade_items = tradeItems;
    } else {
      pricingForm.setData(newValue, ['trade_items']);
    }
  }
}, { immediate: true });

const tradeItemForm = useFormData({
  name: '',
  budget: 0,
  rate: 0,
  quantity: 0,
  unit: '',
});

const togglePricing = () => {
  if(totalSumSelected.value) {
    totalSumSelected.value = false;

    pricingForm.form.trade_items = [];

    pricingForm.form.budget = undefined;
  } else {
    if(pricingForm.form.trade_items?.length) {
      Swal.fire({
        icon: 'warning',
        title: 'Switch to Total Sum?',
        text: 'Switching will clear any trade items created',
        showCancelButton: true,
      }).then((result) => {
        if(result.isConfirmed) {
          totalSumSelected.value = true;

          pricingForm.resetData();
        }
      });
    } else {
      totalSumSelected.value = true;

      pricingForm.resetData();
    }
  }
};

const variationOrderPricingAction = useStoreApiAction(variationsOrdersStore.priceVariationOrder);

const savePricing = () => {
  variationOrderPricingAction.request(props.variationOrderId, pricingForm.form).then((data) => {
    Swal.fire('Variation Priced', '', 'success').then(() => {
      emit('priced');
      emit('close');
    });
  }).catch((error) => {
    console.log(error);
  });
};

watch(() => [tradeItemForm.form.rate, tradeItemForm.form.quantity], (newValue) => {
  if(newValue[0] > 0 && newValue[1] > 0) {
    tradeItemForm.form.budget = newValue[0] * newValue[1];
  }
});

const pushTradeItem = () => {
  pricingForm.form.trade_items.push({
    ...tradeItemForm.form,
  });

  tradeItemForm.resetData();
};

const tradeItemTotal = computed(() => {
  return pricingForm.form.trade_items?.reduce((total, tradeItem) => {
    return total += tradeItem.budget;
  }, 0);
});

const canAddTradeItem = computed(() => {
  return !!(tradeItemForm.form.name && tradeItemForm.form.budget && tradeItemForm.form.quantity &&
    tradeItemForm.form.rate && tradeItemForm.form.unit);
});

const removeTradeItem = (index: number) => {
  Swal.fire({
    icon: 'question',
    text: 'Remove trade item?',
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      pricingForm.form.trade_items.splice(index, 1);
    }
  });
};

const selectedTradeItem = ref<number | undefined>();

const editTradeItem = (index: number) => {
  selectedTradeItem.value = index;

  tradeItemForm.setData(pricingForm.form.trade_items[index]);
};

const saveEditTradeItem = () => {
  pricingForm.form.trade_items[selectedTradeItem.value] = {
    ...tradeItemForm.form,
  };

  tradeItemForm.resetData();

  selectedTradeItem.value = undefined;
};

const projectsStore = useProjectsStore();

const project = computed(() => {
  return projectsStore.project;
});
</script>

<style scoped></style>
