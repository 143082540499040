<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl">
          Attach Variation
        </h1>
      </div>
    </template>

    <template #body>
      <div class="min-w-md h-full w-full p-4">
        <form
          class="flex flex-col space-y-4"
          @submit.prevent="saveVariationOrder"
        >
          <div class="font-semibold text-lg">
            Date {{ new Date().toLocaleDateString() }}
          </div>

          <div>
            <div class="flex justify-between text-lg">
              <span>Delay</span>

              <span class="font-semibold">{{ delay.code }}</span>
            </div>

            <p>
              {{ delay.description }}
            </p>
          </div>

          <div class="flex flex-col space-y-2">
            <p class="font-semibold text-lg">
              Variation Details
            </p>

            <label>
              Description
              <textarea
                v-model="variationOrderForm.form.description"
                name="description"
                class="form-input resize-none"
              />
            </label>

            <label>
              Notes
              <textarea
                v-model="variationOrderForm.form.notes"
                name="notes"
                class="form-input resize-none"
              />
            </label>
          </div>
        </form>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-end p-2 space-x-4">
        <button
          type="button"
          class="btn btn--secondary-blue"
          @click="$emit('close')"
        >
          Cancel
        </button>

        <button
          type="button"
          class="btn btn--primary-blue"
          data-test="apply-filter"
          @click="saveVariationOrder"
        >
          Create
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import Modal from '@/components/Modal.vue';
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import Delay from '@/models/Delay';
import { useDelaysStore } from '@/store/delays';
import { useVariationOrdersStore } from '@/store/variationOrders';
import Swal from 'sweetalert2';
import { computed } from 'vue';

const props = defineProps<{
  projectId: string;
  delayId: Id;
}>();

const emits = defineEmits<{
  (e: 'close');
}>();

const delaysStore = useDelaysStore();
const attachVariationOrderAction = useStoreApiAction(delaysStore.attachVariationOrder);

const variationOrderForm = useFormData({
  description: '',
  notes: '',
});

const delay = computed(() => {
  return delaysStore.models.find(props.delayId);
});

const saveVariationOrder = () => {
  attachVariationOrderAction.request(props.projectId, variationOrderForm.form).then((variationOrder) => {
    Swal.fire({
      icon: 'success',
      text: 'Variation attached to delay.',
    }).then(() => {
      emits('close');
    });
  }).catch((error) => {
    variationOrderForm.setErrors(error.data);
  });
};
</script>

<style scoped></style>
