<template>
  <div class="!pt-0">
    <div class="flex flex-col max-h-screen pt-16 pb-2.5 min-h-screen space-y-2.5">
      <div class="flex items-stretch justify-between mt-2.5">
        <div class="flex space-x-2 element-island">
          <h1 class="font-semibold text-3xl">
            Variations Schedule
          </h1>
        </div>

        <div class="element-island flex items-center">
          <button
            type="button"
            class="btn btn--secondary-blue"
            @click="showCreateVariationModal = true"
          >
            + Variation
          </button>
        </div>
      </div>

      <div class="element-island overflow-y-auto flex space-x-8 min-h-max flex-1 p-4">
        <template v-if="variationOrders.length">
          <div
            v-if="fetchVariationsAction.firstLoad"
            class="flex flex-col space-y-4 w-3/5"
            :class="[variationsExpanded ? 'w-full' : 'w-3/5']"
          >
            <div class="relative h-full">
              <div class="absolute inset-0 overflow-auto">
                <div class="flex flex-col w-full">
                  <div class="sticky top-0 bg-white">
                    <div class="flex justify-between items-center px-2">
                      <div class="flex space-x-4 items-center">
                        <div
                          class=""
                          @click="toggleExpand"
                        >
                          <Icon
                            v-if="variationsExpanded"
                            name="arrows-pointing-in"
                            class="w-6 h-6 hover:stroke-ccm-blue-500 cursor-pointer"
                          />

                          <Icon
                            v-else
                            name="arrows-pointing-out"
                            class="w-6 h-6 hover:stroke-ccm-blue-500 cursor-pointer"
                          />
                        </div>

                        <Icon
                          name="search"
                          class="w-6 h-6"
                        />

                        <AppInput
                          v-model="searchForm.form.search"
                          type="text"
                          name="variation_orders_filter"
                          class="form-input h-10"
                        />
                      </div>

                      <AppSelect
                        v-model="searchForm.form.status"
                        name="status_filter"
                        class="capitalize form-input pr-8"
                        :options="statusOptions"
                        empty-text="Status"
                      />

                      <button
                        type="button"
                        class="btn btn--secondary-blue"
                        @click="resetSearch"
                      >
                        Clear Search
                      </button>
                    </div>
                  </div>

                  <table
                    v-if="fetchVariationsAction.is(States.COMPLETE) && filteredVariations.length > 0"
                    class="mt-4 border-separate border-spacing-0 w-full"
                  >
                    <thead>
                      <tr class="child:p-2 sticky top-0 bg-white border border-gray-200 child:border-y child:border-gray-200 first-child:border-l last-child:border-r">
                        <th class="text-left border-b border-gray-200">
                          Variation
                        </th>

                        <th class="text-left border-b border-gray-200">
                          Client Ref
                        </th>

                        <th class="text-left border-b border-gray-200">
                          Status
                        </th>

                        <th class="text-right border-b border-gray-200">
                          Amount
                        </th>

                        <th
                          v-if="variationsExpanded"
                          class="text-left border-b border-gray-200"
                        >
                          Next Action
                        </th>

                        <th class="border-b border-gray-200" />
                      </tr>
                    </thead>

                    <tbody>
                      <VariationOrderRow
                        v-for="variationOrder in filteredVariations"
                        :key="variationOrder.id"
                        :variation-order="variationOrder"
                        :expanded="variationsExpanded"
                        @show-variation="showVariation(variationOrder)"
                        @open-comment-modal="openCommentModal(variationOrder)"
                        @accept-variation-order="acceptVariationOrder(variationOrder)"
                        @decline-variation-order="declineVariationOrder(variationOrder)"
                        @price-variation-order="selectedVariation = variationOrder.id; showModal = true;"
                        @submit-variation-order="submitVariationOrder(variationOrder.id)"
                        @approve-variation-order="approveVariationOrder(variationOrder.id)"
                        @sum-set-variation-order="priceVariationOrder($event.variationOrder, $event.sumPrice)"
                        @save-next-action="updateVariationOrder($event.variationOrder, $event.nextAction)"
                        @send-notice="sendNoticeForVariationOrder($event)"
                        @register-notice="registerNotice($event)"
                        @register-pricing-notice="registerPricingNotice($event)"
                        @open-correspondence-modal="openCorrespondenceModal($event)"
                      />

                      <tr
                        v-if="!searchForm.form.search && fetchVariationsAction.is(States.COMPLETE)"
                        class="sticky bottom-0 bg-white border child:px-2 child:border-y first-child:border-l last-child:border-r font-semibold"
                      >
                        <td />

                        <td />

                        <td>
                          Total
                        </td>

                        <td class="text-right">
                          {{ australianCurrency(totalVariationsBudget) }}
                        </td>

                        <td v-if="variationsExpanded" />

                        <td />
                      </tr>
                    </tbody>
                  </table>

                  <div
                    v-else-if="fetchVariationsAction.is(States.COMPLETE)"
                    class="font-semibold text-center mt-4"
                  >
                    No variations found, try adjusting your filter.
                  </div>

                  <AppSpinner
                    v-else
                    class="mx-auto mt-4"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="!variationsExpanded"
              class="text-sm my-auto w-1/2"
            >
              <div class="bg-ccm-blue-100 rounded-t-md text-white p-2 font-semibold text-base">
                Cost Synopsis
              </div>

              <table class="w-full">
                <thead class="text-base child:border-b child:border-x border-gray-600 font-semibold">
                  <tr class="child:px-5 child:py-2 text-left">
                    <th>Status</th>

                    <th class="text-right">
                      Cost
                    </th>
                  </tr>
                </thead>

                <tbody class="child:border-b child:border-x border-gray-600 font-semibold">
                  <tr class="child:px-5 child:py-2">
                    <td>
                      Priced
                    </td>

                    <td class="text-right">
                      {{ australianCurrency(variationsSummary.priced) }}
                    </td>
                  </tr>

                  <tr class="child:px-5 child:py-2">
                    <td>
                      Submitted
                    </td>

                    <td class="text-right">
                      {{ australianCurrency(variationsSummary.submitted) }}
                    </td>
                  </tr>

                  <tr class="child:px-5 child:py-2">
                    <td>
                      Provisioned
                    </td>

                    <td class="text-right">
                      {{ australianCurrency(variationsSummary.provisioned) }}
                    </td>
                  </tr>

                  <tr class="child:px-5 child:py-2">
                    <td>
                      Approved
                    </td>

                    <td class="text-right">
                      {{ australianCurrency(variationsSummary.approved) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <AppSpinner
            v-else
            class="mx-auto mt-5"
          />

          <div
            v-show="!variationsExpanded"
            class="flex flex-col space-y-4 w-2/5"
          >
            <div class="flex h-full">
              <div class="w-1/2 my-auto">
                <p class="text-center text-lg font-semibold">
                  Variations by status
                </p>

                <VariationsStatusGraph
                  class="mt-6"
                  :variation-orders-by-status="variationOrdersByStatus"
                  :variations-summary="variationsSummary"
                />
              </div>

              <div class="w-1/2 my-auto">
                <p class="text-center text-lg font-semibold">
                  Variation Status by Value
                </p>

                <VariationsValueGraph
                  class="mt-6"
                  :variation-orders-by-status="variationOrdersByStatus"
                  :variations-summary="variationsSummary"
                  :total-variations-budget="totalVariationsBudget"
                />
              </div>
            </div>

            <div class="text-sm my-auto">
              <div class="bg-ccm-blue-100 rounded-t-md text-white p-2 font-semibold text-base ">
                Project Summary
              </div>

              <table class="w-full">
                <tbody class="child:border-b child:border-x border-gray-600 font-semibold">
                  <tr class="first-child:pl-4 child:p-2">
                    <td>
                      Contract Value
                    </td>

                    <td>{{ australianCurrency(totalTradesBudget) }}</td>
                  </tr>

                  <tr class="first-child:pl-4 child:p-2">
                    <td>
                      Approved Variations Value
                    </td>

                    <td>{{ australianCurrency(variationsSummary.approved_variations_total) }}</td>
                  </tr>

                  <tr class="first-child:pl-4 child:p-2">
                    <td>
                      Current Adjusted Value
                    </td>

                    <td>{{ australianCurrency(totalTradesBudget + variationsSummary.approved_variations_total) }}</td>
                  </tr>

                  <tr class="first-child:pl-4 child:p-2">
                    <td>
                      Unapproved Variations
                    </td>

                    <td>{{ australianCurrency(variationsSummary.unapproved_variations_total) }}</td>
                  </tr>

                  <tr class="first-child:pl-4 child:p-2">
                    <td>
                      Final Estimated Value
                    </td>

                    <td>{{ australianCurrency(totalTradesBudget + totalVariationsBudget) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>

        <div
          v-else-if="fetchVariationsAction.isNot(States.LOADING)"
          class="font-semibold mx-auto"
        >
          No variations found for project.
        </div>

        <div
          v-else
          class="flex w-full"
        >
          <AppSpinner class="mx-auto" />
        </div>
      </div>
    </div>

    <PricingModal
      v-if="showModal"
      :variation-order-id="selectedVariation"
      @close="showModal = false"
      @priced="fetchSummary"
    />

    <VariationSaveModal
      v-if="showCreateVariationModal"
      :project-id="$props.projectId"
      @close="showCreateVariationModal = false"
    />

    <VariationShowModal
      v-if="showVariationModal"
      :variation-order-id="selectedVariation"
      @close="showVariationModal = false"
    />

    <VariationCommentModal
      v-if="showCommentModal"
      :project-id="props.projectId"
      :variation-id="selectedVariation"
      @close="showCommentModal = false"
    />

    <RegisterNoticeModal
      v-if="showRegisterModal"
      :variation-order-id="selectedVariation"
      @close="showRegisterModal = false"
    />

    <RegisterPricingModal
      v-if="showPricingRegisterModal"
      :variation-order-id="selectedVariation"
      @close="showPricingRegisterModal = false"
    />

    <VariationCorrespondenceModal
      v-if="showVariationCorrespondenceModal"
      :variation-order-id="selectedVariation"
      @close="showVariationCorrespondenceModal = false"
    />
  </div>
</template>

<script setup lang="ts">
import PricingModal from '@/components/HeadContract/VariationsSchedule/PricingModal.vue';
import RegisterNoticeModal from '@/components/HeadContract/VariationsSchedule/RegisterNoticeModal.vue';
import RegisterPricingModal from '@/components/HeadContract/VariationsSchedule/RegisterPricingModal.vue';
import VariationCommentModal from '@/components/HeadContract/VariationsSchedule/VariationCommentModal.vue';
import VariationCorrespondenceModal from '@/components/HeadContract/VariationsSchedule/VariationCorrespondenceModal.vue';
import VariationSaveModal from '@/components/HeadContract/VariationsSchedule/VariationSaveModal.vue';
import VariationShowModal from '@/components/HeadContract/VariationsSchedule/VariationShowModal.vue';
import VariationsStatusGraph from '@/components/HeadContract/VariationsSchedule/VariationsStatusGraph.vue';
import VariationsValueGraph from '@/components/HeadContract/VariationsSchedule/VariationsValueGraph.vue';
import VariationOrderRow from '@/components/VariationsSchedule/VariationOrderRow.vue';
import { useFormData } from '@/composables/useFormData';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency, cumulativeSum, enumKeyFromValue, roundDecimals } from '@/helpers';
import VariationOrder, { VariationOrderStatus } from '@/models/VariationOrder';
import { useProjectsStore } from '@/store/projects';
import { useTradesStore } from '@/store/trades';
import { useVariationOrdersStore } from '@/store/variationOrders';
import { useDebounce, useDebounceFn } from '@vueuse/core';
import Swal from 'sweetalert2';
import { computed, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps<{
  projectId: string;
}>();

const variationOrdersStore = useVariationOrdersStore();
const projectsStore = useProjectsStore();
const tradesStore = useTradesStore();

const project = computed(() => {
  return projectsStore.project;
});

const variationsSummary = ref<VariationOrdersSummary>({
  approved_variations_total: 0,
  unapproved_variations_total: 0,
  priced: 0,
  submitted: 0,
  provisioned: 0,
  approved: 0,
  total_variations: 0,
  total_budget: 0,
});

const fetchTradesAction = useStoreApiAction(tradesStore.fetchTrades);
const fetchVariationsAction = useStoreApiAction(variationOrdersStore.fetchVariationOrders);
const fetchVariationsSummaryAction = useStoreApiAction(variationOrdersStore.fetchVariationOrdersSummary);

const fetchSummary = () => {
  fetchVariationsSummaryAction.request(project.value.id).then((summary) => {
    variationsSummary.value = summary;
  }).catch(console.error);
};

watch(() => project.value?.id, (newValue) => {
  if(newValue) {
    fetchVariationsAction.request(newValue);

    fetchTradesAction.request(newValue, { boq_type: 'direct' });

    fetchVariationsSummaryAction.request(newValue).then((summary) => {
      variationsSummary.value = summary;
    }).catch(console.error);
  }
}, { immediate: true });

const router = useRouter();

watch(project, (newValue) => {
  if(newValue && !newValue.boq_locked) {
    router.push({ name: 'BillOfQuantities', params: { projectId: newValue.id } });
  }
}, { immediate: true });

const variationOrders = computed(() => {
  return variationOrdersStore.models.with('trade_items').where('project_id', parseInt(props.projectId)).orderBy(
    'status',
  ).get();
});

const totalVariationsBudget = computed(() => {
  return variationsSummary.value.unapproved_variations_total + variationsSummary.value.approved_variations_total;
});

const variationOrdersByStatus = computed(() => {
  return variationOrders.value.reduce((variationsMap, variation) => {
    const key = enumKeyFromValue(VariationOrderStatus, variation.status);

    if(variationsMap.has(key)) {
      const variations = variationsMap.get(key);

      variations.push(variation);
      variationsMap.set(key, variations);
    } else {
      variationsMap.set(key, [variation]);
    }

    return variationsMap;
  }, new Map<string, VariationOrder[]>());
});

const acceptVariationOrderAction = useStoreApiAction(variationOrdersStore.acceptVariationOrder);
const declineVariationOrderAction = useStoreApiAction(variationOrdersStore.declineVariationOrder);
const submitVariationOrderAction = useStoreApiAction(variationOrdersStore.submitVariationOrder);
const approveVariationOrderAction = useStoreApiAction(variationOrdersStore.approveVariationOrder);
const priceVariationOrderAction = useStoreApiAction(variationOrdersStore.priceVariationOrder);
const updateVariationOrderAction = useStoreApiAction(variationOrdersStore.updateVariationOrder);
const sendNoticeForVariationOrderAction = useStoreApiAction(variationOrdersStore.sendNoticeForVariationOrder);

const updateVariationOrder = (variationOrder: VariationOrder, nextAction: string) => {
  updateVariationOrderAction.request(variationOrder.id, { next_action: nextAction }).catch(console.error);
};

const acceptVariationOrder = (variationOrder: VariationOrder) => {
  Swal.fire({
    title: 'Accept Variation Order?',
    icon: 'question',
    text: variationOrder.description,
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      acceptVariationOrderAction.request(variationOrder.id).then(() => {
        console.log('accepted');
      }).catch((error) => {
        console.log(error);
      });
    }
  });
};

const declineVariationOrder = (variationOrder: VariationOrder) => {
  Swal.fire({
    title: 'Decline Variation Order?',
    icon: 'question',
    text: variationOrder.description,
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      declineVariationOrderAction.request(variationOrder.id).then(() => {
        console.log('declined');
      }).catch((error) => {
        console.log(error);
      });
    }
  });
};

const submitVariationOrder = (id: Id) => {
  Swal.fire({
    title: 'Submit Variation Order?',
    text: 'Submit variation order pricing to be approved by client',
    icon: 'question',
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      submitVariationOrderAction.request(id).then((data) => {
        console.log(data);
      }).catch((error) => {
        Swal.fire('Failed to Submit', 'Failed to submit variation order', 'error');
      });
    }
  });
};

const approveVariationOrder = (id: Id) => {
  Swal.fire({
    title: 'Approve Variation Order?',
    icon: 'question',
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      approveVariationOrderAction.request(id).then((data) => {
        console.log(data);
      }).catch((error) => {
        Swal.fire('Failed to Approve', '', 'error');
      });
    }
  });
};

const priceVariationOrder = (variationOrder: VariationOrder, budget: number) => {
  priceVariationOrderAction.request(variationOrder.id, { budget }).then().catch((error) => {
    console.log(error);
  });
};

const sendNoticeForVariationOrder = (variationOrder: VariationOrder) => {
  Swal.fire({
    title: 'Send Notice for Variation Order?',
    icon: 'question',
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed) {
      sendNoticeForVariationOrderAction.request(variationOrder.id).then((data) => {
        Swal.fire('Notice Sent', '', 'success');
      }).catch((error) => {
        Swal.fire('Failed to Send Notice', '', 'error');
      });
    }
  });
};

const selectedVariation = ref();
const showModal = ref(false);
const showCreateVariationModal = ref(false);

const trades = computed(() => {
  return tradesStore.models.where('project_id', project.value?.id).where('boq_type', 'direct').get();
});

const totalTradesBudget = computed(() => {
  return trades.value.reduce((total, current) => {
    return total + current.totalBudget;
  }, 0);
});

const searchForm = useFormData({
  search: '',
  status: undefined,
});

const statusOptions = computed(() => {
  const options = [];

  for(const key in VariationOrderStatus) {
    options.push({ value: VariationOrderStatus[key], label: key });
  }

  return options;
});

const filteredVariationIds = ref<number[]>();

const searchVariations = () => {
  fetchVariationsAction.request(project.value?.id, searchForm.form).then(({ data: variations }) => {
    filteredVariationIds.value = variations.map((variation) => variation.id);
  }).catch((error) => {
    console.log(error);
  });
};

const filteredVariations = computed(() => {
  if(!searchForm.isDirty.value) {
    return variationOrders.value;
  }

  return variationOrders.value.filter((variationOrder) => {
    return filteredVariationIds.value?.includes(variationOrder.id);
  });
});

const debounceSearch = useDebounceFn(searchVariations, 300);
const filterActive = ref(false);

watch(searchForm.form, (newValue) => {
  if(!newValue.search && !newValue.status) {
    filterActive.value = false;
  } else {
    filterActive.value = true;
  }
});

watch(searchForm.form, () => {
  if(filterActive.value) {
    debounceSearch();
  }
});

const resetSearch = () => {
  filterActive.value = false;

  searchForm.resetData();

  searchVariations();
};

const showVariationModal = ref(false);

const showVariation = (variation: VariationOrder) => {
  selectedVariation.value = variation.id;

  showVariationModal.value = true;
};

const showCommentModal = ref(false);

const openCommentModal = (variation: VariationOrder) => {
  selectedVariation.value = variation.id;

  showCommentModal.value = true;
};

const showRegisterModal = ref(false);

const registerNotice = (variation: VariationOrder) => {
  selectedVariation.value = variation.id;

  showRegisterModal.value = true;
};

const showPricingRegisterModal = ref(false);

const registerPricingNotice = (variation: VariationOrder) => {
  selectedVariation.value = variation.id;

  showPricingRegisterModal.value = true;
};

const showVariationCorrespondenceModal = ref(false);

const openCorrespondenceModal = (variation: VariationOrder) => {
  selectedVariation.value = variation.id;

  showVariationCorrespondenceModal.value = true;
};

// const totalORders = ref(0);
// const ordersIndex = ref(0);
// const { wrapperProps } = useVirtualList(totalORders, ordersIndex, 40);
// const orderListRef = ref();
// const page = ref(1);
// const paginator = ref<ResourcePaginator<VariationOrder>>();

// const recordQueue = useQueue<number>(10);

// const initialFetch = () => {
//   variationOrdersStore.models.query().where('plant_id', props.plant.id).delete();

//   fetchVariationsAction.request(props.plant.id, plantsStore.filter.form).then((newPaginator) => {
//     recordQueue.reset();
//     totalRecords.value = 0;
//     totalRecords.value += newPaginator.data.length;

//     recordIndex.value = newPaginator.meta.current_page * newPaginator.meta.per_page;

//     recordQueue.enqueueFront(newPaginator.data.map((record: PlantDailyRecord) => {
//       return record.id;
//     }));

//     paginator.value = newPaginator;
//   });
// };

// const scrolled = () => {
//   const recordList = recordListRef.value;

//   if(recordList) {
//     const scrollable = recordList.scrollHeight > recordList.clientHeight;

//     if(
//       page.value > 1 &&
//       scrollable &&
//       recordList.scrollTop === 0
//     ) {
//       fetchRecordsAction.request(props.plant.id, { page: page.value - 1, ...plantsStore.filter.form })
//         .then((newPaginator) => {
//           paginator.value = newPaginator;

//           totalRecords.value -= newPaginator.data.length;
//           recordIndex.value = newPaginator.meta.current_page * newPaginator.meta.per_page;

//           const recordsToDelete = recordQueue.enqueueBack(newPaginator.data.map((record: PlantDailyRecord) => {
//             return record.id;
//           }));

//           plantDailyRecordsStore.models.query().whereId(recordsToDelete).delete();

//           recordList.scrollTop = Math.round(recordList.scrollHeight * 0.1);
//           page.value = newPaginator.meta.current_page;
//         });
//     } else if(
//       paginator.value.meta.last_page !== page.value &&
//       scrollable &&
//       recordList.scrollHeight - recordList.scrollTop === recordList.clientHeight
//     ) {
//       fetchRecordsAction.request(props.plant.id, { page: page.value + 1, ...plantsStore.filter.form })
//         .then((newPaginator) => {
//           paginator.value = newPaginator;

//           totalRecords.value += newPaginator.data.length;
//           recordIndex.value = newPaginator.meta.current_page * newPaginator.meta.per_page;

//           const recordsToDelete = recordQueue.enqueueFront(newPaginator.data.map((record: PlantDailyRecord) => {
//             return record.id;
//           }));

//           plantDailyRecordsStore.models.query().whereId(recordsToDelete).delete();

//           recordList.scrollTop = Math.round(recordList.scrollHeight * 0.2);
//           page.value = newPaginator.meta.current_page;
//         });
//     }
//   }
// };

const variationsExpanded = ref(false);

const toggleExpand = () => {
  if(variationsExpanded.value) {
    variationsExpanded.value = false;
  } else {
    variationsExpanded.value = true;
  }
};
</script>

<style scoped></style>
