import Delay from '@/models/Delay';
import VariationOrder from '@/models/VariationOrder';
import DelayService from '@/services/DelayService';
import { defineStore } from 'pinia';
import { useRepo } from 'pinia-orm';

export const useDelaysStore = defineStore('delays', {
  state: () => ({}),

  getters: {
    models: () => useRepo(Delay),
  },

  actions: {
    async fetchDelays(projectId: Id, form: ApiRequests['api.v1.delays.index'] = {}) {
      const [data, error] = await DelayService.index(projectId, form);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    // async fetchApprovedVariationOrders(projectId: Id) {
    //   const [data, error] = await VariationOrderService.claimIndex(projectId);

    //   if(data) {
    //     this.models.save(data);

    //     return data;
    //   }

    //   throw error;
    // },

    async fetchDelay(id: Id) {
      const [data, error] = await DelayService.show(id);

      if(data) {
        this.models.save(data.data);

        return data;
      }

      throw error;
    },

    async acceptDelay(id: Id) {
      const [data, error] = await DelayService.accept(id);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async declineDelay(id: Id) {
      const [data, error] = await DelayService.decline(id);

      if(data) {
        this.models.destroy(data.id);

        return data;
      }

      throw error;
    },

    async submitDelay(id: Id) {
      const [data, error] = await DelayService.submit(id);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async provisionDelay(id: Id, signedParams: unknown) {
      const [data, error] = await DelayService.provision(id, signedParams);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async approveDelay(id: Id) {
      const [data, error] = await DelayService.approve(id);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async createDelay(projectId: Id, request: ApiRequests['api.v1.delays.store']) {
      const [data, error] = await DelayService.create(projectId, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async updateDelay(id: Id, request: ApiRequests['api.v1.delays.update']) {
      const [data, error] = await DelayService.update(id, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },
    // async deleteDelay(id: Id) {
    //   const [data, error] = await DelayService.delete(id);

    //   if(data) {
    //     this.models.destroy(data.id);

    //     return data;
    //   }

    //   throw error;
    // },

    async fetchDelaysSummary(projectId: Id) {
      const [data, error] = await DelayService.summary(projectId);

      if(data) {
        return data as DelaysSummary;
      }

      throw error;
    },

    async comment(id: Id, form: ApiRequests['api.v1.delays.comments']) {
      const [data, error] = await DelayService.comment(id, form);

      if(data) {
        return data;
      }

      throw error;
    },

    async attachVariationOrder(id: Id, form: ApiRequests['api.v1.delays.attach-variation-order']) {
      const [data, error] = await DelayService.attachVariationOrder(id, form);

      if(data) {
        return data;
      }

      throw error;
    },
  },
});
