<template>
  <div class="w-[90%] mx-auto flex items-center pb-16">
    <div class="bg-white p-2 mt-8 rounded-lg shadow-lg border border-gray-300 w-full">
      <div class="my-5 flex justify-center">
        <h1 class="text-3xl font-semibold justify-center">
          New Claim
        </h1>
      </div>

      <div class="flex flex-col px-3">
        <form
          class="flex flex-col space-y-4"
          @submit.prevent="saveVariationOrder"
        >
          <div>
            Date {{ new Date().toLocaleDateString() }}
          </div>

          <label>
            Name

            <AppInput
              v-model="variationOrderForm.form.name"
              class="form-input"
              type="text"
              name="name"
            />
          </label>

          <label>
            Type

            <!-- <AppSelect
              v-model="variationOrderForm.form.type"
              class="form-input"
              name="type"
              :options="[
                '0', 1
              ]"
            /> -->
          </label>

          <div>
            Description
            <textarea
              v-model="variationOrderForm.form.description"
              name="description"
              class="form-input resize-none"
            />
          </div>

          <div class="flex space-x-4">
            <RouterLink
              type="button"
              class="btn btn--secondary-blue w-1/2"
              :to="{ name: 'MobileVariationsIndex' }"
            >
              Cancel
            </RouterLink>

            <button
              type="submit"
              class="btn btn--primary-blue w-1/2"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { useVariationOrdersStore } from '@/store/variationOrders';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps<{
  projectId: string;
  variationOrderId?: string;
}>();

const variationOrdersStore = useVariationOrdersStore();
const router = useRouter();

variationOrdersStore.fetchVariationOrders(props.projectId);

const variationOrder = computed(() => {
  return variationOrdersStore.models.find(props.variationOrderId);
});

const createVariationOrderAction = useStoreApiAction(variationOrdersStore.createVariationOrder);

const variationOrderForm = useFormData({
  description: '',
  type: 0,
  name: '',
});

const saveVariationOrder = () => {
  createVariationOrderAction.request(props.projectId, variationOrderForm.form).then((variationOrder) => {
    router.push({ name: 'MobileVariationsIndex' });
  }).catch((error) => {
    variationOrderForm.setErrors(error.data);
  });
};
</script>

<style scoped></style>
