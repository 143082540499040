<template>
  <div class="w-full">
    <canvas
      ref="statusChart"
    />
  </div>
</template>

<script setup lang="ts">
import VariationOrder, { VariationOrderStatusColours, VariationOrderStatusTitles } from '@/models/VariationOrder';
import { Chart } from 'chart.js';
import { ref, useTemplateRef, watch } from 'vue';

const props = defineProps<{
  variationOrdersByStatus: Map<string, VariationOrder[]>;
  variationsSummary: VariationOrdersSummary;
}>();

let statusChartCanvas: Chart<'pie'> | undefined;
const statusChart = useTemplateRef('statusChart');

watch(statusChart, (newValue) => {
  if(newValue) {
    const ctx = newValue.getContext('2d');

    if(statusChartCanvas !== undefined) {
      statusChartCanvas.destroy();
    }

    const variations = Array.from(props.variationOrdersByStatus.keys());

    const variationsPerStatus = Array.from(props.variationOrdersByStatus.values()).map((variations) => {
      return variations.length;
    });

    const statusLabels = variations.map((status) => {
      return VariationOrderStatusTitles[status];
    });

    const colours = variations.map((status) => {
      return VariationOrderStatusColours[status];
    });

    const data = {
      labels: statusLabels,

      datasets: [{
        data: variationsPerStatus,
        // TODO: 17/02/25 Fix when variations load before chart
        backgroundColor: colours,

        hoverOffset: 4,
      }],
    };

    statusChartCanvas = new Chart(ctx, {
      type: 'pie',
      data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
          },

          tooltip: {
            callbacks: {
              label(this, tooltipItem) {
                return `${tooltipItem.dataset.data[tooltipItem.dataIndex]} (${
                  (tooltipItem.dataset.data[tooltipItem.dataIndex] / props.variationsSummary.total_variations * 100)
                    .toFixed(2)
                }%)`;
              },
            },
          },
        },

        maintainAspectRatio: false,
      },
    });
  }
});

watch(() => props.variationOrdersByStatus, (newValue) => {
  if(statusChartCanvas) {
    const variations = Array.from(newValue.keys());

    const variationsPerStatus = Array.from(newValue.values()).map((variations) => {
      return variations.length;
    });

    const statusLabels = variations.map((status) => {
      return VariationOrderStatusTitles[status];
    });

    const colours = variations.map((status) => {
      return VariationOrderStatusColours[status];
    });

    statusChartCanvas.data.datasets[0].data = variationsPerStatus;
    statusChartCanvas.data.labels = statusLabels;
    statusChartCanvas.data.datasets[0].backgroundColor = colours;

    statusChartCanvas.update();
  }
}, { immediate: true });
</script>

<style scoped></style>
