<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl">
          Register Notice for Variation Order
        </h1>
      </div>
    </template>

    <template #body>
      <div class="min-w-md flex flex-col p-4 max-h-[30rem] overflow-y-auto w-full">
        <p class="font-semibold text-lg">
          Variation Order
        </p>

        <p class="font-semibold">
          Description
        </p>

        <p>
          {{ variationOrder.description }}
        </p>

        <p class="font-semibold mt-2">
          Notes
        </p>

        <div
          v-if="variationOrder.notes"
        >
          {{ variationOrder.notes }}
        </div>

        <form
          action=""
          class="flex flex-col space-y-4 mt-4"
          @submit.prevent=""
        >
          <label>
            Date Received

            <AppInput
              v-model="noticeForm.form.date_received"
              name="date_received"
              type="date"
              class="form-input"
            />
          </label>

          <label>
            To

            <AppInput
              v-model="noticeForm.form.to"
              name="to"
              type="text"
              class="form-input"
            />
          </label>

          <label>
            From

            <AppInput
              v-model="noticeForm.form.from"
              name="from"
              class="form-input"
              type="text"
            />
          </label>

          <label>
            Description

            <textarea
              v-model="noticeForm.form.description"
              name="description"
              class="form-input resize-none"
              type="text"
            />

            <span
              v-if="noticeForm.getErrors('description')"
              class="text-red-600 text-sm"
            >{{ noticeForm.getErrors('description') }}</span>
          </label>

          <label class="flex justify-between">
            Response Required

            <input
              v-model="noticeForm.form.response_required"
              name="response_required"
              type="checkbox"
              class="form-checkbox"
              true-value="1"
              false-value="0"
            >
          </label>

          <FileUpload
            v-model="noticeForm.form.media"
            multiple
            :error="noticeForm.getErrors('media.0')"
            @change="addFile"
          />

          <div
            v-if="filesUploaded.length"
            class="overflow-x-auto max-w-sm flex"
          >
            <UploadedFileDisplay
              v-for="(file, i) in filesUploaded"
              :key="i"
              :file="file"
              :error="noticeForm.getErrors(`media.${i}`)"
              @delete="removeFile(i)"
            />
          </div>
        </form>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-end p-2 space-x-4">
        <button
          type="button"
          class="btn btn--secondary-blue"
          @click="$emit('close')"
        >
          Cancel
        </button>

        <button
          type="button"
          class="btn btn--primary-blue"
          data-test="apply-filter"
          @click="storeNotice"
        >
          Register Notice
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import FileUpload from '@/components/FileUpload.vue';
import Modal from '@/components/Modal.vue';
import UploadedFileDisplay from '@/components/UploadedFileDisplay.vue';
import { useFileUploadForm } from '@/composables/useFileUploadForm';
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { dateForDateInput, dateForInput } from '@/helpers';
import { useVariationOrdersStore } from '@/store/variationOrders';
import Swal from 'sweetalert2';
import { computed, ref } from 'vue';

const emit = defineEmits<{
  (e: 'close');
  (e: 'priced');
}>();

const props = defineProps<{
  variationOrderId: Id;
}>();

const variationsOrdersStore = useVariationOrdersStore();

const variationOrder = computed(() => {
  return variationsOrdersStore.models.find(props.variationOrderId);
});

const noticeForm = useFormData({
  date_received: dateForDateInput(new Date()),
  to: '',
  from: '',
  description: '',
  response_required: 0,
  media: [],
});

const storeNoticeAction = useStoreApiAction(variationsOrdersStore.storeNotice);

const storeNotice = () => {
  storeNoticeAction.request(props.variationOrderId, noticeForm.toFormDataObject()).then((data) => {
    Swal.fire({
      icon: 'success',
      title: 'Notice Registered',
    }).then(() => {
      emit('close');
    });
  }).catch((error) => {
    noticeForm.setErrors(error.data);
  });
};

const { filesUploaded, addFile, removeFile } = useFileUploadForm(undefined, (file, index) => {
  noticeForm.form.media.splice(index, 1);
});
</script>

<style scoped></style>
