<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl mx-10">
          {{ project?.name }} Monthly Commentary
        </h1>
      </div>
    </template>

    <template #body>
      <div class="h-full w-full p-4 overflow-y-auto min-w-3xl max-w-3xl">
        <div
          v-if="indexCommentaryAction.is(States.COMPLETE)"
          class="flex divide-gray-300 divide-x justify-between"
        >
          <div class="p-2 w-1/3 overflow-y-auto  max-h-96">
            <div
              v-if="!commentaryForCurrentMonthExists && selectedComment"
              class="sticky -top-2 bg-white flex justify-center"
            >
              <button
                type="button"
                class="btn btn--tertiary-blue text-sm"
                @click="deselectComment"
              >
                Add {{ new Date().toLocaleString('default', { month: 'long', year: 'numeric' }) }} commentary
              </button>
            </div>

            <ul class="space-y-2">
              <li
                v-for="comment in projectMonthlyCommentary"
                :key="comment.id"
                :class="{ 'bg-gray-100': comment.id === selectedComment?.id }"
                class="p-2 rounded-lg"
              >
                <button
                  type="button"
                  class="text-left w-full"
                  @click="selectComment(comment)"
                >
                  <p class="font-semibold">
                    {{ comment.createdAt.toLocaleString('default', { month: 'long', year: 'numeric' }) }}
                  </p>

                  <p class="line-clamp-3">
                    {{ comment.body }}
                  </p>
                </button>
              </li>
            </ul>
          </div>

          <form
            action=""
            class="flex flex-col space-y-4 p-2 w-2/3 justify-center"
            @submit.prevent="saveCommentary"
          >
            <div
              v-if="daysUntilLocked < 7 && selectedCommentIsThisMonth"
              class="flex space-x-2 bg-red-100 w-full p-4 font-medium"
            >
              <Icon
                name="caution"
                class="h-6 w-6 text-red-600"
              />

              <p
                v-if="daysUntilLocked > 0"
              >
                Commentary will be locked {{ daysUntilLocked === 1 ? 'tomorrow' : `in ${daysUntilLocked} days` }}.
              </p>

              <p v-else>
                Commentary is locked and cannot be changed.
              </p>
            </div>

            <label>
              <span class="font-medium">
                {{ selectedComment ? selectedComment.createdAt.toLocaleString('default', { month: 'long', year: 'numeric' }) : new Date().toLocaleString('default', { month: 'long', year: 'numeric' }) }} Commentary
              </span>

              <textarea
                v-model="commentaryForm.form.comment"
                :disabled="disableCommentary"
                name="comment"
                rows="10"
                class="form-input resize-none mt-4"
              />
            </label>
          </form>
        </div>

        <AppSpinner
          v-else
          class="mx-auto w-full"
        />
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-end p-2 space-x-4">
        <button
          type="button"
          class="btn btn--secondary-blue"
          @click="$emit('close');"
        >
          Cancel
        </button>

        <button
          :disabled="disableCommentary"
          type="button"
          class="btn btn--primary-blue"
          @click="saveCommentary"
        >
          {{ selectedComment ? 'Update' : 'Save' }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import Modal from '@/components/Modal.vue';
import { useFormData } from '@/composables/useFormData';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import Comment from '@/models/Comment';
import { useCommentsStore } from '@/store/comments';
import { useProjectsStore } from '@/store/projects';
import { DateTime } from 'luxon';
import Swal from 'sweetalert2';
import { computed, ref, watch } from 'vue';

const emits = defineEmits<{
  (e: 'close');
}>();

const projectsStore = useProjectsStore();
const commentsStore = useCommentsStore();

const project = computed(() => {
  return projectsStore.project;
});

const projectMonthlyCommentary = computed(() => {
  return commentsStore.models.where('commentable_id', project.value?.id).where(
    'commentable_type',
    project.value?.$baseEntity(),
  ).orderBy('created_at', 'desc').get();
});

const commentaryForm = useFormData({
  comment: '',
});

const storeCommentaryAction = useStoreApiAction(projectsStore.commentaryStore);
const updateCommentaryAction = useStoreApiAction(projectsStore.commentaryUpdate);
const indexCommentaryAction = useStoreApiAction(projectsStore.commentaryIndex);

watch(() => project.value?.id, (newValue) => {
  if(newValue) {
    indexCommentaryAction.request(newValue);
  }
}, { immediate: true });

const selectedComment = ref<Comment | undefined>();

const selectComment = (comment: Comment) => {
  selectedComment.value = comment;
  commentaryForm.form.comment = comment.body;
};

const deselectComment = () => {
  selectedComment.value = undefined;
  commentaryForm.resetData(['comment']);
};

watch(projectMonthlyCommentary, (newValue) => {
  const today = new Date();

  newValue.some((comment) => {
    if(comment.createdAt.getMonth() === today.getMonth()) {
      selectComment(comment);

      return true;
    }
  });
}, { immediate: true });

const commentaryForCurrentMonthExists = computed(() => {
  const today = new Date();

  return projectMonthlyCommentary.value.some((comment) => {
    return comment.createdAt.getMonth() === today.getMonth();
  });
});

const saveCommentary = () => {
  if(selectedComment.value) {
    updateCommentaryAction.request(project.value.id, selectedComment.value.id, commentaryForm.form).then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Commentary Saved.',
      }).then(() => {
        emits('close');
      });
    }).catch((error) => {
      if(error.status === 403) {
        Swal.fire({
          icon: 'error',
          title: 'Commentary could not be added.',
          text: error.data?.message,
        });
      }
    });
  } else {
    storeCommentaryAction.request(project.value.id, commentaryForm.form).then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Commentary Saved.',
      }).then(() => {
        emits('close');
      }).catch((error) => {
        if(error.status === 403) {
          Swal.fire({
            icon: 'error',
            title: 'Commentary could not be added.',
            text: error.data?.message,
          });
        }
      });
    });
  }
};

const daysUntilLocked = computed(() => {
  return Math.ceil(DateTime.now().endOf('month').minus({ days: project.value?.commentary_lock }).diffNow('days').days);
});

const selectedCommentIsThisMonth = computed(() => {
  return selectedComment.value?.createdAt.getMonth() === new Date().getMonth();
});

const disableCommentary = computed(() => {
  return (selectedComment.value && !selectedCommentIsThisMonth.value) ||
    daysUntilLocked.value <= 0;
});
</script>

<style scoped></style>
