import MediaService from '@/services/MediaService';
import { useMediaStore } from '@/store/media';
import { ref } from 'vue';
import { useStoreApiAction } from './useStoreApiAction';

export interface Media {
  custom_properties: { access_url?: string };
  extension: string;
  file_name: string;
  name: string;
  order: number;
  original_url: string;
  preview_url: string;
  size: number;
  uuid: string;
  mime_type: string;
}

export function useMediaCollection<T extends (...args: any[]) => Promise<any>>(mediaRequest: T) {
  const mediaRequestAction = useStoreApiAction(mediaRequest);
  const mediaCollection = ref<Media[]>([]);

  const fetchMedia = async (...params: Parameters<T>) => {
    return mediaRequestAction.request(...params).then((medias: Record<string, Media>) => {
      for(const key in medias) {
        const element = medias[key];

        mediaCollection.value.push(element);
      }

      mediaCollection.value.sort((a, b) => a.order - b.order);

      return mediaCollection;
    });
  };

  const refreshMedia = (id: Id) => {
    const mediaStore = useMediaStore();

    mediaStore.refreshMedia(id).then((newMedia) => {
      const mediaIndex = mediaCollection.value.findIndex((media) => media.uuid === newMedia.uuid);

      mediaCollection.value[mediaIndex] = newMedia;
    });
  };

  const removeMedia = (id: Id) => {
    const mediaIndex = mediaCollection.value.findIndex((media) => media.uuid === id);

    mediaCollection.value.splice(mediaIndex, 1);
  };

  const deleteMedia = (id: Id) => {
    const mediaStore = useMediaStore();

    mediaStore.deleteMedia(id).then((deletedMedia) => {
      removeMedia(deletedMedia.uuid);
    });
  };

  const clear = () => {
    mediaCollection.value = [];
  };

  const addMedia = (medias: Record<string, Media>) => {
    for(const key in medias) {
      const element = medias[key];

      mediaCollection.value.push(element);
    }

    mediaCollection.value.sort((a, b) => a.order - b.order);

    return mediaCollection;
  };

  return {
    mediaCollection,
    fetchMedia,
    mediaRequestAction,
    refreshMedia,
    removeMedia,
    deleteMedia,
    addMedia,
    clear,
  };
}
