<template>
  <table class="w-full">
    <thead class="">
      <tr class="sticky -top-2 bg-gray-300">
        <th class="py-2 text-left pl-3">
          Trade
        </th>

        <th class="text-right pr-3">
          Budget
        </th>

        <th class="text-right bg-green-200/50">
          Current Claim
        </th>

        <th class="text-right bg-green-200/50">
          Certified
        </th>

        <th class="text-right bg-green-200/50">
          Previous
        </th>

        <th class="text-right bg-green-200/50 pr-3">
          Cumulative
        </th>

        <th class="text-right bg-blue-300/50">
          Claim %
        </th>

        <th class="text-right bg-blue-300/50">
          Certified %
        </th>

        <th class="text-right bg-blue-300/50">
          Previous %
        </th>

        <th class="text-right bg-blue-300/50 pr-3">
          Cumulative %
        </th>

        <th class="text-right">
          Variance
        </th>

        <th class="text-right pr-3 border-r">
          Cost To Complete
        </th>
      </tr>
    </thead>

    <tbody
      v-if="claim"
      class="border-t border-black"
    >
      <TradeRow
        v-for="trade in claim.trades"
        :key="trade.id"
        :trade="trade"
        :claim="claim"
      />

      <tr class="border-black border">
        <td class="pl-3">
          Subtotal
        </td>

        <td class="text-right">
          {{ australianCurrency(totalCosts.trades.budget) }}
        </td>

        <td class="text-right">
          {{ australianCurrency(totalCosts.trades.current_claim) }}
        </td>

        <td class="text-right">
          {{ australianCurrency(totalCosts.trades.current_certified) }}
        </td>

        <td class="text-right">
          {{ australianCurrency(totalCosts.trades.previous) }}
        </td>

        <td class="text-right">
          {{ australianCurrency(totalCosts.trades.cumulativeCertified) }}
        </td>

        <td class="text-right">
          {{ isNumber(totalCosts.trades.current_claim / totalCosts.trades.budget) ? australianNumber((totalCosts.trades.current_claim / totalCosts.trades.budget) * 100) : 0 }}%
        </td>

        <td class="text-right">
          {{ isNumber(totalCosts.trades.current_certified / totalCosts.trades.budget) ? australianNumber((totalCosts.trades.current_certified / totalCosts.trades.budget) * 100) : 0 }}%
        </td>

        <td class="text-right">
          {{ isNumber(totalCosts.trades.previous / totalCosts.trades.budget) ? australianNumber((totalCosts.trades.previous / totalCosts.trades.budget) * 100) : 0 }}%
        </td>

        <td class="text-right">
          {{ isNumber(totalCosts.trades.cumulativeCertified / totalCosts.trades.budget) ? australianNumber((totalCosts.trades.cumulativeCertified / totalCosts.trades.budget) * 100) : 0 }}%
        </td>

        <td class="text-right">
          {{ australianCurrency(Math.abs(totalCosts.trades.current_claim - totalCosts.trades.current_certified)) }}
        </td>

        <td class="text-right pr-3">
          {{ australianCurrency(Math.abs(totalCosts.trades.budget - totalCosts.trades.current_certified)) }}
        </td>
      </tr>

      <template
        v-for="(costs, key) in variationOrderCosts"
        :key="key"
      >
        <VariationOrderRow
          :claim="claim"
          :costs="costs"
          :title="key"
          :variation-order-type="VariationOrderType[key]"
        />
      </template>

      <tr class="border-black border">
        <td class="pl-3">
          Subtotal
        </td>

        <td class="text-right">
          {{ australianCurrency(totalCosts.variationOrders.budget) }}
        </td>

        <td class="text-right">
          {{ australianCurrency(totalCosts.variationOrders.current_claim) }}
        </td>

        <td class="text-right">
          {{ australianCurrency(totalCosts.variationOrders.current_certified) }}
        </td>

        <td class="text-right">
          {{ australianCurrency(totalCosts.variationOrders.previous) }}
        </td>

        <td class="text-right">
          {{ australianCurrency(totalCosts.variationOrders.cumulativeCertified) }}
        </td>

        <td class="text-right">
          {{ totalCosts.variationOrders.budget > 0 ? australianNumber((totalCosts.variationOrders.current_claim / totalCosts.variationOrders.budget) * 100) : 0 }}%
        </td>

        <td class="text-right">
          {{ totalCosts.variationOrders.budget > 0 ? australianNumber((totalCosts.variationOrders.current_certified / totalCosts.variationOrders.budget) * 100) : 0 }}%
        </td>

        <td class="text-right">
          {{ totalCosts.variationOrders.budget > 0 ? australianNumber((totalCosts.variationOrders.previous / totalCosts.variationOrders.budget) * 100) : 0 }}%
        </td>

        <td class="text-right">
          {{ totalCosts.variationOrders.budget > 0 ? australianNumber((totalCosts.variationOrders.cumulativeCertified / totalCosts.variationOrders.budget) * 100) : 0 }}%
        </td>

        <td class="text-right">
          {{ australianCurrency(Math.abs(totalCosts.variationOrders.current_claim - totalCosts.variationOrders.current_certified)) }}
        </td>

        <td class="text-right pr-3">
          {{ australianCurrency(Math.abs(totalCosts.variationOrders.budget - totalCosts.variationOrders.current_certified)) }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup lang="ts">
import TradeRow from '@/components/ProgressClaims/TradeRow.vue';
import VariationOrderRow from '@/components/ProgressClaims/VariationOrderRow.vue';
import { australianCurrency, australianNumber, isNumber } from '@/helpers';
import { VariationOrderStatus, VariationOrderType } from '@/models/VariationOrder';
import VariationOrderCost from '@/models/VariationOrderCost';
import { useClaimsStore } from '@/store/claims';
import { useVariationOrderCostsStore } from '@/store/variationOrderCosts';
import { Collection } from 'pinia-orm';
import { computed } from 'vue';

const props = defineProps<{
  claimId: string;
  projectId: string;
}>();

const claimsStore = useClaimsStore();

const claim = computed(() => {
  // TODO: 10/11/23 constrain variation orders to only those with cost for the current claim
  if(props.claimId) {
    const claimQuery = claimsStore.models.with('trades', (query) => {
      query.with('trade_items');
    }).with('trade_item_costs').with('variation_orders', (query) => {
      query.where('status', VariationOrderStatus.approved)
        .with('trade_items');
    }).with('variation_order_costs').where(
      'project_id',
      parseInt(props.projectId),
    );

    return claimQuery.find(parseInt(props.claimId));
  }

  return undefined;
});

const totalCosts = computed(() => {
  const totals = {
    trades: {
      budget: 0,
      current_claim: 0,
      current_certified: 0,
      cumulativeCertified: 0,
      previous: 0,
    },

    variationOrders: {
      budget: 0,
      current_claim: 0,
      current_certified: 0,
      cumulativeCertified: 0,
      previous: 0,
    },
  };

  if(claim.value) {
    claim.value.trades?.forEach((trade) => {
      trade.trade_items.forEach((tradeItem) => {
        totals.trades.budget += tradeItem.budget;
        totals.trades.previous += tradeItem.previous(claim.value);
      });
    });

    claim.value.trade_item_costs.forEach((cost) => {
      totals.trades.current_claim += cost.current_claim;
      totals.trades.current_certified += cost.current_certified;
    });

    claim.value.variation_orders?.forEach((variationOrder) => {
      totals.variationOrders.budget += variationOrder.budget;
    });

    claim.value.variation_order_costs?.forEach((variationOrderCost) => {
      totals.variationOrders.current_claim += variationOrderCost.current_claim;
      totals.variationOrders.current_certified += variationOrderCost.current_certified;
    });
  }

  totals.trades.cumulativeCertified = totals.trades.current_certified + totals.trades.previous;

  totals.variationOrders.cumulativeCertified = totals.variationOrders.current_certified +
    totals.variationOrders.previous;

  return totals;
});

const variationOrderCostsStore = useVariationOrderCostsStore();

const variationOrderCosts = computed(() => {
  const costs: Partial<{ [k in keyof typeof VariationOrderType]: Collection<VariationOrderCost> }> = {};

  for(const key in VariationOrderType) {
    costs[key] = variationOrderCostsStore.models.with('variation_order').where('claim_id', claim.value?.id).whereHas(
      'variation_order',
      (query) => {
        query.where('type', VariationOrderType[key]);
      },
    ).get();
  }

  return costs;
});
</script>

<style scoped></style>
