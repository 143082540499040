export type fileTypes = 'image/png' | 'image/gif' | 'image/jpeg' | 'unknown';

export async function getFileMime(file: File) {
  return new Promise<fileTypes>((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = (ev) => {
      if(ev.target.result instanceof ArrayBuffer) {
        const arr = (new Uint8Array(ev.target.result)).subarray(0, 4);
        let header = '';

        for(let i = 0;i < arr.length;i++) {
          header += arr[i].toString(16);
        }

        const jpegHeader = header.slice(0, 4);

        switch (jpegHeader) {
          case 'ffd8':
            resolve('image/jpeg');
            break;

          default:
            // https://www.garykessler.net/library/file_sigs.html
            switch (header) {
              case '89504e47':
                resolve('image/png');
                break;

              case '47494638':
                resolve('image/gif');
                break;

              default:
                resolve('unknown');
                break;
            }
        }
      }
    };

    fileReader.readAsArrayBuffer(file);
  });
}
