import { route } from '@/ziggy-shim';
import { api } from './LaravelClient';

export default {
  async show(id: Id) {
    return await api(route('api.v1.media.show', id), 'get');
  },

  async delete(id: Id) {
    return await api(route('api.v1.media.destroy', id), 'delete');
  },

  async download(id: Id) {
    return await api(route('api.v1.media.download', id), 'get');
  },
};
