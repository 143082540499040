<template>
  <div class="space-y-10 p-5">
    <div class="card">
      <div class="card__header">
        <div class="flex justify-between items-center">
          <p>
            Details
          </p>

          <button
            type="button"
            class="btn btn--primary-blue"
            data-test="save-project"
            @click="updateProject"
          >
            Save Project Settings
          </button>
        </div>
      </div>

      <div>
        <form
          class="p-6 space-x-10 flex justify-between"
          @submit.prevent="updateProject"
        >
          <div class="w-1/3">
            <label>
              Name
              <AppInput
                v-model="projectForm.form.name"
                name="name"
                type="text"
                data-test="name"
                class="form-input"
                :error="projectForm.getErrors('name')"
              />
            </label>

            <label>
              Reference
              <AppInput
                v-model="projectForm.form.reference"
                name="name"
                type="text"
                data-test="reference"
                class="form-input"
                :error="projectForm.getErrors('reference')"
              />
            </label>

            <label class="flex items-center justify-between">
              <p>
                Track Weekends For Utilisation
              </p>

              <input
                v-model="projectForm.form.track_weekends"
                type="checkbox"
                name="track_weekends"
                class="focus:ring-0 border border-gray-400 block focus:outline-none"
              >
            </label>

            <label class="flex items-center justify-between">
              <p>
                Default dashboard
              </p>

              <AppSelect
                v-model="projectForm.form.default_dashboard"
                name="default_dashboard"
                :options="[{ value: 'head_contract', label: 'Head Contract'}, { value: 'self_perform', label: 'Self Perform'}]"
                class="form-input pr-8"
                :error="projectForm.getErrors('default_dashboard')"
              />
            </label>
          </div>

          <div class="w-1/3">
            <label>
              Description
              <textarea
                v-model="projectForm.form.description"
                name="description"
                class="form-input resize-none"
                rows="7"
              />

              <span
                v-if="projectForm.getErrors('description')"
                class="text-red-600 text-sm"
              >{{ projectForm.getErrors('description') }}</span>
            </label>
          </div>

          <div class="w-1/3">
            <fieldset class="mt-4">
              <legend class="font-semibold">
                Standard Shift
              </legend>

              <div class="flex space-x-4">
                <label
                  class="w-1/2"
                  title="The number of hours in a standard shift for Labour on the project"
                >
                  Labour <span class="text-sm text-gray-400">(hr)</span>

                  <AppInput
                    v-model.number="projectForm.form.labour_standard_shift"
                    name="labour_standard_shift"
                    step=".5"
                    type="number"
                    data-test="labour_standard_shift"
                    class="form-input"
                    :error="projectForm.getErrors('labour_standard_shift')"
                  />
                </label>

                <label
                  class="w-1/2"
                  title="The number of hours in a standard shift for plant item on the project"
                >
                  Plant <span class="text-sm text-gray-400">(hr)</span>

                  <AppInput
                    v-model.number="projectForm.form.plant_standard_shift"
                    name="plant_standard_shift"
                    step=".5"
                    type="number"
                    data-test="plant_standard_shift"
                    class="form-input"
                    :error="projectForm.getErrors('plant_standard_shift')"
                  />
                </label>
              </div>

              <div class="flex space-x-4 mt-4">
                <label
                  class="w-1/2"
                  title="The maximum number of hours in a standard shift for labour or plant item on the project"
                >
                  Maximum <span class="text-sm text-gray-400">(hr)</span>

                  <AppInput
                    v-model.number="projectForm.form.maximum_shift"
                    name="maximum_shift"
                    type="number"
                    step=".5"
                    data-test="maximum_shift"
                    class="form-input"
                    :error="projectForm.getErrors('maximum_shift')"
                  />
                </label>

                <label
                  class="w-1/2"
                  title="The normal start time for both labour and plant on the project"
                >
                  Start
                  <AppInput
                    v-model="projectForm.form.shift_start_at"
                    name="shift_start_at"
                    type="time"
                    data-test="shift_start_at"
                    class="form-input"
                    :error="projectForm.getErrors('shift_start_at')"
                  />
                </label>

                <label
                  class="w-1/2"
                  title="The normal start time for both labour and plant on the project"
                >
                  Night Start
                  <AppInput
                    v-model="projectForm.form.night_shift_start_at"
                    name="night_shift_start_at"
                    type="time"
                    data-test="night_shift_start_at"
                    class="form-input"
                    :error="projectForm.getErrors('night_shift_start_at')"
                  />
                </label>
              </div>
            </fieldset>
          </div>
        </form>
      </div>
    </div>

    <div class="card">
      <div class="card__header">
        <div class="flex justify-between items-center">
          <p>
            Cover Page
          </p>
        </div>
      </div>

      <div class="flex flex-col items-center p-4">
        <div class="w-1/2">
          <FileUpload
            v-model="fileUploadForm"
            accept="image/jpeg;image/png"
            @change="fileUploaded"
          />
        </div>

        <MediaDisplay
          v-if="coverPageMedia.mediaCollection.value.length > 0"
          :media="coverPageMedia.mediaCollection.value[0]"
          class="max-w-md"
        />
      </div>
    </div>

    <div class="flex w-full gap-4">
      <SupplierList
        :project-id="$props.projectId"
        class="w-full"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import FileUpload from '@/components/FileUpload.vue';
import MediaDisplay from '@/components/MediaDisplay.vue';
import SupplierList from '@/components/ProjectSettings/ProjectTab/SupplierList.vue';
import { useFormData } from '@/composables/useFormData';
import { useMediaCollection } from '@/composables/useMediaCollection';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { timeForInput } from '@/helpers';
import { useProjectsStore } from '@/store/projects';
import Swal from 'sweetalert2';
import { computed, nextTick, ref, watch } from 'vue';

const props = defineProps<{
  projectId: string;
}>();

const emits = defineEmits<{
  (e: 'dirty');
  (e: 'saved');
}>();

const projectsStore = useProjectsStore();

const project = computed(() => {
  return projectsStore.models.query().find(parseInt(props.projectId));
});

const projectForm = useFormData({
  name: '',
  labour_standard_shift: 0,
  plant_standard_shift: 0,
  maximum_shift: 0,
  shift_start_at: '',
  night_shift_start_at: '',
  track_weekends: false,
  reference: '',
  default_dashboard: '',
  description: '',
});

watch(project, (newValue) => {
  if(newValue) {
    projectForm.setData(newValue, ['shift_start_at', 'night_shift_start_at']);
    projectForm.form.shift_start_at = timeForInput(newValue.shiftStartAt);
    projectForm.form.night_shift_start_at = timeForInput(newValue.nightShiftStartAt);

    nextTick(() => {
      projectForm.resetDirtyStatus();
    });
  }
}, { immediate: true });

watch(projectForm.isDirty, (newValue) => {
  if(newValue) {
    emits('dirty');
  } else {
    emits('saved');
  }
});

const updateProjectAction = useStoreApiAction(projectsStore.updateProject);

const updateProject = () => {
  projectForm.resetErrors();

  updateProjectAction.request(props.projectId, projectForm.form).then(() => {
    Swal.fire({
      title: 'Project Updated',
      icon: 'success',
    });

    // projectForm.form.name = project.value.name;
    // projectForm.form.labour_standard_shift = project.value.labour_standard_shift;
    // projectForm.form.plant_standard_shift = project.value.plant_standard_shift;
    // projectForm.form.maximum_shift = project.value.maximum_shift;
    projectForm.setData(project.value, ['shift_start_at', 'night_shift_start_at']);

    projectForm.form.shift_start_at = timeForInput(project.value.shiftStartAt);
    projectForm.form.night_shift_start_at = timeForInput(project.value.nightShiftStartAt);
  }).catch((error) => {
    projectForm.setErrors(error.data);
  });
};

const coverPageMedia = useMediaCollection(projectsStore.showCoverPage);

watch(() => props.projectId, (newValue) => {
  if(newValue) {
    coverPageMedia.fetchMedia(newValue);
  }
}, { immediate: true });

const storeMediaAction = useStoreApiAction(projectsStore.storeCoverPage);
const fileUploadForm = ref();

const fileUploaded = (event: Event) => {
  const files = Array.from((event.target as HTMLInputElement).files);
  const selectedFile = files[0];

  if(selectedFile) {
    const fileFormData = new FormData();

    fileFormData.append('images[]', selectedFile);

    storeMediaAction.request(project.value.id, fileFormData).then((medias) => {
      coverPageMedia.clear();
      coverPageMedia.addMedia(medias);
    }).catch((error) => {
      if(error.status === 422) {
        Swal.fire({
          icon: 'error',
          title: 'Failed to upload attachments',
          text: error.data.message,
        });
      }
    });
  }
};
</script>

<style scoped></style>
