import { getFileMime } from '@/helpers';
import { ref } from 'vue';

export function useFileUploadForm(
  onFileAdded: (event: Event, files: UploadedFile[]) => void = undefined,
  onFileRemoved: (file: UploadedFile, index: number) => void = undefined,
) {
  const filesUploaded = ref<UploadedFile[]>([]);

  const addFile = (event: Event) => {
    const files = Array.from((event.target as HTMLInputElement).files);

    filesUploaded.value = [];

    files.forEach(async (file) => {
      const mimeType = await getFileMime(file);

      filesUploaded.value.push({
        fileName: file.name,
        mime: mimeType,
        fileBlob: file,
      });
    });

    if(onFileAdded) {
      onFileAdded(event, filesUploaded.value);
    }
  };

  const removeFile = (index: number) => {
    const file = filesUploaded.value.splice(index, 1)[0];

    if(onFileRemoved) {
      onFileRemoved(file, index);
    }
  };

  return {
    addFile,
    removeFile,
    filesUploaded,
  };
}
