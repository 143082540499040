<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl mx-10">
          Variation Correspondence
        </h1>
      </div>
    </template>

    <template #body>
      <div class="flex flex-col h-full w-full p-4 overflow-y-auto min-w-3xl max-w-4xl">
        <div v-if="fetchCorrespondencesAction.is(States.COMPLETE)">
          <div
            class="flex justify-between items-center mt-2 w-full"
          >
            <button
              type="button"
              class="mx-auto btn btn--secondary-blue"
              @click="showAttachments = !showAttachments"
            >
              Show {{ showAttachments ? 'Correspondence' : 'Attachments' }}
            </button>
          </div>

          <div
            class="flex divide-gray-300 divide-x justify-between mt-2"
          >
            <div class="p-2 w-1/3 overflow-y-auto max-h-96">
              <div
                v-if="selectedCorrespondence"
                class="sticky -top-2 bg-white flex justify-center"
              >
                <button
                  type="button"
                  class="btn btn--tertiary-blue text-sm"
                  @click="deselectCorrespondence"
                >
                  Add new correspondence
                </button>
              </div>

              <ul
                v-if="variationOrder.correspondences?.length > 0"
                class="space-y-2"
              >
                <li
                  v-for="correspondence in variationOrder.correspondences"
                  :key="correspondence.id"
                  :class="{ 'bg-gray-100': correspondence.id === selectedCorrespondence?.id }"
                  class="p-2 rounded-lg"
                >
                  <button
                    type="button"
                    class="text-left w-full"
                    @click="selectCorrespondence(correspondence)"
                  >
                    <p class="font-semibold">
                      {{ correspondence.dateReceived.toLocaleString() }}
                    </p>

                    <p class="line-clamp-3">
                      {{ correspondence.description }}
                    </p>
                  </button>
                </li>
              </ul>

              <p
                v-else
                class="font-semibold"
              >
                No Correspondences found.
              </p>
            </div>

            <div
              v-if="showAttachments"
              class="relative h-full w-2/3"
            >
              <p
                v-if="correspondenceMedia.mediaCollection.value.length === 0"
                class="font-semibold p-10 my-auto text-center"
              >
                No attachments found.
              </p>

              <button
                v-if="correspondenceMedia.mediaCollection.value.length > 1"
                class="absolute left-2 bottom-1/2 border border-black rounded-full w-8 h-8 flex justify-center items-center hover:bg-gray-100 active:bg-gray-200 z-10 bg-white"
                type="button"
                @click="mediaSwiper.slidePrev()"
              >
                <Icon
                  name="chevron-left"
                  class="w-6 h-6"
                />
              </button>

              <Swiper
                class="max-w-sm mb-10"
                :space-between="20"
                loop
                centered-slides
                :modules="[Pagination]"
                :pagination="{
                  el: '.swiper-pagination',
                  type: 'bullets'
                }"
                @swiper="setSwiper"
              >
                <SwiperSlide
                  v-for="media in correspondenceMedia.mediaCollection.value"
                  :key="media.uuid"
                  class="!h-auto"
                >
                  <div class="flex items-center h-full w-full justify-center">
                    <MediaDisplay
                      :media="media"
                      @delete="correspondenceMedia.removeMedia(media.uuid)"
                      @refresh="correspondenceMedia.refreshMedia(media.uuid)"
                    />
                  </div>
                </SwiperSlide>
              </Swiper>

              <div class="swiper-pagination" />

              <button
                v-if="correspondenceMedia.mediaCollection.value.length > 1"
                class="absolute right-2 bottom-1/2 border border-black rounded-full w-8 h-8 flex justify-center items-center hover:bg-gray-100 active:bg-gray-200 z-10 bg-white"
                type="button"
                @click="mediaSwiper.slideNext()"
              >
                <Icon
                  name="chevron-right"
                  class="w-6 h-6"
                />
              </button>
            </div>

            <form
              v-else
              action=""
              class="flex flex-col space-y-4 p-2 w-2/3 justify-center"
              @submit.prevent="saveCorrespondence"
            >
              <label>
                Date Received

                <AppInput
                  v-model="correspondenceForm.form.date_received"
                  name="date_received"
                  type="date"
                  class="form-input"
                  :error="correspondenceForm.getErrors('date_received')"
                  :disabled="!!selectedCorrespondence"
                />
              </label>

              <label>
                To

                <AppInput
                  v-model="correspondenceForm.form.to"
                  name="to"
                  type="text"
                  class="form-input"
                  :error="correspondenceForm.getErrors('to')"
                  :disabled="!!selectedCorrespondence"
                />
              </label>

              <label>
                From

                <AppInput
                  v-model="correspondenceForm.form.from"
                  name="from"
                  class="form-input"
                  type="text"
                  :error="correspondenceForm.getErrors('from')"
                  :disabled="!!selectedCorrespondence"
                />
              </label>

              <label>
                Description

                <textarea
                  v-model="correspondenceForm.form.description"
                  name="description"
                  class="form-input resize-none"
                  type="text"
                  :disabled="!!selectedCorrespondence"
                />

                <span
                  v-if="correspondenceForm.getErrors('description')"
                  class="text-red-600 text-sm"
                >{{ correspondenceForm.getErrors('description') }}</span>
              </label>

              <label class="flex justify-between">
                Response Required

                <input
                  v-model="correspondenceForm.form.response_required"
                  name="response_required"
                  type="checkbox"
                  class="form-checkbox"
                  true-value="1"
                  false-value="0"
                  :disabled="!!selectedCorrespondence"
                >
              </label>

              <div
                v-if="!selectedCorrespondence"
                class="h-20"
              >
                <FileUpload
                  v-model="correspondenceForm.form.media"
                  multiple
                  @change="addFile"
                />
              </div>

              <div
                v-if="filesUploaded.length"
                class="overflow-x-auto flex"
              >
                <UploadedFileDisplay
                  v-for="(file, i) in filesUploaded"
                  :key="i"
                  :file="file"
                  :error="correspondenceForm.getErrors(`media.${i}`)"
                  @delete="removeFile(i)"
                />
              </div>
            </form>
          </div>
        </div>

        <AppSpinner
          v-else
          class="mx-auto w-full"
        />
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-end p-2 space-x-4">
        <button
          type="button"
          class="btn btn--secondary-blue"
          @click="$emit('close');"
        >
          Close
        </button>

        <button
          type="button"
          class="btn btn--primary-blue"
          :disabled="!!selectedCorrespondence"
          @click="saveCorrespondence"
        >
          Save Correspondence
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import FileUpload from '@/components/FileUpload.vue';
import MediaDisplay from '@/components/MediaDisplay.vue';
import Modal from '@/components/Modal.vue';
import UploadedFileDisplay from '@/components/UploadedFileDisplay.vue';
import { useFileUploadForm } from '@/composables/useFileUploadForm';
import { useFormData } from '@/composables/useFormData';
import { useMediaCollection } from '@/composables/useMediaCollection';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { dateForDateInput } from '@/helpers';
import Correspondence from '@/models/Correspondence';
import { useCorrespondencesStore } from '@/store/correspondences';
import { useProjectsStore } from '@/store/projects';
import { useVariationOrdersStore } from '@/store/variationOrders';
import Swal from 'sweetalert2';
import { Pagination } from 'swiper/modules';
import { Swiper as SwiperInterface } from 'swiper/types';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { computed, ref, watch } from 'vue';

const props = defineProps<{
  variationOrderId: Id;
}>();

const emits = defineEmits<{
  (e: 'close');
}>();

const variationOrdersStore = useVariationOrdersStore();
const correspondencesStoreStore = useCorrespondencesStore();
const projectsStore = useProjectsStore();

const project = computed(() => {
  return projectsStore.project;
});

const variationOrder = computed(() => {
  return variationOrdersStore.models.with('trade_items').with('user').with('correspondences').with('delay').where(
    'id',
    parseInt(props.variationOrderId as string),
  )
    .first();
});

const fetchCorrespondencesAction = useStoreApiAction(variationOrdersStore.fetchCorrespondences);
const storeCorrespondenceAction = useStoreApiAction(variationOrdersStore.storeCorrespondence);

watch(() => props.variationOrderId, (newValue) => {
  if(newValue) {
    fetchCorrespondencesAction.request(newValue);
  }
}, { immediate: true });

const correspondenceForm = useFormData({
  date_received: dateForDateInput(new Date()),
  to: '',
  from: '',
  description: '',
  response_required: 0,
  media: [],
});

const { filesUploaded, addFile, removeFile } = useFileUploadForm(undefined, (file, index) => {
  correspondenceForm.form.media.splice(index, 1);
});

const selectedCorrespondence = ref<Correspondence | undefined>();

const selectCorrespondence = (correspondence: Correspondence) => {
  selectedCorrespondence.value = correspondence;
  correspondenceForm.form.date_received = dateForDateInput(correspondence.date_received);
  correspondenceForm.form.to = correspondence.to;
  correspondenceForm.form.from = correspondence.from;
  correspondenceForm.form.description = correspondence.description;
  correspondenceForm.form.response_required = correspondence.response_required ? 1 : 0;
};

const saveCorrespondence = () => {
  storeCorrespondenceAction.request(props.variationOrderId, correspondenceForm.toFormDataObject()).then(() => {
    Swal.fire({
      icon: 'success',
      text: 'Correspondence Saved',
    });

    correspondenceForm.resetData();
    filesUploaded.value = [];
  });
};

const deselectCorrespondence = () => {
  selectedCorrespondence.value = undefined;
  correspondenceForm.resetData();
};

const correspondenceMedia = useMediaCollection(correspondencesStoreStore.fetchMedia);

watch(() => selectedCorrespondence.value?.id, (newValue) => {
  if(newValue) {
    correspondenceMedia.clear();
    correspondenceMedia.fetchMedia(newValue);
  }
}, { immediate: true });

const mediaSwiper = ref<SwiperInterface>();

const setSwiper = (swiper: SwiperInterface) => {
  mediaSwiper.value = swiper;
};

const showAttachments = ref(false);
</script>

<style scoped></style>
